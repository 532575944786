import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR, ValidationErrors } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import PostCodeLookUp from "../api/agentPostCode";
import { ContactDetails } from "../components/NewCustomer/ContactDetails";
import { ProfilePersonalDetails } from "../components/Profile/ProfilePersonalDetails";
import { ICustomerProfile } from "../models/user";
import { RootStoreContext } from "../stores/rootStore";
import { Footer } from "../components/Layout/Footer";
import { history } from "../";
import { ProfileFooter } from "../components/Profile/ProfileFooter";
import { CardDetails } from "../components/Profile/CardDetails";
import { Header } from "../components/Layout/Header";
import { PasswordBox } from "../components/Common/PasswordBox";
import { confirmAlert } from "react-confirm-alert";
import { WorldPayDialog } from "../components/WorldPay/WorldPayDialog";
import { StripeDialogWrapper } from "../components/Stripe/StripeDialogWrapper";

export const ProfilePage = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const {setPrevRoute, currentShop} = rootStore.commonStore;
  const { user, updateCustomer, customerInfo, customer, passwordVerified, setPasswordVerified, defaultCardUID,showCardDialog } = rootStore.userStore;
  const [customerValues, setCustomerValues] = useState<ICustomerProfile>({
            title: "",
            firstName: "",
            middleName: "",
            surname: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            postCode: "",
    });
  useEffect(() => {
    setPrevRoute(window.location.pathname);
    setPasswordVerified(false);
  }, [])
  useEffect(() => {
    if (user && user.isCustomer)
      if (!customer) 
        customerInfo();
  }, [user]);
  useEffect(() => {
    if(customer){
      setCustomerValues({
            title: customer.title,
            firstName: customer.firstName,
            middleName: customer.middleName,
            surname: customer.surname,
            address1: customer.address1,
            address2: customer.address2,
            address3: customer.address3,
            address4: customer.address4,
            postCode: customer.postCode,
          });
    }
  }, [customer])

  const validate = async (values: ICustomerProfile) => {
    const errors: ValidationErrors = [];
    if (!values.firstName) errors.firstName = "First name is required";
    if (!values.surname) errors.surname = "Surname is required";
    if (!values.address1) errors.address1 = "Address is required";
    if (!values.postCode) errors.postCode = "Post code is required";
    else
      await PostCodeLookUp.lookUp(values.postCode)
        .then((res) => {
          if (!res) errors.postCode = "Invalid post code";
        })
        .catch(() => (errors.postCode = "Invalid post code"));
    return errors;
  };
  let cardDialog = null;
   switch(currentShop!.cardProvider)
   {
     case 'WORLDPAY': cardDialog = <WorldPayDialog />; break;
     case 'STRIPE' : cardDialog = <StripeDialogWrapper />; break;
     default: cardDialog = <StripeDialogWrapper />;
   }
if(!passwordVerified){
  return (
    <main className="login-wrapper">
      <div className="login-container">
        <div className="logo">
          <img
            src={ currentShop?.logoPath ? `${currentShop?.appDocUrl}/Logo/${currentShop?.logoPath}` : "/images/logo-v.png"}
            alt={currentShop?.shopName || "My Drycleaner"}
          />
          <h1 className="logo-title">
           {currentShop?.shopName || "My Drycleaner"}
          </h1>
        </div>
        <PasswordBox />
      </div>
    </main>
  )
}
else
  return (
    <>
    <Header />
    <FinalForm
      initialValues={customerValues}
      validate={validate}
      onSubmit={(values:ICustomerProfile) =>{
        if(defaultCardUID.length === 0 && !user?.hasCredit){
            confirmAlert({
                        customUI: ({ onClose }) => {
                          return (
                            <div className='dialog-box'>
                              <div className="dialog-title">
                                <h2>No Default Card</h2>
                              </div>
                              <div className="dialog-body">
                                <p>Please select a default credit/debit card</p>
                              </div>
                              <div className="dialog-footer">
                              <button className="btn btn-default" onClick={onClose}>Ok</button>
                              </div>
                            </div>
                          );
                        }
                      });
        } else
          updateCustomer(values)
                .then(() => history.push("/order"))
                .catch((error) => ({
                  [FORM_ERROR]: error,
                }))
      }}
      render={({handleSubmit})=>(
        <form onSubmit={handleSubmit}>
<main>
      <div className="common-wrapper">
        <div className="basket-container">
          <div className="basket-details">
            <ProfilePersonalDetails />
          </div>
          <div className="basket-details">
            <ContactDetails />
          </div>
        </div>
        {!user?.hasCredit && (
        <div className="card-detail-grid">
          <div className="basket-personal-details">
            <h2 className="basket-heading">Payment Details</h2>
            <CardDetails />
          </div>
        </div>
        )}
      </div>
    </main>
    <Footer>
      <ProfileFooter />
    </Footer>
        </form>
      )}
    />
     {currentShop!.hasCardModule && showCardDialog && cardDialog}
    </>
  );
});
