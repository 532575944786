import React, { useContext } from "react";
import { IOrderDetail } from "../../models/order";
import { FaMinusSquare } from "react-icons/fa";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";

interface IProps {
  orderDetail: IOrderDetail;
}
export const OrderDetailRow: React.FC<IProps> = observer(({ orderDetail }) => {
  const rootStore = useContext(RootStoreContext);
  const { deleteOrderDetail } = rootStore.orderStore;

  return (
    <div
      className={
        orderDetail.itemType === "S"
          ? "basket-grid-row basket-grid-row-service"
          : "basket-grid-row"
      }
    >
      <div>
        {orderDetail.itemType === "I" ? `${orderDetail.quantity} x` : ""}
      </div>

      <div
        className={orderDetail.itemType === "S" ? "basket-item-service" : ""}
      >
        {orderDetail.subCategory}
      </div>
      {/*       <div className="basket-grid-number">
        &pound;{orderDetail.price.toFixed(2)}
    </div>  */}
      <div className="basket-grid-number">
        &pound;{orderDetail.lineTotal.toFixed(2)}
      </div>
      <div>
        <FaMinusSquare className="delete-icon"
          onClick={() =>
            deleteOrderDetail(orderDetail.mainLineNo, orderDetail.lineNo)
          }
        />
      </div>
    </div>
  );
});
