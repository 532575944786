import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useState } from 'react'
import { Dialog } from '../Common/Dialog';
interface IProps{
    defaultType: number;
    showDialog: boolean;
    hideDialog: () => void;
    saveDialog: (type: number) => void;
}
export const AlterationsTypeEditDialog: React.FC<IProps> = observer(({defaultType,showDialog,hideDialog,saveDialog}) => {
    
    const [alterType, setAlterType] = useState(defaultType);
    const handleAlterationTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        let type = parseInt(event.target.value);
        if(type !== alterType)
            setAlterType(type);
    }

 return (
    <Dialog
      title={`What service do you require for this garment?`}
      showDialog={showDialog}
      hideDialog={hideDialog}
      saveData={() => saveDialog(alterType)}
      saveDisabled={false}
      saveLabel='Save'
    >
    <div className="input-radio-group">
        <label className="input-form-radio">Alter & Clean
        <input name="alterType" type="radio" value={1} onChange={handleAlterationTypeChange} checked={alterType ===  1} />
        <span className="checkmark"></span>
        </label>

        <label className="input-form-radio">Alteration Only
        <input name="alterType" type="radio" value={2} onChange={handleAlterationTypeChange} checked={alterType ===  2} />
        <span className="checkmark"></span>
        </label>
    </div>
    </Dialog>
  );
});
