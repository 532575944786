import axios from "axios";
const API_KEY = "hQntUGfwjE6MDkW2MBt69w2543";
const PostCodeLookUp = {
  lookUp: (postCode: string): Promise<boolean> =>
    axios
      .get(`https://api.getAddress.io/find/${postCode}?api-key=${API_KEY}`)
      .then((res) => {
        if (res.status === 400) return false;
        return true;
      })
      .catch( () => {
        return false;
      }),
};

export default PostCodeLookUp;
