import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { RootStoreContext } from '../../stores/rootStore';
import { Dialog } from '../Common/Dialog';

const AlterationsTypeDialog = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    selectedItem,
    showAlterations,
    showAlterationsTypeDialog,
    hideAlterationsTypeDialog,
    setAlterationsType,
    alterationsType,
    alterationsTypeEditMode,
    setAlterationTypeUpdated,
    selectedMainLineNo,
  } = rootStore.categoryStore;
  const {
    mainAlterationDetail,
    setMainAlterationDetail,
    getMainAlterationDetails,
    updateAlterationsType
  } = rootStore.orderStore;
    useEffect(() => {
        if(selectedMainLineNo > 0)
            setMainAlterationDetail(getMainAlterationDetails(selectedMainLineNo));
    }, [
        selectedMainLineNo,
        setMainAlterationDetail,
        getMainAlterationDetails
    ]);
    const [alterType, setAlterType] = useState(alterationsType > 0 ? alterationsType : 1);
    const handleAlterationTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        let type = parseInt(event.target.value);
        if(type !== alterType)
            setAlterType(type);
    }
  const handleSaveAlterationsType = () => {
    
    setAlterationsType(alterType);
    if(selectedMainLineNo !== 0 && mainAlterationDetail){
        let alterationDetail = {
            mainLineNo: mainAlterationDetail!.mainLineNo,
            lineNo: mainAlterationDetail!.lineNo,
            mainCategory: mainAlterationDetail!.mainCategory,
            subCategory: mainAlterationDetail!.subCategory,
            scUnique: mainAlterationDetail!.scUnique,
            stkCode: mainAlterationDetail!.stkCode,
            discountQty: 0,
            quantity: 1,
            pieces: selectedItem!.pieces,
            price: alterType === 1 ? selectedItem!.scPrice : 0,
            lineTotal: alterType === 1 ? selectedItem!.scPrice : 0,
            alterationCode: alterType === 1 ? 0 : 5,
            alteration: "A",
            itemType: "I",
            collectionCharges: 0,
            deliveryCharges: 0,
            discountType: alterType === 1 ?
              (selectedItem!.discount > 0
                ? `PR::0:${selectedItem?.pmPrice}`
                : "") : "",
            discount: alterType === 1 ? selectedItem!.discount : 0,
            headerDiscount: 0,
            notes: "",
        }
        setMainAlterationDetail(alterationDetail)
        updateAlterationsType(alterationDetail);
    }
    hideAlterationsTypeDialog();
    if(!alterationsTypeEditMode) 
        showAlterations();
    else
        setAlterationTypeUpdated(true);
  }

  const handleHideDialog = () => {
    setAlterType(1);
    hideAlterationsTypeDialog();
  }

return (
    <Dialog
      title={`What service do you require for this garment?`}
      showDialog={showAlterationsTypeDialog}
      hideDialog={handleHideDialog}
      saveData={handleSaveAlterationsType}
      saveDisabled={false}
      saveLabel={selectedMainLineNo > 0 ? 'Save' : 'Next'}
    >
    <div className="input-radio-group">
        <label className="input-form-radio">Alter & Clean
        <input name="alterType" type="radio" value={1} onChange={handleAlterationTypeChange} checked={alterType ===  1} />
        <span className="checkmark"></span>
        </label>

        <label className="input-form-radio">Alteration Only
        <input name="alterType" type="radio" value={2} onChange={handleAlterationTypeChange} checked={alterType ===  2} />
        <span className="checkmark"></span>
        </label>
    </div>
    </Dialog>
  );
}

export default observer(AlterationsTypeDialog);