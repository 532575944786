import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { customerFullAddress, customerFullName } from "../../utils/Utils";

export const NameAddress = observer(() => {
    
    const rootStore = useContext(RootStoreContext);
    const {customer} = rootStore.userStore;
    if(!customer)
        return (
        <div className="basket-personal-details">
        <h2 className="basket-heading">Name & Address</h2>
            <div className="basket-field-group">
                <label className="basket-field-group-label">Name</label>
                <label className="basket-field-group-input">...Fetching name...</label>
            </div>
            <div className="basket-field-group">
                <label className="basket-field-group-label">Address</label>
                <label className="basket-field-group-input">...Fetching address...</label>
            </div>
        </div>
    );

        
  return (
    <div className="basket-personal-details">
      <h2 className="basket-heading">Name & Address</h2>
        <div className="basket-field-group">
            <label className="basket-field-group-label">Name</label>
            <label className="basket-field-group-input">{customerFullName(customer!)}</label>
        </div>
        <div className="basket-field-group">
            <label className="basket-field-group-label">Address</label>
            <label className="basket-field-group-input">{customerFullAddress(customer!)}</label>
        </div>
    </div>
  );
});
