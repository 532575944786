import React from "react";
import { observer } from "mobx-react-lite";
import { getDateString } from "../../utils/Utils";
interface IProps{
    selectedDate: Date;
    setSelectedDate: (value:string) => void
}
export const ChangeSlotHeader : React.FC<IProps> = observer(
  ({selectedDate, setSelectedDate}) => {
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setSelectedDate(event.target.value);
      }
    return (
      <div className="input-form-date-group">
          <label className="input-label">Select Date</label>
          <input id="selectedDate" name="selectedDate" type="date" className="input-form-field" onChange={handleChange} defaultValue={getDateString(selectedDate)}></input>
      </div>
    );
  }
);