import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntentResult } from '@stripe/stripe-js';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react'
import { RootStoreContext } from '../../stores/rootStore';
import { Dialog } from '../Common/Dialog'

export const StripeDialog = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const { showCardDialog, hideNewCardDialog, saveNewStripeCard, savingCard, cardSaveError, setCardSaveError, cancelStripeIntent } = rootStore.userStore;
    const [showError, setShowError] = useState(false);
    const stripe_api = useStripe();
    const stripe_elements = useElements();
    const handleCancel = async () => {
        await cancelStripeIntent();
        hideNewCardDialog();
    }
    const handleSubmit = async () => {

        if(!stripe_api || !stripe_elements){
            setCardSaveError("Loading...");
            setShowError(true);
            return;
        }
        setCardSaveError("");
        setShowError(false);
        let returnURL = `${window.location.protocol}//${window.location.host}/cardstatus` 
        // @ts-ignore
        stripe_api.confirmSetup({elements: stripe_elements, confirmParams : { return_url: returnURL}, redirect: 'if_required'})
            .then( async (response: SetupIntentResult) => {
                if(response.error){
                    setCardSaveError(response.error.message!);
                    setShowError(true);
                } else if(response.setupIntent){
                    switch(response.setupIntent.status){
                        case 'succeeded':
                            if(response.setupIntent.payment_method){
                                saveNewStripeCard(response.setupIntent.payment_method)
                                .then(() => {
                                    if(!savingCard && cardSaveError)
                                        setShowError(true);
                                });
                            if(cardSaveError.length === 0)
                                hideNewCardDialog();
                            }
                            break;
                        case 'processing':
                            setCardSaveError("Processing..")
                            setShowError(true);
                            break;
                        case "requires_payment_method":
                            setCardSaveError('Failed to process payment details. Please try another payment method');
                            setShowError(true);
                            break;
                        default:
                            setCardSaveError("An error occured while saving your card!");
                            setShowError(true);
                            break;
                    }
                }
            })
    }
    return (
    <Dialog title="Add new Credit/Debit card" showDialog={showCardDialog} hideDialog={handleCancel} saveData={handleSubmit} saveDisabled={savingCard}>
        <form>
            <PaymentElement />
        </form>
        {!savingCard && showError && (<span className="input-error-big">{cardSaveError}</span>)}
    </Dialog>
  )
});
