import { observer } from 'mobx-react-lite'
import React from 'react'
import { IBundleOrderGroupEntity } from '../../models/order'
import { displayDate, displayDateTime} from '../../utils/Utils'
import { BundleOrdersList } from './BundleOrdersList';

interface IProp{
    orders: IBundleOrderGroupEntity[];
    orderType: number;
    setOrdersType: (type: number) => void;
}
export const BundleOrderGroup : React.FC<IProp> = observer(({orders, orderType, setOrdersType}) => {
    const handleSetOrderType = () => setOrdersType(orderType);
    return (
            <div className="myorders-list-container">
            {orders.map(bundle => (
                <div key={bundle.orderNo} className="myorders-bundle-order-wrapper">
                    <div className="myorders-bundle-order-header">
                        <div className="myorders-bundle-order-header-item">
                            <div>Bundle No: {bundle.orderNo}</div>
                            <div>Total: &pound;&nbsp;{bundle.total.toFixed(2)}</div>
                        </div>
                        <div className="myorders-bundle-order-header-item">
                            <div>Order Date: {displayDate(bundle.orderDate)}</div>
                            { bundle.collectionDate && (<div>Collection Date: {displayDateTime(bundle.collectionDate)}</div>)}
                        </div>
                    </div>
                    <BundleOrdersList orders={bundle.orders} dateFieldText={bundle.collectionDate ? "Delivery Date" : "Collection from Shop"} setOrdersType={handleSetOrderType} />
                </div>))}
        </div>
    )
});
