import React, { useContext, useState } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { Form as FinalForm, Field } from "react-final-form";
import { FaKey } from "react-icons/fa";
import { FORM_ERROR, ValidationErrors } from "final-form";
import { observer } from "mobx-react-lite";


export const PasswordBox = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { verifyPassword, setPasswordVerified } = rootStore.userStore;
  const [saveError, setSaveError] = useState("")
  
  const validate = (value: string) => {
    const errors: ValidationErrors = [];
    if(!value) return errors.password("Kindly type your password")
  };
  return (
      <>
    <div className="dialog-box login-box">
        <div className="login-box-title"><h2>Authorisation</h2></div>
      <FinalForm
        validate={validate}
        onSubmit={(value: any) =>{
            verifyPassword(value.password).then((res) => {
                setPasswordVerified(res)
                if(!res){
                    setSaveError("Invalid Password");
                    return { [FORM_ERROR]: 'Invalid Password' }
                }
            });
        }
        }
        render={({
          handleSubmit,
          submitError,
          submitting,
          invalid,
          pristine,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="basket-input-label">
                This section requires authorisation.<br />Kindly type your password to access this section.
            </div>
            <Field name="password">
              {({ input, meta }) => (
                <div className="input-group">
                  <div className="input-icon">
                    <FaKey />
                  </div>
                  <input
                    className="input-field"
                    type="password"
                    {...input}
                    placeholder="Password"
                  />
                  {meta.touched && (meta.error || meta.submitError) && (
                    <span className="input-error">
                      {meta.error || meta.submitError}
                    </span>
                  )}
                </div>
              )}
            </Field>
             {submitError && !dirtySinceLastSubmit && (
              <span className="input-error-big">{submitError}</span>
            )}
            { saveError.length > 0 && (
              <span className="input-error-big">{saveError}</span>
            )}
            <button
              type="submit"
              className="btn-fullwidth btn-default mb-20"
              disabled={
                (invalid && !dirtySinceLastSubmit) || pristine || submitting
              }
            >
              Authorise
            </button>
          </form>
        )}
      />
    </div>
    </>
  );
});
