import React, { useContext, useState, useEffect, ChangeEvent } from "react";
import { observer } from "mobx-react-lite";
import { ISubCategory } from "../../models/category";
import { RootStoreContext } from "../../stores/rootStore";
import { IOrderDetail } from "../../models/order";
import { FaMinusSquare, FaPlusSquare, FaPen, FaEdit } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
interface IProps {
  item: ISubCategory | null;
}

export const ProductItem: React.FC<IProps> = observer(({ item }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    showAlterationsEdit,
    setShowAlterationsTypeDialog,
    alterationUpdated,
    setAlterationUpdated,
  } = rootStore.categoryStore;
  const { currentShop } = rootStore.commonStore;
  const {
    getOrderDetailForItem,
    initOrderDetail,
    getAlterationDetailsForItem,
    addToOrder,
    removeFromOrder,
    updateOrderDetail,
    removeAllFromOrder,
    mainLineNo,
    incrementMainLineNo,
    setOrderSaved,
    setTurnAroundTime,
    orderDetails
  } = rootStore.orderStore;
    const [orderDetail, setOrderDetail] = useState<IOrderDetail>(
    initOrderDetail(item!)
  );
  const [alterations, setAlterations] = useState<IOrderDetail[]>([]);
  const [alterationPrice, setAlterationPrice] = useState(0.0);
  const [alterationQty, setAlterationQty] = useState(0);
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState('');
  useEffect(() => {
    if (item) {
      setOrderDetail(getOrderDetailForItem(item) || initOrderDetail(item));
      setAlterations(getAlterationDetailsForItem(item) || []);
    }
  }, [
    item,
    setOrderDetail,
    getOrderDetailForItem,
    initOrderDetail,
    setAlterations,
    getAlterationDetailsForItem,
  ]);
  useEffect(() =>{
    if(orderDetails?.length === 0){
      setOrderDetail(initOrderDetail(item!));
      setAlterations([]);
      setAlterationPrice(0);
      setAlterationQty(0);
    }
  }, [orderDetails])

  useEffect(() => {
    refreshAlterationTotals();
  }, [alterations]);
  useEffect(() => {
    if(alterationQty > 0)
      ReactTooltip.rebuild();
  },[alterationQty])
  useEffect(() => {
    if (alterationUpdated) {
      setAlterations(getAlterationDetailsForItem(item!) || []);
      setAlterationUpdated(false);
    }
  }, [alterationUpdated]);

  useEffect(() => {
    if(orderDetail)
      setNotes(orderDetail.notes);
  },[orderDetail]);

  const refreshAlterationTotals = () => {
    let qty = 0;
    let price = 0.0;
    alterations.forEach((a: IOrderDetail) => {
      if (a.itemType === "I" && a.alteration === "A") qty += a.quantity;
      price += a.lineTotal;
    });
    setAlterationPrice(price);
    setAlterationQty(qty);
  };
  const handleShowNotes = () => {
    setShowNotes(!showNotes);
  };
  const handleNotesChanges = (event: ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };
  const handleUpdateOrderDetail = () => {
    if(orderDetail){
      orderDetail.notes = notes;
      updateOrderDetail(orderDetail);
    }

  }
  const handleAddToOrder = () => {
    setOrderSaved(0);
    let od: IOrderDetail = { ...orderDetail };
    if (od.quantity === 0) {
      od.mainLineNo = mainLineNo;
      od.lineNo = mainLineNo * 100;
      od.price = item!.scPrice;
      incrementMainLineNo();
      setTurnAroundTime(item!.turnAroundTime);
    }
    od.quantity++;
    od.pieces += item!.pieces;
    od.lineTotal += item!.scPrice;
    od.discount += item!.discount;
    if(od.notes !== notes) 
      od.notes = notes;
    setOrderDetail({ ...od });
    addToOrder(od);
  };

  const handleRemoveFromOrder = () => {
    if (orderDetail!.quantity > 1) {
      let od: IOrderDetail = {
        mainLineNo: orderDetail!.mainLineNo,
        lineNo: orderDetail!.lineNo,
        mainCategory: item!.groupName,
        subCategory: item!.itemName,
        scUnique: item!.scUnique,
        stkCode: item!.stkCode,
        discountQty: 0,
        price: item!.scPrice,
        quantity: orderDetail!.quantity - 1,
        pieces: orderDetail!.pieces - item!.pieces,
        lineTotal: orderDetail!.lineTotal - item!.scPrice,
        alterationCode: 0,
        alteration: "",
        itemType: "I",
        collectionCharges: 0,
        deliveryCharges: 0,
        discountType: item ? (item?.discount > 0 ? "PR::0:" : "") : "",
        discount: orderDetail!.discount - item!.discount,
        headerDiscount: 0,
        notes: orderDetail!.notes ?? '',
      };
      setOrderDetail({ ...od });
      removeFromOrder(item!);
    } else {
      removeAllFromOrder(orderDetail!);
      setOrderDetail(initOrderDetail(item!));
      setNotes('');
    }
  };

  if (item === null) return <div className="product">Not Found!</div>;
  return (
    <div className="product">
      <div className="product-img">
        <img
          src={`${currentShop!.appDocUrl}/Item/${item.itemImage}?${new Date().getTime()}`}
          alt={item.itemName}
        />
      </div>
      <div className="product-details">
        <div className="product-name-wrapper">
        <div className="product-title">
          <h2>{item.itemName}</h2>
          <span className="product-price">
            &pound;&nbsp;{item.scPrice.toFixed(2)}
          </span>
        </div>
        <div className="product-desc">{item.description}</div>
        </div>
        <div className="product-service-wrapper">
          <div className="product-service">
            <span className="product-service-heading">{item.buttonCaption}</span>
            <div className="product-service-controls">
              {orderDetail?.quantity > 0 ? (
                <>
                  <FaMinusSquare onClick={handleRemoveFromOrder} />
                  <span className="product-service-qty">
                    {orderDetail?.quantity}
                  </span>
                  <FaPlusSquare onClick={handleAddToOrder} />
                  <span className="product-service-price">
                    &pound;&nbsp;{orderDetail?.lineTotal.toFixed(2)}
                  </span>
                </>
              ) : (
                <>
                    <button className="btn-small" onClick={() => handleAddToOrder()}>Add to Order</button>
                </>
              )}
              {currentShop?.hasUserNotes && orderDetail?.quantity > 0 && (<FaEdit data-tip="Notes" onClick={handleShowNotes} /> )}
            </div>
          </div>
          <div className="product-service">
            {currentShop!.hasAlterations && item!.hasAlterations && (
              <>
                <span className="product-service-heading">Alteration</span>
                {(alterationQty > 0) ?  (
                  <>
                    <div className="product-service-controls">
                        <span className="product-service-qty">{alterationQty}</span>
                      <FaPlusSquare onClick={() => setShowAlterationsTypeDialog(item)} />
                      <span className="product-service-price">
                        &pound;&nbsp;{alterationPrice.toFixed(2)}
                      </span>
                        <FaEdit data-tip="Click here to view and make changes" onClick={() => showAlterationsEdit(item)} />
                    </div>
                  </>
                ) : (
                    <button className="btn-small" onClick={() => setShowAlterationsTypeDialog(item)}>Add to Order</button>
                )}
              </>
              )}
          </div>
        </div>
        {showNotes && (
          <div className="product-note">
            <div className="input-group">
              <div className="input-icon">
                <FaPen />
              </div>
              <input
                name="notes"
                value={notes}
                onChange={handleNotesChanges}
                onKeyPress={(e) => {
                  var code = e.keyCode || e.which;
                  if(code === 13)
                    handleUpdateOrderDetail();
                }}
                onBlur={(e) => {
                  handleUpdateOrderDetail();
                }}
                className="input-field"
                type="text"
                placeholder="Any comments or notes"
              />
            </div>
          </div>
        )}
      </div>
      <ReactTooltip />
    </div>
  );
});
