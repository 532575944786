import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { RootStoreContext } from '../../stores/rootStore'

export const CurrentOffers = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const { myOffers, loadMyOffers, loadingMyOffers} = rootStore.userStore;

    useEffect(() =>{
        loadMyOffers()
    },[])

    return (
        <>
        <div className="basket-personal-details">
          <h2 className="basket-heading">Current Offers</h2>
        </div>
          { loadingMyOffers ? (<span className="input-error">Loading offers...</span>) : (
            <div className="offers-grid">
            <div className="offers-grid-heading">
                <div>Voucher Code</div>
                <div>Expiry Date</div>
                <div>Description</div>
            </div>
            { myOffers?.map((offer,i) => (
                <div key={i} className="offers-grid-row">
                    <div>{offer.voucherCode}</div>
                    <div>{offer.expiryDate}</div>
                    <div>{offer.description}</div>
                </div>
            ))}
            { myOffers.length === 0 && (
                <span className="input-error-big">Currently there are no offers for you</span>
            )}
        </div>
          )}


        </>
    )
});
