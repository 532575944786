import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { ContactDetails } from "../components/NewCustomer/ContactDetails";
import { PersonalDetails } from "../components/NewCustomer/PersonalDetails";
import { Footer } from "../components/Layout/Footer";
import { Header } from "../components/Layout/Header";
import { Form as FinalForm } from "react-final-form";
import { FORM_ERROR, ValidationErrors } from "final-form";
import { ICustomerForm } from "../models/user";
import { history } from "../";
import PostCodeLookUp from "../api/agentPostCode";
import { RootStoreContext } from "../stores/rootStore";
import { NewCustomerFooter } from "../components/NewCustomer/NewCustomerFooter";
import { confirmAlert } from "react-confirm-alert";

export const NewCustomerPage = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const { user, saveCustomer, verifyCustomer, signIn } = rootStore.userStore;
    const { setPrevRoute} = rootStore.commonStore;
    const [customerValues, setCustomerValues] = useState({
        accountCode: "",
            title: "",
            firstName: "",
            middleName: "",
            surname: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            postCode: "",
            emailId: "",
            mobileNo: "",
            password: "",
            confirmPassword: "",
            deliveryNotes: ""
    });
useEffect(()=>{
  setPrevRoute(window.location.pathname);
},[])
useEffect(() => {
    if (user)
      if (!user!.isCustomer) {
        setCustomerValues({
            accountCode: "",
            title: "",
            firstName: "",
            middleName: "",
            surname: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            postCode: user!.postCode,
            emailId: "",
            mobileNo: "",
            password: "",
            confirmPassword: "",
            deliveryNotes: ""
          });
      }
  }, [user]);

  const validate = async (values: ICustomerForm) => {
    const errors: ValidationErrors = [];
    if (!values.firstName) errors.firstName = "First name is required";
    if (!values.surname) errors.surname = "Surname is required";
    if (!values.emailId) errors.emailId = "Email id required";
    if (!values.mobileNo) errors.mobileNo = "Mobile no is required";
    if (values.mobileNo && (values.mobileNo.length < 10 ||  values.mobileNo.length > 11)) errors.mobileNo = "Invalid mobile number";
    if (!values.address1) errors.address1 = "Address is required";
    if (!values.postCode) errors.postCode = "Post code is required";
    else
      await PostCodeLookUp.lookUp(values.postCode)
        .then((res) => {
          if (!res) errors.postCode = "Invalid post code";
        })
        .catch(() => (errors.postCode = "Invalid post code"));
    if (!values.password) errors.password = "Password is required";
    if (!values.confirmPassword)
      errors.confirmPassword = "Kindly confirm your password";
    if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    )
      errors.confirmPassword = "Passwords don't match";
    return errors;
  };
  if(user!.isCustomer)
    history.push("/basket");
return (
    <>
     <Header />
     <FinalForm
        initialValues={customerValues}
        validate={validate}
        onSubmit={(values: ICustomerForm) => {          
          verifyCustomer(`0${values.mobileNo.substr(-10,10)}`,values.emailId).then((res) => {
            if(!res){
                saveCustomer(values)
                .then(() => history.push("/basket"))
                .catch((error) => ({
                  [FORM_ERROR]: error,
                }))
            } else {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='dialog-box'>
                  <div className="dialog-title">
                    <h2>Account Exists</h2>
                  </div>
                  <div className="dialog-body">
                     <p>An account with your details already exists, please Sign In</p>
                  </div>
                  <div className="dialog-footer">
                  <button className="btn btn-cancel" onClick={onClose}>Cancel</button>
                  <button className="btn btn-default"
                    onClick={() => {
                      signIn();
                      onClose();
                    }}
                  >
                    Sign In
                  </button>
                  </div>
                </div>
              );
            }});
            }
          }
          )
        }
        }
        render={({
          handleSubmit,
          submitError,
          submitting,
          invalid,
          pristine,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <main>
              <div className="basket-wrapper">
                <div className="basket-container">
                  <div className="basket-details">
                    <PersonalDetails />
                    {submitError && <span className="input-error-big">{submitError}</span>}
                  </div>
                  <div className="basket-details">
                    <ContactDetails />
                  </div>
                </div>
              </div>
            </main>
            <Footer>
              <NewCustomerFooter />
            </Footer>
          </form>
        )}
      />
    </>
);
});