import React from "react";
import { FaTimesCircle } from "react-icons/fa";

interface IProps {
  title: string;
  showDialog: boolean;
  hideDialog: () => void;
  saveData: () => void;
  saveDisabled: boolean;
  saveLabel?: string;
  hasScroll?: boolean;
}

export const Dialog: React.FC<IProps> = ({
  children,
  title,
  showDialog,
  hideDialog,
  saveData,
  saveDisabled,
  saveLabel = 'Save',
  hasScroll = false
}) => {
  return (
    <div id='dialog' className={`dialog-wrapper ${showDialog ? "dialog-show" : ""}`}>
      <div className="dialog-box">
        <div className="dialog-title">
          <h2 data-alter-title>{title}</h2>
          <span className="dialog-close">
            <FaTimesCircle onClick={() => hideDialog()} />
          </span>
        </div>
        <div className={`dialog-body  ${hasScroll ? "overflow-scroll" : ""}`}>{children}</div>
        <div className="dialog-footer">
          <button className="btn btn-cancel" onClick={hideDialog}>
            Cancel
          </button>
          <button disabled={saveDisabled} className="btn btn-default" onClick={saveData}>
            {saveLabel}
          </button>
        </div>
      </div>
    </div>
  );
};
