import { RootStore } from "./rootStore";
import agent from "../api/agent";
import { observable, action, runInAction, reaction } from "mobx";
import { addDays, addHours, formatDate, uniqueSlotDates } from "../utils/Utils";
import { ITimeSlot, ITimeSlotsDTO } from "../models/order";

export default class TimeSlotStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    reaction(
      () => this.defaultCollection,
      (collection) => {
        if (collection) {
          window.sessionStorage.setItem("mdc_collect", JSON.stringify(collection));
        } else {
          window.sessionStorage.removeItem("mdc_collect");
        }
      }
    );
    reaction(
      () => this.defaultDelivery,
      (delivery) => {
        if (delivery) {
          window.sessionStorage.setItem("mdc_deliver", JSON.stringify(delivery));
        } else {
          window.sessionStorage.removeItem("mdc_deliver");
        }
      }
    );
  }

  restoreTimeslot = (key:string) => {
    const odString = window.sessionStorage.getItem(key);
    if (odString) {
      return JSON.parse(odString);
    }
    return undefined;
  };
 
  @action clearTimeslots = () => {
    this.defaultCollection = undefined;
    window.sessionStorage.removeItem("mdc_collect");
    this.defaultDelivery = undefined;
    window.sessionStorage.removeItem("mdc_deliver");
  };

  @observable showCollectionTimeSlots: boolean = false;
  @observable showDeliveryTimeSlots: boolean = false;
  @action setShowCollectionTimeSlots = (show: boolean) => {
    this.showCollectionTimeSlots = show;
  };
  @action setShowDeliveryTimeSlots = (show: boolean) => {
    this.showDeliveryTimeSlots = show;
  };
  
  @observable defaultCollection: ITimeSlot | undefined = this.restoreTimeslot("mdc_collect");
  @observable noCollectionSlot: boolean = false;
  @observable loadingDefaultCollection = false;
  @action setLoadingDefaultCollection = (loading: boolean) => {
    this.loadingDefaultCollection = loading;
  }
  @action setDefaultCollection = (collection: ITimeSlot) => {
    this.defaultCollection = collection;
    this.noCollectionSlot = false;
  };
  @action getDefaultCollection = async (orderDate: Date, postCode: string) => {
    this.loadingDefaultCollection = true;
    agent.TimeSlot.defaultCollection(formatDate(addHours(orderDate,this.rootStore.userStore.user!.minInterval)), postCode)
      .then((timeSlot) => {
        runInAction(() => {
          this.setDefaultCollection(timeSlot);
          this.loadingDefaultCollection = false;
        });
      })
      .catch(() =>
        runInAction(() => {
          this.noCollectionSlot = true;
          this.noDeliverySlot = true;
        })
      );
  };

  @observable defaultDelivery: ITimeSlot | undefined = this.restoreTimeslot("mdc_deliver");
  @observable noDeliverySlot: boolean = false;
  @observable loadingDefaultDelivery = false;
  @action setLoadingDefaultDelivery = (loading: boolean) => {
    this.loadingDefaultDelivery = loading;
  }
  @action setDefaultDelivery = (delivery: ITimeSlot) => {
    this.defaultDelivery = delivery;
    this.noDeliverySlot = false;
  };
  @action getDefaultDelivery = async (
    collectionDate: Date,
    postCode: string
  ) => {
      this.loadingDefaultDelivery = true;
    agent.TimeSlot.defaultDelivery(formatDate(addDays(collectionDate,(this.rootStore.userStore.user!.minTurnAround > this.rootStore.orderStore.turnAroundTime ? this.rootStore.userStore.user!.minTurnAround : this.rootStore.orderStore.turnAroundTime))), postCode)
      .then((timeSlot) => {
        runInAction(() => {
          this.setDefaultDelivery(timeSlot);
          this.loadingDefaultDelivery = false;
        });
      })
      .catch(() => {
        runInAction(() => (this.noDeliverySlot = true));
      });
  };
/*
  @observable collectionTimeSlotDates: Date[] = [];
  @observable deliveryTimeSlotDates: Date[] = [];
  @action loadCollectionTimeSlotDates = () => {
      this.loadingCollectionSlots = true;
      if (this.weeklyCollectionSlots.length > 0) {
        this.collectionTimeSlotDates.splice(0, this.collectionTimeSlotDates.length);
        this.collectionTimeSlotDates = uniqueSlotDates(this.weeklyCollectionSlots);
      }
      this.loadingCollectionSlots = false;
  }
  @action loadDeliveryTimeSlotDates = () => {
      this.loadingDeliverySlots = true;
      if (this.weeklyDeliverySlots.length > 0) {
        this.deliveryTimeSlotDates.splice(0, this.deliveryTimeSlotDates.length);
        this.deliveryTimeSlotDates = uniqueSlotDates(this.weeklyDeliverySlots);
      }
      this.loadingDeliverySlots = false;
  };
*/
  @observable loadingCollectionSlots: boolean = false;
  @observable weeklyCollectionSlots: ITimeSlotsDTO[] = [];
  @action loadWeeklyCollectionSlots = (date: string, postCode: string) => {
    this.loadingCollectionSlots = true;
    agent.TimeSlot.weeklyCollection(date, postCode)
      .then((slots) => {
        runInAction(() => {
          this.weeklyCollectionSlots = slots;
          this.loadingCollectionSlots = false;
        });
      })
      .catch((err) => console.log(err));
  };
  @observable collectionSlotsForDate: ITimeSlot[] = [];
  @observable loadingCollectionSlotsForDate = false;
  @action getCollectionSlotsForDate = (date: Date) => {
    this.loadingCollectionSlotsForDate = true;
    let ts = this.weeklyCollectionSlots.find(t => new Date(t.timeSlotDate).getTime() === new Date(date).getTime());
    if(ts !== undefined)
      this.collectionSlotsForDate = [...ts.slots];
    else
      this.collectionSlotsForDate = [];
    runInAction(() => this.loadingCollectionSlotsForDate = false);
  };
  @observable loadingDeliverySlots: boolean = false;
  @observable weeklyDeliverySlots: ITimeSlotsDTO[] = [];
  @action loadWeeklyDeliverySlots = (date: string, postCode: string) => {
    this.loadingDeliverySlots = true;
    agent.TimeSlot.weeklyDelivery(date, postCode)
      .then((slots) => {
        runInAction(() => {
          this.weeklyDeliverySlots = slots;
          this.loadingDeliverySlots = false;
        });
      })
      .catch((err) => console.log(err));
  };
  
  @observable deliverySlotsForDate: ITimeSlot[] = [];
  @observable loadingDeliverySlotsForDate = false;
  @action getDeliverySlotsForDate = (date: Date) => {
    this.loadingDeliverySlotsForDate = true;
    let ts = this.weeklyDeliverySlots.find(t => new Date(t.timeSlotDate).getTime() === new Date(date).getTime());
    if(ts !== undefined)
      this.deliverySlotsForDate = [...ts.slots];
    else
      this.deliverySlotsForDate = [];
    runInAction(() => this.loadingDeliverySlotsForDate = false);
  };

  @action deliverySlotsAvailable = (date: Date) => {
    return this.weeklyDeliverySlots.some(
      (s) => new Date(s.timeSlotDate).getTime() === new Date(date).getTime()
    );
  };

  @action collectionSlotsAvailable = (date: Date) => {
    return this.weeklyCollectionSlots.some(
      (s) => new Date(s.timeSlotDate).getTime() === new Date(date).getTime()
    );
  };

  @observable slotsForDate: ITimeSlot[] = [];
  @observable loadingSlots: boolean = false;
  @action loadSlotsForDate = (date: string, postCode: string) => {
    this.loadingSlots = true;
    agent.TimeSlot.slotfordate(date,postCode)
    .then((slots) => {
        runInAction(() => {
          this.slotsForDate = slots;
          this.loadingSlots = false;
        });
      })
  }
}
