import React, { useContext } from 'react'
import { history } from "../../";
import { RootStoreContext } from "../../stores/rootStore";

export const BackToOrdersFooter = () => {
  const rootStore = useContext(RootStoreContext);
  const { currentShop } = rootStore.commonStore;
  const { user } = rootStore.userStore;
return (
    <div className="common-footer">
        <div className="common-footer-buttons">
            {(currentShop?.itemSelectionOptions === 0 || user?.noItemSelect) ? (
                <></>
            ) : (
                <button onClick={() => history.push("/order")} className="btn-medium btn-bottom-toolbar mt1r">Back to Order Booking</button>
            )}
        </div>
    </div>
)
}
