import { RootStore } from "./rootStore";
import { observable, action, reaction, computed, runInAction} from "mobx";
import { IOrderDetail, IOrder, IOrderBasket, IAppOrderEntity, IBundleOrderGroupEntity, ITimeSlot } from "../models/order";
import { ISubCategory } from "../models/category";
import agent from "../api/agent";
import { formatDate, getDateFullNameString, getTimeString } from "../utils/Utils";
import { IVoucher } from "../models/voucher";
import { IRegularVisit, IScheduledVisit } from "../models/visit";

export default class OrderStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    reaction(
      () => this.currentOrder,
      (order) => {
        if(order)
          window.localStorage.setItem("mdc_current_order", JSON.stringify(order));
        else
          window.localStorage.removeItem("mdc_current_order");
      }
    )
    reaction(
      () => this.orderDetails,
      (details) => {
        if (details.length > 0) {
          window.localStorage.setItem("mdc_order", JSON.stringify(details));
        } else {
          window.localStorage.removeItem("mdc_order");
        }
      }
    );
    reaction(
      () => this.basketValues,
      (bv) => {
        if(bv.postCode.length > 0){
          window.localStorage.setItem("mdc_basket", JSON.stringify(bv));
        } else {
          window.localStorage.removeItem("mdc_basket");
        }
      }
    );
    reaction(
      () => this.orderSaved,
      (os) => {
        if(os === 1){
          window.sessionStorage.setItem("mdc_saved", os.toString());
        } else {
          window.sessionStorage.removeItem("mdc_saved");
        }
      }
    );
  }

  restoreOrderDetails = () => {
    const odString = window.localStorage.getItem("mdc_order");
    if (odString) {
      return JSON.parse(odString);
    }
    return [];
  };

  initBasketValues = () => {
    return {
      accountCode: "",
      title: "",
      firstName: "",
      middleName: "",
      surname: "",
      address1: "",
      address2: "",
      postCode: "",
      emailId: "",
      mobileNo: "",
      password: "",
      confirmPassword: "",
      deliveryNotes: "",
      orderNotes: "",
      discountCode: "",
      headerDiscount: 0,
      grossAmount: 0,
      minCharge: 0,
      collectFromShop: false,
    };
  }
  restoreBasketValues = () => {
    const bvString = window.localStorage.getItem("mdc_basket");
    if(bvString) {
      return JSON.parse(bvString);
    }
    return this.initBasketValues();
  }

  getLastMainLineNo = () => {
    let mLineNo = 10;
    if (this.orderDetails?.length > 0) {
      this.orderDetails.forEach((detail) => {
        if (detail.mainLineNo > mLineNo) mLineNo = detail.mainLineNo;
      });
    }
    mLineNo++;
    return mLineNo;
  };
  @observable turnAroundTime: number = 0;
  @action setTurnAroundTime = (tt:number) =>{
    if(tt)
    if(this.turnAroundTime < tt)
      this.turnAroundTime = tt;
  }
  @action resetTurnAroundTime = () =>{
    this.turnAroundTime = 0;
  }
  @observable loadingOrder: boolean = false;
    @action setLoadingOrder = (loading: boolean) => {
    this.loadingOrder = loading;
  };
  @observable orderSaved: number = window.sessionStorage.getItem("mdc_saved") ? parseInt(window.sessionStorage.getItem("mdc_saved")!) : 0;
  @action setOrderSaved = (saved: number) =>{
    this.orderSaved = saved;
  }
  @observable savingOrder: boolean = false;
  @observable currentOrder: IOrder | undefined;
  @observable orderDetails: IOrderDetail[] = this.restoreOrderDetails();
  @observable basketValues: IOrderBasket = this.restoreBasketValues();
  @observable mainAlterationDetail: IOrderDetail | undefined;
  
  @action setMainAlterationDetail = (detail: IOrderDetail | undefined) => {
    this.mainAlterationDetail = detail;
  }
  @computed get currentAlterationType() {
    if(this.mainAlterationDetail){
      return (this.mainAlterationDetail.alterationCode === 5) ? 2 : 1;
    } else
      return 0;
  }

  @observable unPickedOrders: IAppOrderEntity[] = [];
  @observable loadingUnPickedOrders: boolean = false;
  @action setLoadingUnPickedOrders = (value: boolean) => this.loadingUnPickedOrders = value;
  

  @observable pickedOrders: IAppOrderEntity[] = [];
  @observable loadingPickedOrders: boolean = false;
  @action setLoadingPickedOrders = (value: boolean) => this.loadingPickedOrders = value;

  @observable ordersInProcess: IBundleOrderGroupEntity[] = [];
  @observable loadingOrdersInProcess: boolean = false;
  @action setLoadingOrdersInProcess = (value: boolean) => this.loadingOrdersInProcess = value;

  @observable ordersReady: IBundleOrderGroupEntity[] = [];
  @observable loadingOrdersReady: boolean = false;
  @action setLoadingOrdersReady = (value: boolean) => this.loadingOrdersInProcess = value;

  @observable ordersOnDelivery: IBundleOrderGroupEntity[] = [];
  @observable loadingOrdersOnDelivery: boolean = false;
  @action setLoadingOrdersOnDelivery = (value: boolean) => this.loadingOrdersOnDelivery = value;

  @observable ordersDelivered: IBundleOrderGroupEntity[] = [];
  @observable loadingOrdersDelivered: boolean = false;
  @action setLoadingOrdersDelivered = (value: boolean) => this.loadingOrdersDelivered = value;

  @action setBasketValues = (values:IOrderBasket) => this.basketValues = values;
  @observable discountVoucher: IVoucher | null = null;
  @action setDiscountVoucher = (voucher: IVoucher | null) => this.discountVoucher = voucher;
  
  @observable loadingVisitSlot: boolean = false;
  @observable visitSlot: ITimeSlot | undefined;
  @action setVisitSlot = (slot: ITimeSlot | undefined) => {
    this.visitSlot = slot;
  }
  @observable selectedVisitUID: string = "";
  @action setSelectedVisitUID = (uid: string) =>{
    this.selectedVisitUID = uid;
  }
  @observable showChangeSlotDialog: boolean = false;
  @action setShowChangeSlotDialog = (show: boolean) => {
    this.showChangeSlotDialog = show;
  } 
  @computed get totalPieces() {
    let total = 0;
    this.orderDetails?.forEach((detail) => {
      total += detail.pieces;
    });
    return total;
  }

  @computed get totalQuantity() {
    let total = 0;
    this.orderDetails?.forEach((detail) => {
      if (detail.itemType === "I") total += detail.quantity;
    });
    return total;
  }

  @computed get totalGrossAmount() {
    let total = 0;
    this.orderDetails?.forEach((detail) => {
      total += detail.lineTotal;
    });
    return total;
  }

  @computed get totalDiscount() {
    let total = 0;
    this.orderDetails?.forEach((detail) => {
      total += detail.discount;
    });
    total += this.headerDiscount;
    return total;
  }

  @observable headerDiscount: number = 0;
  @action setHeaderDiscount = (discount:number) => {
    this.headerDiscount = discount;
  }

  @observable showBasket: boolean = false;
  @action setShowBasket = (show: boolean) => {
    this.showBasket = show;
  }
  @observable mainLineNo: number = this.getLastMainLineNo();

  @action incrementMainLineNo = () => {
    this.mainLineNo++;
  };

  @action createOrder = (accountCode: string) => {
    this.currentOrder = {
      accountCode: accountCode,
      orderDate: new Date(),
      orderNo: 0,
      quantity: this.totalQuantity,
      pieces: this.totalPieces,
      netAmount: 0,
      vatAmount: 0,
      grossAmount: this.totalGrossAmount,
      grandTotal: this.totalGrossAmount,
      deliveryCharges: 0,
      collectionCharges: 0,
      discountCode: "",
      headerDiscount: 0,
      lineDiscount: this.totalDiscount,
      totalDiscount: this.totalDiscount,
      minCharge: 0,
      cCampUniqueRecID: "",
      collectionSlot: this.rootStore.timeSlotStore.defaultCollection!,
      deliverySlot: this.rootStore.timeSlotStore.defaultCollection!,
      orderNotes: "",
      deliveryNotes: "",
      collectFromShop: false,
      cardUniqueRecId: "",
      orderDetails: this.orderDetails
    };
  };

  @action clearOrder = () => {
    this.currentOrder = undefined;
  };

  @action clearOrderDetails = () => {
    this.orderDetails.length = 0;
    window.localStorage.removeItem("mdc_order");
  };

  @action clearBasketValues = () => {
    this.basketValues = this.initBasketValues();
    window.localStorage.removeItem("mdc_basket");
  }
  @action addToOrder = (orderDetail: IOrderDetail) => {
    this.orderDetails = [
      ...this.orderDetails.filter(
        (od) =>
          od.scUnique !== orderDetail.scUnique ||
          (od.scUnique === orderDetail.scUnique &&
          od.mainLineNo !== orderDetail.mainLineNo) 
      ),
      orderDetail,
    ];
  };

  @action removeFromOrder = (item: ISubCategory) => {
    let orderDetail = this.orderDetails.find(
      (od) =>
        od.scUnique === item.scUnique &&
        od.alteration === "" &&
        od.itemType === "I"
    );
    if (orderDetail !== undefined) {
      orderDetail.quantity--;
      orderDetail.pieces -= item.pieces;
      orderDetail.lineTotal -= item.pmPrice;
      orderDetail.discount -= item.discount;
      this.orderDetails = [
        ...this.orderDetails.filter(
          (od) =>
            od.mainLineNo !== orderDetail!.mainLineNo &&
            od.lineNo !== orderDetail!.lineNo
        ),
        orderDetail,
      ];
    }
  };
  @action updateOrderDetail = (orderDetail: IOrderDetail) =>{
    this.orderDetails = [
      ...this.orderDetails.filter(
        (od) => od.mainLineNo !== orderDetail.mainLineNo && od.lineNo !== orderDetail.lineNo
      ),
      orderDetail
    ];
  }

  @action removeAllFromOrder = (orderDetail: IOrderDetail) => {
    this.orderDetails = [
      ...this.orderDetails.filter(
        (od) => od.mainLineNo !== orderDetail.mainLineNo
      ),
    ];
  };

  @action getOrderDetailForItem = (item: ISubCategory) => {
    const orderDetail = this.orderDetails?.find(
      (od) =>
        od.scUnique === item.scUnique &&
        od.alteration === "" &&
        od.itemType === "I"
    );
    return orderDetail;
  };

  @action initOrderDetail = (item: ISubCategory) => {
    return {
      mainLineNo: this.mainLineNo,
      lineNo: this.mainLineNo * 100,
      mainCategory: item?.groupName,
      subCategory: item?.itemName,
      scUnique: item?.scUnique,
      stkCode: item?.stkCode,
      discountQty: 0,
      price: 0,
      quantity: 0,
      pieces: 0,
      lineTotal: 0,
      alterationCode: 0,
      alteration: "",
      itemType: "I",
      collectionCharges: 0,
      deliveryCharges: 0,
      discountType: item ? (item?.discount > 0 ? "PR::0:" : "") : "",
      discount: 0,
      headerDiscount: 0,
      notes: "",
    };
  };

  @action addAlterations = (alterations: IOrderDetail[]) => {
    this.orderDetails = [...this.orderDetails, ...alterations];
  };
  @action updateAlterationsType = (mainDetail: IOrderDetail) => {
    this.orderDetails = [...this.orderDetails.filter((od) => od.mainLineNo !== mainDetail.mainLineNo || (od.mainLineNo === mainDetail.mainLineNo && od.lineNo !== mainDetail.lineNo)),mainDetail];
  }
  @action updateAlterations = (alterations: IOrderDetail[]) =>{
  alterations.forEach((alter) => {
  this.orderDetails = [
      ...this.orderDetails.filter(
        (od) => od.mainLineNo !== alter.mainLineNo || (od.mainLineNo === alter.mainLineNo && od.lineNo !== alter.lineNo)
      ),
      alter
    ];

  })  
  }
  @action removeAlteration = (mainLineNo: number, lineNo: number) => {
    this.orderDetails = [
      ...this.orderDetails.filter(
        (od) => od.mainLineNo !== mainLineNo || (od.mainLineNo === mainLineNo && od.lineNo !== lineNo)
      ),
    ];
  };
  @action removeAllAlterations = (mainLineNo: number) => {
    this.orderDetails = [
      ...this.orderDetails.filter((od) => od.mainLineNo !== mainLineNo),
    ];
  };

  @action getAlterationDetailsForItem = (item: ISubCategory) => {
    let alterations: IOrderDetail[] = []; 
    let mainDetails = this.orderDetails?.filter(
      (od) => od.scUnique === item.scUnique && od.alteration === "A"
    );
    if(mainDetails){
      mainDetails.forEach((d) => {
        alterations = [...alterations,...this.orderDetails?.filter( (od) => od.mainLineNo === d.mainLineNo)];
      })
    }
    return alterations;

/*    return this.orderDetails?.filter(
      (od) => od.scUnique === item.scUnique && od.alteration === "A"
    );*/
  };
  @action getAlterationDetails = (mainLineNo: number) => {
   return this.orderDetails?.filter(
      (od) => od.mainLineNo === mainLineNo);
  }
  @action getMainAlterationDetails = (mainLineNo: number) => {
    let detail = this.orderDetails?.find(
      (od) => od.mainLineNo === mainLineNo && od.alteration === 'A' && od.itemType === 'I');
   return detail;
  }

  @action deleteAlteration = (mainLineNo: number, lineNo: number) => {
    const od = this.orderDetails.find(
      (d) => d.mainLineNo === mainLineNo && d.lineNo === lineNo
    );
    if (od) {
      if (od.itemType === "I") {
        this.removeAllAlterations(mainLineNo);
      } else {
        const alters = this.orderDetails.filter(
          (d) => d.mainLineNo === mainLineNo
        );
        if (alters.length <= 2) {
          this.removeAllAlterations(mainLineNo);
        }  else {
          this.removeAlteration(mainLineNo, lineNo);
        } 
      }
  }
}
  @action deleteOrderDetail = (mainLineNo: number, lineNo: number) => {
    const od = this.orderDetails.find(
      (d) => d.mainLineNo === mainLineNo && d.lineNo === lineNo
    );
    if (od) {
      if (od.itemType === "I") {
        if (od.alteration === "") {
          if (od.quantity > 1) {
            let pieces = od.pieces / od.quantity;
            let discount = od.discount / od.quantity;
            od.quantity--;
            od.pieces -= pieces;
            od.lineTotal -= od.price;
            od.discount -= discount;
            this.orderDetails = [
              ...this.orderDetails.filter(
                (d) =>
                  d.mainLineNo !== od!.mainLineNo && d.lineNo !== od!.lineNo
              ),
              od,
            ];
          } else {
            this.removeAllFromOrder(od);
          }
        } else {
          this.removeAllAlterations(mainLineNo);
        }
      } else {
        const alters = this.orderDetails.filter(
          (d) => d.mainLineNo === mainLineNo
        );
        if (alters.length === 2) this.removeAllAlterations(mainLineNo);
        else this.removeAlteration(mainLineNo, lineNo);
      }
      runInAction(() => {
        this.currentOrder!.orderDetails = [...this.orderDetails];
      })
    }
  };
  @action checkVoucher = async (voucherCode: string): Promise<IVoucher | null> => {
    return await agent.Order.checkVoucher(voucherCode,formatDate(new Date()));
 }

  @action saveOrder = async () => {
    this.savingOrder = true;
    try {
      
        if (
          this.totalGrossAmount < this.rootStore.userStore.user!.minOrderValue
        )
          runInAction(
            () =>
              (this.currentOrder!.minCharge =
                this.rootStore.userStore.user!.minOrderValue -
                this.totalGrossAmount)
          );
          
        runInAction(() => {
          const {
            defaultCollection,
            defaultDelivery,
          } = this.rootStore.timeSlotStore;
          this.currentOrder!.quantity= this.totalQuantity;
          this.currentOrder!.pieces= this.totalPieces;
          this.currentOrder!.grossAmount= this.totalGrossAmount;
          this.currentOrder!.grandTotal= this.totalGrossAmount;
          this.currentOrder!.lineDiscount= this.totalDiscount;
          this.currentOrder!.totalDiscount= this.totalDiscount;
          this.currentOrder!.orderDetails= [...this.orderDetails];
          this.currentOrder!.collectionSlot = defaultCollection!;
          this.currentOrder!.deliverySlot = defaultDelivery!;
          this.currentOrder!.collectFromShop = this.basketValues.collectFromShop;
          this.currentOrder!.orderNotes = this.basketValues.orderNotes;
          if(this.basketValues.deliveryNotes.length > 0)
            this.currentOrder!.deliveryNotes = this.basketValues.deliveryNotes;
          this.currentOrder!.cardUniqueRecId = this.rootStore.userStore.defaultCardUID;
          if(this.discountVoucher){
            // Calculate discount here
            let discPercent = 0; let discApplied = 0;
            this.currentOrder!.cCampUniqueRecID = this.discountVoucher.cCampUniqueRecID; 
            this.currentOrder!.discountCode = this.basketValues.discountCode;
            this.currentOrder!.headerDiscount = this.headerDiscount;
            if(this.discountVoucher.offerType === 0)
              discPercent = (this.headerDiscount/this.totalGrossAmount) * 100;
            else
              discPercent = this.discountVoucher.percentDiscount;
            for(let index=0; index < this.orderDetails.length; index++){
              if(this.discountVoucher.offerType === 0 && index === (this.orderDetails.length -1))
                break
              let od:IOrderDetail = this.orderDetails[index];
              od.headerDiscount = parseFloat((od.lineTotal * (discPercent/100)).toFixed(2));
              od.discountType += `${this.basketValues.discountCode}:${this.discountVoucher.cCampUniqueRecID}:${this.discountVoucher.offerType === 0?0:od.headerDiscount}:${this.discountVoucher.offerType === 1?0:od.headerDiscount};` 
              discApplied += od.headerDiscount;
            }
            if(this.discountVoucher.offerType === 0)
              this.orderDetails[this.orderDetails.length-1].headerDiscount = parseFloat((this.headerDiscount - discApplied).toFixed(2));
          }
        });
        this.rootStore.userStore.updateDefaultCustomerCard()
        if(this.currentOrder!.orderNo > 0){
          runInAction(() => {
            this.currentOrder!.orderDetails.length = 0;
             this.currentOrder!.orderDetails = [...this.orderDetails];
          })
        agent.Order.updateOrder(this.currentOrder!).then((response) => {
          runInAction(() => {
            if(response){
              let html =  this.rootStore.commonStore.currentShop!.orderSaveMessage;
               this.rootStore.commonStore.setMessage(html
                                                        .replace("[OrderNumber]",this.currentOrder!.orderNo.toString())
                                                        .replace("[Date]", getDateFullNameString(this.currentOrder!.collectionSlot.slotDate)!)
                                                        .replace("[Time]",getTimeString(this.currentOrder!.collectionSlot.slotTimeFrom) + ':00 - ' + getTimeString(this.currentOrder!.collectionSlot.slotTimeTo) + ':00')
                                                        .replace("[ShopTelephone]",this.rootStore.userStore.user!.shopTelephone)
                                                        .replace("[ReviewLink]", this.rootStore.userStore.user!.reviewLink)
                );
            } else {
               this.rootStore.commonStore.setMessage(
                  "An error occured while saving your order. Please try again later, or contact support if error persits."
                );
            }
            this.currentOrder = undefined;
            this.orderDetails.length = 0;
            this.basketValues = this.initBasketValues();
            window.localStorage.removeItem("mdc_order");
            window.localStorage.removeItem("mdc_basket");
            this.rootStore.timeSlotStore.clearTimeslots();
            this.savingOrder = false;
            this.setOrderSaved(1);
          });
        });
        } else {
        agent.Order.saveOrder(this.currentOrder!).then((orderno) => {
          runInAction(() => {
            if(orderno > 0){
            this.currentOrder!.orderNo = orderno;
            let html =  this.rootStore.commonStore.currentShop!.orderSaveMessage;
            this.rootStore.commonStore.setMessage(html
                                                  .replace("[OrderNumber]", orderno.toString())
                                                  .replace("[Date]", getDateFullNameString(this.currentOrder!.collectionSlot.slotDate)!)
                                                  .replace("[Time]",getTimeString(this.currentOrder!.collectionSlot.slotTimeFrom) + ':00 - ' + getTimeString(this.currentOrder!.collectionSlot.slotTimeTo) + ':00')
                                                  .replace("[ShopTelephone]",this.rootStore.userStore.user!.shopTelephone)
                                                  .replace("[ReviewLink]", this.rootStore.userStore.user!.reviewLink)
              );
            } else {
               this.rootStore.commonStore.setMessage(
                  "An error occured while saving your order. Please try again later, or contact support if error persits."
                );
            }
            this.currentOrder = undefined;
            this.orderDetails.length = 0;
            this.basketValues = this.initBasketValues();
            window.localStorage.removeItem("mdc_order");
            window.localStorage.removeItem("mdc_basket");
            this.rootStore.timeSlotStore.clearTimeslots();
            this.savingOrder = false;
            this.setOrderSaved(1);
          });
        });

        }

    } catch (error) {
      throw error;
    } 
  };
  @action resetOrder = () =>{
            this.currentOrder = undefined;
            this.orderDetails.length = 0;
            this.orderDetails = [];
            this.basketValues = this.initBasketValues();
            this.rootStore.timeSlotStore.clearTimeslots();
            this.setOrderSaved(1);
  }

  @action reCalculatePrices = async () => {
    this.resetTurnAroundTime();
    this.orderDetails.forEach((od) => {
      this.rootStore.categoryStore.loadSubCategories(od.mainCategory);
      while(this.rootStore.categoryStore.loadCategories){;}
      this.rootStore.categoryStore.loadSubCategories(od.mainCategory);
      while(this.rootStore.categoryStore.loadingSubCategories){;}
      var subCat = this.rootStore.categoryStore.subCategories.find((sc) => sc.scUnique === od.scUnique);
      if(subCat){
        this.setTurnAroundTime(subCat.turnAroundTime);
        if(subCat.discount > 0){
          if (od.discount !== (subCat.discount*od.quantity)){
            od.discount = subCat.discount*od.quantity;
          }
        }
      }
    })
  }

 
  @action editOrder = (uid: string) => {
    this.setLoadingOrder(true);
    agent.Order.getOrder(uid).then(order => {
      runInAction(() => {
      this.currentOrder = {
            accountCode: order.accountCode,
            orderDate: order.orderDate,
            orderNo: order.orderNo,
            quantity: order.quantity,
            pieces: order.pieces,
            netAmount: order.netAmount,
            vatAmount: order.vatAmount,
            grossAmount: order.grossAmount,
            grandTotal: order.grandTotal,
            deliveryCharges: order.deliveryCharges,
            collectionCharges: order.collectionCharges,
            discountCode: order.discountCode,
            headerDiscount: order.headerDiscount,
            lineDiscount: order.lineDiscount,
            totalDiscount: order.totalDiscount,
            minCharge: order.minCharge,
            cCampUniqueRecID: order.cCampUniqueRecID,
            collectionSlot: order.collectionSlot,
            deliverySlot: order.deliverySlot,
            orderNotes: order.orderNotes,
            deliveryNotes: order.deliveryNotes,
            collectFromShop: order.collectFromShop,
            cardUniqueRecId: order.cardUniqueRecId,
            orderDetails: order.orderDetails,
          };
          this.orderDetails.length = 0;
          this.orderDetails = order.orderDetails;
          this.rootStore.timeSlotStore.defaultCollection = order.collectionSlot;
          this.rootStore.timeSlotStore.defaultDelivery = order.deliverySlot;
          this.rootStore.userStore.defaultCardUID = order.cardUniqueRecId;
      });
      this.setLoadingOrder(false);
    })
  }
  
  @observable loadingRegularVisits: boolean = false;
  @observable loadingScheduledVisits: boolean = false;
  @observable regularVisits: IRegularVisit[] = [];
  @observable scheduledVisits: IScheduledVisit[] = [];
  @action loadRegularVisits = () =>{
    this.loadingRegularVisits = true;
    agent.Visit.regular().then((result) =>{
      runInAction(()=>{
        this.regularVisits = result;
        this.loadingRegularVisits = false;
      })
    })
  };
  @action loadScheduledVisit = () =>{
    this.loadingScheduledVisits = true;
    agent.Visit.scheduled().then((result) => {
      runInAction(()=>{
        this.scheduledVisits = result;
        this.loadingScheduledVisits = false;
      })
    })
  };
  @observable changeVisitSlot = (visitUID: string, slotUID: string, ordersType: number) => {
    agent.Visit.updateSlot(visitUID, slotUID).then((res) => {
      if(res){
          switch(ordersType){
            case -1:
              this.loadScheduledVisit();
              break;
            case 1:
              this.getUnPickedOrders();
              break;
            case 2:
              this.getPickedOrders();
              break;
            case 3:
              this.getOrdersInProcess();
              break;
            case 4: 
                this.getOrdersReady();
                break;
            case 5:
              this.getOrdersOnDelivery();
              break;
            case 6:
              this.getOrdersDelivered();
              break;
          }
      }
    });
  }
  @observable deleteVisit = (uniqueRedId: string) => {
    agent.Visit.delete(uniqueRedId).then((res) => {
      if(res){
        runInAction(()=>{
          this.scheduledVisits = this.scheduledVisits.filter(v => v.uniqueRecId !== uniqueRedId);
        })
      }
    });
  }

  
  @action getUnPickedOrders = async () => {
    if(this.loadingUnPickedOrders)
      return;
    this.setLoadingUnPickedOrders(true);
    this.unPickedOrders = [];
    agent.Order.getUnPickedOrders().then((response) => {
      if(response)
      runInAction(() => {
        this.unPickedOrders = response;
      })
      this.setLoadingUnPickedOrders(false);
    })
  }

  @action getPickedOrders = async () => {
    if(this.loadingPickedOrders)
      return;
    this.setLoadingPickedOrders(true);
    this.pickedOrders = [];
    agent.Order.getPickedOrders().then((response) => {
      if(response)
      runInAction(() => {
        this.pickedOrders = response;
      })
      this.setLoadingPickedOrders(false);
    })
  }

  @action getOrdersInProcess = async () => {
    if(this.loadingOrdersInProcess)
      return;
    this.setLoadingOrdersInProcess(true);
    this.ordersInProcess = [];
    agent.Order.getOrdersInProcessing().then((response) => {
      if(response)
        runInAction(() => {
          this.ordersInProcess = response;
        })
      this.setLoadingOrdersInProcess(false);
    })
  }

  @action getOrdersReady = async () => {
    if(this.loadingOrdersReady)
      return;
    this.setLoadingOrdersReady(true);
    this.ordersReady = [];
    agent.Order.getOrdersReady().then((response) => {
      if(response)
        runInAction(() => {
          this.ordersReady = response;
        })
      this.setLoadingOrdersReady(false);
    })
  }

  @action getOrdersOnDelivery = async () => {
    if(this.loadingOrdersOnDelivery)
      return;
    this.setLoadingOrdersOnDelivery(true);
    this.ordersOnDelivery = [];
    agent.Order.getOrdersOnDelivery().then((response) => {
      if(response)
        runInAction(() => {
          this.ordersOnDelivery = response;
        })
      this.setLoadingOrdersOnDelivery(false);
    })
  }

  @action getOrdersDelivered = async () => {
    if(this.loadingOrdersDelivered)
      return;
    this.setLoadingOrdersDelivered(true);
    this.ordersDelivered = [];
    agent.Order.getOrdersDelivered().then((response) => {
      if(response)
        runInAction(() => {
          this.ordersDelivered = response;
        })
      this.setLoadingOrdersDelivered(false);
    })
  }
}
