import React, { useContext } from "react";
import { Field } from "react-final-form";

import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { LoadingComponent } from "../Common/LoadingComponent";

export const ProfilePersonalDetails = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { customer } = rootStore.userStore;
  if(!customer)
  return <LoadingComponent message="Loading..." />
  return (
    <div className="basket-personal-details">
      <div className="personal-profile-heading">
        <div>Personal Details</div>
        <div>{customer.accountCode}</div>
      </div>
        <Field name="firstName">
          {({ input, meta }) => (
            <>
            <div className="basket-field-group">
              <label className="basket-field-group-label">First Name</label>
              <input
                className="basket-field-group-input"
                type="text"
                placeholder="First Name"
                {...input}
              />
            </div>
                {meta.touched && (meta.error || meta.submitError) && (
                <span className="input-error basket-field-group-error">
                  {meta.error || meta.submitError}
                </span>
              )}

            </>
          )}
        </Field>
        
        <Field name="middleName">
          {({ input, meta }) => (
            <div className="basket-field-group">
            <label className="basket-field-group-label">Middle Name</label>
            <input
              className="basket-field-group-input"
              type="text"
              placeholder="Middle Name"
              {...input}
            />
            </div>
          )}
        </Field>
        <Field name="surname">
          {({ input, meta }) => (
            <>
            <div className="basket-field-group">
              <label className="basket-field-group-label">Surname</label>
              <input
                className="basket-field-group-input"
                type="text"
                placeholder="Surname"
                {...input}
              />
             
            </div>
                          {meta.touched && (meta.error || meta.submitError) && (
                <span className="input-error basket-field-group-error">
                  {meta.error || meta.submitError}
                </span>
 )}
            </>
          )}
        </Field>
          <div className="basket-field-group">
            <label className="basket-field-group-label">Email Address</label>
            <label className="basket-field-group-input">{customer!.emailId}</label>
          </div>
          <div className="basket-field-group">
            <label className="basket-field-group-label">Mobile No</label>
            <label className="basket-field-group-input">{customer!.mobileNo}</label>
          </div>
    </div>
  );
});