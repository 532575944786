import React, { useContext, useState, useEffect } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { ILoginFormValues } from "../../models/user";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { FaUser, FaKey, FaStore } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
export const LoginBox = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { shops, currentShop } = rootStore.commonStore;
  const { login, setShowLogin } = rootStore.userStore;
  const [loginValues, setLoginValues] = useState({
    appKey: currentShop?.appKey || "",
    username: "",
    password: "",
    shopCode: currentShop?.shopCode || "",
    rememberMe: false,
  });
  useEffect(() => {
    if (currentShop)
      setLoginValues({
        ...loginValues,
        appKey: currentShop?.appKey,
        shopCode: currentShop?.shopCode,
      });
  }, [currentShop]);
  const validate = combineValidators({
    username: isRequired("Username"),
    password: isRequired("Password"),
  });
  return (
    <div className="dialog-box login-box">
      <div className="login-box-title">
        <h2>Sign In</h2>
        </div>
      <FinalForm
        validate={validate}
        initialValues={loginValues}
        onSubmit={(values: ILoginFormValues) =>
          login(values).catch((error) => {
            if(error?.notAllowed)
              return {
                  [FORM_ERROR]: error.error,
              }
            else
              return {
                  [FORM_ERROR]: "Invalid username and/or password",
              }
        }
          )
        }
        render={({
          handleSubmit,
          submitError,
          submitting,
          invalid,
          pristine,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Field name="username">
              {({ input, meta }) => (
                <div className="input-group">
                  <div className="input-icon">
                    <FaUser />
                  </div>
                  <input
                    tabIndex={1}
                    className="input-field"
                    type="text"
                    {...input}
                    placeholder="Customer Account or Email Id"
                  />
                  {meta.touched && (meta.error || meta.submitError) && (
                    <span className="input-error">
                      {meta.error || meta.submitError}
                    </span>
                  )}
                </div>
              )}
            </Field>
            <Field name="password">
              {({ input, meta }) => (
                <div className="input-group">
                  <div className="input-icon">
                    <FaKey />
                  </div>
                  <input
                    tabIndex={2}
                    className="input-field"
                    type="password"
                    {...input}
                    placeholder="Password"
                  />
                  {meta.touched && (meta.error || meta.submitError) && (
                    <span className="input-error">
                      {meta.error || meta.submitError}
                    </span>
                  )}
                </div>
              )}
            </Field>
            {shops.length > 1 && (
              <Field name="shopCode" type="select">
                {({ input }) => (
                  <div className="input-group">
                    <div className="input-icon">
                      <FaStore />
                    </div>
                    <select tabIndex={3} className="input-field" {...input}>
                      {shops.map((shop) => (
                        <option key={shop.uniqueRecId} value={shop.shopCode}>
                          {shop.shopName} ({shop.shopCode})
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </Field>
            )}
            <div className="forgot-password-link">
              <Link tabIndex={5} to="/forgotpassword">Forgot Password</Link>
            </div>
            
            {submitError && !dirtySinceLastSubmit && (
              <span className="input-error-big">{submitError}</span>
            )}
            <button
              tabIndex={4}
              type="submit"
              className="btn-fullwidth btn-default mb-20"
              disabled={
                (invalid && !dirtySinceLastSubmit) || pristine || submitting
              }
            >
              Sign In
            </button>
            <span className="guest-link">
        Unregistered?{" "}
        <a 
          tabIndex={6}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowLogin(false);
          }}
        >
          click here
        </a></span>
          </form>
        )}
      />
    </div>
  );
});
