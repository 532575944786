import React, { useContext, useEffect, useState } from 'react'
import { RootStoreContext } from '../../stores/rootStore';
import { addHours } from '../../utils/Utils';
import { history } from '../..';
import { observer } from 'mobx-react-lite';
import { confirmAlert } from 'react-confirm-alert';
import { ITimeSlot } from '../../models/order';
import agent from '../../api/agent';

interface IProps{
    uid: string;
    orderNo: number;
    collectionDate: Date;
    status: number;
    deliveryVisit: string;
    setOrdersType: () => void;
}
export const MyOrdersFooter : React.FC<IProps> = observer(({uid, orderNo, collectionDate, status, deliveryVisit, setOrdersType}) => {
    const rootStore = useContext(RootStoreContext);
    const { editOrder, orderDetails, currentOrder,  setVisitSlot, setShowChangeSlotDialog, setSelectedVisitUID } = rootStore.orderStore;
    const { user } = rootStore.userStore;
    const [timeSlotField, setTimeSlotField] = useState<ITimeSlot|undefined>();
    const [ currentDate, setCurrentDate] = useState<Date>(new Date());
    useEffect(() => {
        getVisit();
    }, [])
    useEffect(() => {
        if(user)
            setCurrentDate(addHours(new Date(),user.cutOffOrderEdit));
    },[user])
    const getVisit = async () => {
        const res = await agent.Visit.visitById(deliveryVisit);
        setTimeSlotField(res);
    }
    const handleShowChangeSlotDialog = () => {
        setOrdersType();
        setSelectedVisitUID(deliveryVisit);
        setVisitSlot(timeSlotField);
        setShowChangeSlotDialog(true);
    }
    const handleChangeOrder = (uid: string, orderno: number) => {
        if(orderDetails.length > 0){
            confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className='dialog-box'>
                      <div className="dialog-title">
                        <h2>Confirm Cancel Order</h2>
                      </div>
                      <div className="dialog-body">
                          {(currentOrder && currentOrder?.orderNo > 0) ? (
                          <p>You are in the middle of changing an order. Do you want to proceed to change order no {orderno}?</p>
                          ) : (
                          <p>You are in the middle of creating an order. Do you want to proceed to change order no {orderno}?</p>
                          )}
                      </div>
                      <div className="dialog-footer">
                      <button className="btn btn-cancel" onClick={onClose}>No</button>
                      <button className="btn btn-default" onClick={() => {
                            onClose();        
                            editOrder(uid);
                            history.push("/order");
                        }}>Yes</button>
                      </div>
                    </div>
                  );
                }
            });
        } else {
            editOrder(uid);
            history.push("/order");
        }
    }
    
    if(timeSlotField === undefined)
        return <div className="myorders-buttons-container">Loading...</div>
    return (
        <>
        <div className="myorders-buttons-container">
            {(status === 1 && new Date(collectionDate).getTime() > new Date(currentDate).getTime()) &&  (<button className="btn btn-other" onClick={() => handleChangeOrder(uid, orderNo)}>Change Order</button>)}
            {(status === 2 || status === 3) && (<button className="btn btn-other" onClick={handleShowChangeSlotDialog} >Re Schedule Delivery</button>)}
        </div>
        </>
    )
});
