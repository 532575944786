import { observer } from 'mobx-react-lite'
import React from 'react'
import { IFAQSection } from '../../models/document';
import { FAQQuestion } from './FAQQuestion';
interface IProps{
    section: IFAQSection;
}
export const FAQSection : React.FC<IProps> = observer(({section}) => {
    return (
       <div>
            {section.heading && <h2 className="section-heading">{section.heading}</h2>}
            {section.qandAs.map( qandA => (
                <FAQQuestion key={qandA.questionNo} question={qandA.question} answer={qandA.answer} />
            ))}
        </div>
    )
});
