import React, { useEffect, useContext } from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { RootStoreContext } from "../stores/rootStore";
import { LoginBox } from "../components/login/LoginBox";
import { GuestBox } from "../components/login/GuestBox";
import { LoadingComponent } from "../components/Common/LoadingComponent";
import { observer } from "mobx-react-lite";
interface RouteParams {
  id: string;
}
interface IProps extends RouteComponentProps<RouteParams> {}

export const HomePage: React.FC<IProps> = observer(({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    setInvalidShopCode,
    shopUID,
    setCurrentShop,
    currentShop,
    shops,
    loadShops,
    appLoaded,
    setAppLoaded,
    CheckShopCodeValid,
    invalidShopCode,
    validShopCode,
    prevRoute
  } = rootStore.commonStore;
  const { isLoggedIn, showLogin, setShowLogin, user } = rootStore.userStore;

  useEffect(() => {
    if (match.params.id.length > 0) {
      if (!shopUID || shopUID !== match.params.id){
        CheckShopCodeValid(match.params.id);
      } 
      if (shops.length === 0) loadShops();
    } else {
      setInvalidShopCode();
      setAppLoaded();
    }
  }, [match.params.id, shops]);

  useEffect(() => {
    if (shops.length > 0 && !currentShop) {
      setCurrentShop();
      setAppLoaded();
    }
  }, [shops]);
  useEffect(() => {
    if(!validShopCode){
      setInvalidShopCode();
      setAppLoaded();
    }
  },[validShopCode])

  useEffect(() =>{
    if(currentShop)
      setAppLoaded();
  },[currentShop])
  if (!appLoaded) {
    return <LoadingComponent message="Loading" />;
  }
  if(invalidShopCode){
    return <Redirect to="/" />;
  }
  if (isLoggedIn) {
    if(prevRoute)
      return <Redirect to={prevRoute} />;
    else
      if (currentShop?.itemSelectionOptions === 0 || user?.noItemSelect ) 
        return <Redirect to="/basket" />;
      else
        return <Redirect to="/order" />;

  } else
    return (
    <main className="login-wrapper">
      <div className="login-container">
        <div className="logo">
          <img
            src={ currentShop?.logoPath ? `${currentShop?.appDocUrl}/Logo/${currentShop?.logoPath}` : "/images/logo-v.png"}
            alt={currentShop?.shopName || "My Drycleaner"}
          />
          <h1 className="logo-title">
           {currentShop?.shopName || "My Drycleaner"}
          </h1>
        </div>
        {showLogin ? <LoginBox /> : <GuestBox setShowLogin={setShowLogin} />}
      </div>
    </main>
  );
});
