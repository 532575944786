import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { FaTimesCircle } from 'react-icons/fa';
import { ISubCategory } from '../../models/category';
import { IAlterations, IOrderDetail } from '../../models/order';
import { RootStoreContext } from '../../stores/rootStore';
import { AlterationGroupHeader } from './AlterationGroupHeader';
import { AlterationGroupRow } from './AlterationGroupRow';


export const AlterationEditDialog = observer(() => {
     const rootStore = useContext(RootStoreContext);
     const {
    selectedItem,
    showAlterationEditDialog,
    showAlterationsAddEdit,
    hideAlterationsEdit,
    setAlterationUpdated,
    alterationUpdated,
    alterationTypeUpdated,
    setAlterationTypeUpdated
  } = rootStore.categoryStore;
const {
    getAlterationDetailsForItem,
    deleteAlteration
  } = rootStore.orderStore;
  const [alterations, setAlterations] = useState<IOrderDetail[]>([]);
  const [alterationGroups, setAlterationGroups] = useState<IAlterations[]>([])
  
  const groupAlterations = () => {
    let orders: IAlterations[] = [];
          let groups = alterations.filter(a => a.itemType === 'I');
          groups.forEach(ord => {
            let alteration: IAlterations = {
              order: ord,
              alterations: alterations.filter(a => a.mainLineNo === ord.mainLineNo && a.itemType === 'S'),
            };
            orders.push(alteration); 
          });
          setAlterationGroups(orders);
  }
  useEffect(() => {
    if (selectedItem)
      setAlterations(getAlterationDetailsForItem(selectedItem) || []);
  }, [selectedItem]);
  useEffect(() => {
    if(selectedItem && alterationUpdated)
    setAlterations(getAlterationDetailsForItem(selectedItem) || [])
  },[alterationUpdated])
  useEffect(() => {
      if(alterations) 
        groupAlterations();
  },[alterations]);
  useEffect(() => {
    if(selectedItem && alterationTypeUpdated){
      setAlterations(getAlterationDetailsForItem(selectedItem) || [])
      setAlterationTypeUpdated(false);
    }
  },[alterationTypeUpdated])
   const removeOrderDetail = (detail: IOrderDetail) => {
      if (alterations.length > 2) {
        setAlterations([
          ...alterations.filter((a) => a.lineNo !== detail.lineNo),
        ]);
      } else {
        setAlterations([
          ...alterations.filter((a) => a.mainLineNo !== detail.mainLineNo),
        ]);
      }
      deleteAlteration(detail.mainLineNo,detail.lineNo);
      groupAlterations();
    };
    const saveAlterations = () => {
      setAlterationUpdated(true);
    hideAlterationsEdit();
  };
  const addEditAlterations = (item: ISubCategory, mainLineNo: number) => {
    if(item)
    showAlterationsAddEdit(item,mainLineNo);
    hideAlterationsEdit();
  }
    return (
      <div className={`dialog-wrapper ${showAlterationEditDialog ? "dialog-show" : ""}`}>
      <div className="dialog-box">
        <div className="dialog-title">
          <h2 data-alter-title>{`View Alterations - ${selectedItem?.itemName}`}</h2>
          <span className="dialog-close">
            <FaTimesCircle onClick={saveAlterations} />
          </span>
        </div>
        <div className="dialog-body">
          {alterationGroups.map(({order, alterations}) => (
            <div key={order.mainLineNo} className="alteration-group">
              <AlterationGroupHeader order={order} />
              {alterations.map(detail => (
              <AlterationGroupRow key={detail.lineNo} orderDetail={detail} removeOrderDetail={removeOrderDetail} />))}
              <div className="alteration-group-footer"><a onClick={() => addEditAlterations(selectedItem!,order.mainLineNo)}>Add / Edit</a></div>
            </div>
            ))
          }
        </div>
        <div className="dialog-footer">
          <button className="btn btn-default" onClick={saveAlterations}>
            Close
          </button>
        </div>
      </div>
    </div>
    )
});
