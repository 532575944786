import React, { useContext } from "react";
import { IOrderDetail } from "../../models/order";
import { AlterationRow } from "./AlterationRow";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { AlterationGridHeader } from "./AlterationGridHeader";

interface IProps {
  alterations: IOrderDetail[];
  handleRemoveAlteration: (alterations: IOrderDetail) => void;
  editAlterationNote: (alteration: IOrderDetail) => void;
  handleAlterTypeEdit: () => void;
}
export const AlterationGrid: React.FC<IProps> = observer(
  ({ alterations, handleRemoveAlteration, editAlterationNote, handleAlterTypeEdit }) => {
    const rootStore = useContext(RootStoreContext);
    const { selectedItem } = rootStore.categoryStore;

    return (
      <div data-alter-grid className="alteration-grid">
        {alterations.length > 0 && (
          <>
            <div className="alteration-row-header">
              <div className="alteration-col">Item</div>
              <div className="alteration-col">Service</div>
              <div className="alteration-col">Price</div>
            </div>
            <AlterationGridHeader
              itemName={selectedItem!.itemName}
              price={alterations.find(a => a.itemType === 'I')?.price === 0 ? 0 : selectedItem!.scPrice}
              handleAlterTypeEdit={handleAlterTypeEdit}
            />
          </>
        )}
        {alterations.filter(a => a.itemType !== 'I').sort((a,b) => {return (a.lineNo - b.lineNo)}).map((detail) => (
            <AlterationRow
              key={detail.lineNo}
              orderDetail={detail}
              removeOrderDetail={handleRemoveAlteration}
              editOrderDetail={editAlterationNote}
            />
          ))}
      </div>
    );
  }
);
