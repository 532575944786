import React, { useContext } from "react";
import { RootStoreContext } from "../../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { getDateStringEx, getTimeString } from "../../../utils/Utils";
interface IProps {
  handleClick: () => void;
}
export const CollectionTimeSlotBox: React.FC<IProps> = observer(
  ({ handleClick }) => {
    const rootStore = useContext(RootStoreContext);
    const {
      noCollectionSlot,
      defaultCollection,
      loadingCollectionSlots,
    } = rootStore.timeSlotStore;

    if (!defaultCollection && !noCollectionSlot)
      return (
        <div className="delivery-slot-box">
        <h3 className="delivery-slot-heading">Collection</h3>
            <div>
              <span className="input-error-big">Loading slots...</span>
            </div>
            <div>
              <span>&nbsp;</span>
            </div>
        </div>
      );
    return (
      <div
        className="delivery-slot-box"
        onClick={() => handleClick()}
      >
        <h3 className="delivery-slot-heading">Collection</h3>
        {noCollectionSlot ? (
          <>
            <div>
              <span className="input-error-big">No slots available</span>
            </div>
            <div>
              <span>&nbsp;</span>
            </div>
          </>
        ) : (
          <>
            <div>
            <div>
              <span>
                {getDateStringEx(defaultCollection!.slotDate)}
              </span>
            </div>
            <div>
              <span>{`${getTimeString(defaultCollection!.slotTimeFrom)}:00 - ${getTimeString(defaultCollection!.slotTimeTo)}:00`}</span>
            </div>
            </div>
            <div className="delivery-slot-note">{loadingCollectionSlots ? "Loading slots..." : "Click to change date/time"}</div>
          </>
        )}
      </div>
    );
  }
);
