import React, { useContext, useEffect, useState } from "react";
import { Header } from "../components/Layout/Header";
import { Footer } from "../components/Layout/Footer";
import { RootStoreContext } from "../stores/rootStore";
import { Form as FinalForm } from "react-final-form";
import { IContactForm } from "../models/support";
import { ValidationErrors } from "final-form";
import { ContactUsForm } from "../components/ContactUs/ContactUsForm";
import { observer } from "mobx-react-lite";
import { SupportDetails } from "../components/ContactUs/SupportDetails";
import { BackToOrdersFooter } from "../components/Common/BackToOrdersFooter";
export const ContactUsPage = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { shops, loadShops, setCurrentShop, currentShop, setPrevRoute, getSupportDetails, sendContactForm, supportDetails } = rootStore.commonStore;
  const [contactFormValues, setContactFormValues] = useState<IContactForm>({
      appKey: currentShop?.appKey || '',
      shopCode: currentShop?.shopCode || '',
      firstName: '',
      surname: '',
      emailId: '',
      phoneNo: '',
      message: ''
  })
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

useEffect(() =>{
  setPrevRoute(window.location.pathname);
},[]);
useEffect(()=>{
    if(currentShop){
      setContactFormValues({...contactFormValues,appKey: currentShop!.appKey, shopCode: currentShop!.shopCode})
      getSupportDetails();
    } else {
      if(shops.length === 0)
        loadShops();
      else
        setCurrentShop();
    }
  },[currentShop,shops])
const validate = async (values: IContactForm) => {
    const errors: ValidationErrors = [];
    if (!values.firstName) errors.firstName = "First name is required";
    if (!values.surname) errors.surname = "Surname is required";
    if (!values.emailId) errors.emailId = "Email id is required";
    if (!values.phoneNo) errors.phoneNo = "Phone no is required";
    if(!values.message) errors.message = "Message is required";
    return errors;
  };

  return (
      <>
    <Header />
    <FinalForm
      initialValues={contactFormValues}
      validate={validate}
      onSubmit={(values:IContactForm) =>{
            setSubmitted(false);
            setSubmitting(true);
            values.appKey = currentShop?.appKey || '';
            values.shopCode = currentShop?.shopCode || '';
            sendContactForm(values).then((res: boolean) => {
                setIsSuccess(res);
                setSubmitting(false);
                setSubmitted(true);
            })}}
      render={({handleSubmit})=>(
        <form onSubmit={handleSubmit}>
<main>
      <div className="common-wrapper">
        <div className="basket-container">
          <div className="basket-details">
              {!submitting && !isSuccess && (<ContactUsForm setSubmitted={setSubmitted} />)}
              {submitted && isSuccess && (<span className="input-error-big">Thank you for contacting us, we will contact you shortly</span>) }
              {submitted && !isSuccess && (<span className="input-error-big">Currently we are experiencing issues with this service please try later or call {supportDetails?.phoneNo}</span>) }
          </div>
          <div className="basket-details">
              <SupportDetails />
          </div>
        </div>
      </div>
    </main>
    <Footer>
        <BackToOrdersFooter />
      </Footer>
        </form>
      )}
    
    />
    
    </>
  );
});