import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { ITimeSlot } from "../../models/order";
import { ChangeSlotRow } from "./ChangeSlotRow";

interface IProps {
  timeSlot: ITimeSlot;
  setTimeSlot: (timeSlot: ITimeSlot) => void;
}
export const ChangeSlotGrid: React.FC<IProps> = observer(
  ({ timeSlot, setTimeSlot }) => {
    const rootStore = useContext(RootStoreContext);
    const { loadingSlots, slotsForDate  } = rootStore.timeSlotStore;
    if (loadingSlots)
        return <div>Loading slots...</div>;
    else 
        if(slotsForDate.length === 0)
            return <div>No slots available for selected date</div>
    return (
      <div className="timeslot-time">
          {slotsForDate.map((ds:ITimeSlot) => (
              <ChangeSlotRow
                key={ds.uniqueRecId}
                timeSlot={ds}
                localTimeSlot={timeSlot}
                setTimeSlot={setTimeSlot}
              />
            ))}
      </div>
    );
  }
);
