import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { IForgotPassword } from "../../models/user";
import { IApiResponse } from "../../models/apiresponse";
import {history} from "../../";
export const ForgotPasswordBox = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { doForgotPassword } = rootStore.userStore; 
  const { currentShop, shops, loadShops, setCurrentShop} = rootStore.commonStore;
  const [submitting, setSubmitting] = useState(false);
  const [forgotPasswordValues, setForgotPasswordValues] = useState<IForgotPassword>({
    appKey: currentShop?.appKey || "",
    shopUID: currentShop?.uniqueRecId || "",
    emailId: ""
  });

  const [result, setResult] = useState<IApiResponse>({
    status: 0,
    error:{
      code: 0,
      description: ""
    }
  });
  const handleOnClick = () => {
    setSubmitting(true);
    doForgotPassword(forgotPasswordValues).then((response) =>{ 
      setResult(response);
      if(response.status === 1){
        setForgotPasswordValues({...forgotPasswordValues,emailId: ""});
      }
      setSubmitting(false);
    });
  }
  useEffect(()=>{
    if(currentShop){
      setForgotPasswordValues({...forgotPasswordValues,appKey: currentShop!.appKey, shopUID: currentShop!.uniqueRecId})
    } else {
      if(shops.length === 0)
        loadShops();
      else
        setCurrentShop();
    }
  },[currentShop,shops])

  return (
    <div className="dialog-box forgotpassword-box">
      <div className="dialog-title">
          <div className="dialog-heading">Forgot Password</div>
      </div>
      <div className="dialog-body">
            {  (result.status === 0) && (<div className="input-form-group">
              <label className="input-label" htmlFor="emailId">
                Email Id
              </label>
              <input
                name="emailId"
                type="email"
                className="input-form-field"
                onChange={(e: React.FormEvent<HTMLInputElement>)=>setForgotPasswordValues({...forgotPasswordValues,emailId: e.currentTarget.value})}
                placeholder="Your registered email id"
              />
            </div>)}
            {result.status === 1 ? (<span className="input-error-big">An email has been sent to your registered email id with a reset link. Use the link to reset your password.</span>) : (
              <span className="input-error-big">{result.error.description}</span>
            )}
            
            <div className="dialog-footer">
              <button className="btn btn-cancel" onClick={() => history.goBack()}>Cancel</button>
             { submitting && (<span className="input-error">Please wait...</span>)}
             { (!submitting && result.status === 0) && (<button className="btn btn-default" onClick={handleOnClick}>Submit</button>)}
            </div>
        </div>
    </div>
  );
});
