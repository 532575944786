import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { FaTimesCircle } from "react-icons/fa";
import { RootStoreContext } from '../../stores/rootStore';
import { BasketOrderDetails } from './BasketOrderDetails';

interface IProps {
  showDialog: boolean;
  hideDialog: () => void;
  handleCheckOut: () => void;
  handleDiscardChanges: () => void;
}

const BasketDialog: React.FC<IProps> = ({
  showDialog,
  hideDialog,
  handleCheckOut,
  handleDiscardChanges
}) => {
    const rootStore = useContext(RootStoreContext);
    const { totalPieces, totalGrossAmount, totalDiscount } = rootStore.orderStore;
    const { user } = rootStore.userStore;

  return (
    <div id='dialog' className={`dialog-wrapper ${showDialog ? "dialog-show" : ""}`}>
      <div className="dialog-box">
        <div className="dialog-title">
          <h2 data-alter-title>Order</h2>
          <span className="dialog-close">
            <FaTimesCircle onClick={() => hideDialog()} />
          </span>
        </div>
        <div className="dialog-body">
            <BasketOrderDetails handleDiscardChanges={handleDiscardChanges} />
        <div className="basket-totals">
          <h2 className="basket-heading">Totals</h2>
          <div className="basket-grid">
                <div className="basket-discount-row">
          <div>Total Pieces</div>
          <div className="basket-grid-number">{totalPieces}</div>
        </div>

        <div className="basket-discount-row">
          <div>Total Amount</div>
          <div className="basket-grid-number">
            &pound;
            {totalGrossAmount < user!.minOrderValue
              ? (user!.minOrderValue - totalDiscount).toFixed(2)
              : (totalGrossAmount - totalDiscount).toFixed(2)}
          </div>
        </div>

          </div>
        </div>
        </div>
        <div className="dialog-footer">
          <button className='btn-small btn-other' onClick={hideDialog}>Back to Order</button>
          <button className="btn-small btn-default" onClick={handleCheckOut}>Checkout</button>
        </div>
      </div>
    </div>
  )
}

export default observer(BasketDialog);