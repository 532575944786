import React from 'react'
import { FaTimesCircle } from 'react-icons/fa'
interface IProps{
    showDialog: boolean;
    hideDialog: () => void;
    saveData: () => void;
    repair: string;
}
export const AlterationsDuplicateAlertDialog : React.FC<IProps> = ({showDialog, hideDialog, saveData, repair}) => {
    return (
        <div id='dialog' className={`dialog-wrapper ${showDialog ? "dialog-show" : ""}`}>
      <div className="dialog-box">
        <div className="dialog-title">
          <h2 data-alter-title>Duplicate Entry?</h2>
          <span className="dialog-close">
            <FaTimesCircle onClick={() => hideDialog()} />
          </span>
        </div>
        <div className="dialog-body">
                    <p>You have already added {repair} reapir, do you want to add another identical repair</p>

        </div>
        <div className="dialog-footer">
          <button className="btn btn-cancel" onClick={hideDialog}>
            No
          </button>
          <button className="btn btn-default" onClick={saveData}>
            Yes
          </button>
        </div>
      </div>
    </div>
    )
}
