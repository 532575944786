import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { history } from "../../";
import { confirmAlert } from "react-confirm-alert";

export const ProductsFooter = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { totalPieces, totalGrossAmount, orderDetails, currentOrder, setShowBasket } = rootStore.orderStore;
  const { user } = rootStore.userStore;
  const handleCheckout = () => {
    if(orderDetails.length === 0){
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className='dialog-box'>
                      <div className="dialog-title">
                        <h2>Confirm No Item Selection</h2>
                      </div>
                      <div className="dialog-body">
                        <p>There are no items in your basket, Please select the items or select Skip Item Selection</p>
                      </div>
                      <div className="dialog-footer">
                      <button className="btn btn-default" onClick={onClose}>Ok</button>
                      </div>
                    </div>
                  );
                }
              });
          }
          else
              user?.isCustomer ? history.push("/basket") : history.push("/newcustomer");
  }

  return (
    <div className="products-footer">
      <div className="products-totals">
        <div className="products-total-qty">
          <label>Total Pieces: </label>
          <label>{totalPieces}</label>
        </div>
        {currentOrder && currentOrder.orderNo > 0 && (
          <span>{`Changing Order No ${currentOrder.orderNo}`}</span>
        )}
        <div className="products-total-amt">
          <label>Total Amount: </label>
          <label>
            &pound;&nbsp;
            {totalGrossAmount.toFixed(2)}
          </label>
        </div>
      </div>
      <div className="basket-footer-buttons">
        <button
          onClick={() => setShowBasket(true)}
        className="btn-medium btn-cancel"
        >
          View Order
        </button>
        <button
          onClick={handleCheckout}
        className="btn-medium btn-bottom-toolbar"
        >
          Next
        </button>
      </div>
      <div style={{ clear: "both" }}></div>
    </div>
  );
});
