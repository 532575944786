import React from "react";
import { Field } from "react-final-form";
import { observer } from "mobx-react-lite";

export const ContactDetails = observer(() => {
  return (
    <div className="basket-contact-details">
      <h2 className="basket-heading">Address</h2>
      <Field name="address1">
        {({ input, meta }) => (
          <>
          <div className="basket-field-group">
            <label className="basket-field-group-label">Address<span className="required">*</span></label>
            <input
              className="basket-field-group-input"
              type="text"
              {...input}
              placeholder="Address Line 1"
            />
          </div>
                      {meta.touched && (meta.error || meta.submitError) && (
              <span className="input-error basket-field-group-error">
                {meta.error || meta.submitError}
              </span>
            )}

          </>
        )}
      </Field>
      <Field name="address2">
        {({ input, meta }) => (
          <div className="basket-field-group">
            <label className="basket-field-group-label"></label>
            <input
              className="basket-field-group-input"
              type="text"
              {...input}
              placeholder="Address Line 2"
            />
          </div>
        )}
      </Field>
      <Field name="address3">
        {({ input, meta }) => (
          <div className="basket-field-group">
            <label className="basket-field-group-label"></label>
            <input
              className="basket-field-group-input"
              type="text"
              {...input}
              placeholder="Address Line 3"
            />
          </div>
        )}
      </Field>
      <Field name="address4">
        {({ input, meta }) => (
          <div className="basket-field-group">
            <label className="basket-field-group-label"></label>
            <input
              className="basket-field-group-input"
              type="text"
              {...input}
              placeholder="Address Line 4"
            />
          </div>
        )}
      </Field>
      <Field name="postCode">
        {({ input, meta }) => (
          <>
          <div className="basket-field-group">
            <label className="basket-field-group-label">Postcode<span className="required">*</span></label>
            <input
              className="basket-field-group-input input-field-upper"
              type="text"
              placeholder="Postcode"
              readOnly={true}
              {...input}
            />
          </div>
                      {meta.touched && (meta.error || meta.submitError) && (
              <span className="input-error">
                {meta.error || meta.submitError}
              </span>
            )}

          </>
        )}
      </Field>
    </div>
  );
});
