import { observer } from 'mobx-react-lite'
import React from 'react'
import { ISection } from '../../models/document'
interface IProps{
    section: ISection;
}
export const DocumentSection : React.FC<IProps> = observer(({section}) => {
    return (
        <div>
            {section.heading && <h2 className="section-heading">{section.heading}</h2>}
            {section.subHeading && <h2 className="section-heading">{section.subHeading}</h2>}
            {section.paragraphs.map( para => (
                    <p key={para.paragraphNo} >{para.paragraphText}</p>
            ))}
        </div>
    )
});
