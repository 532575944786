import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/rootStore";
import { ITimeSlot } from "../../../models/order";
import { CollectionTimeSlotRow } from "./CollectionTimeSlotRow";

interface IProps {
  timeSlot: ITimeSlot;
  day: Date | undefined;
  setTimeSlot: (timeSlot: ITimeSlot) => void;
}
export const CollectionTimeSlotGrid: React.FC<IProps> = observer(
  ({ timeSlot, day, setTimeSlot }) => {
    const rootStore = useContext(RootStoreContext);
    const {
      getCollectionSlotsForDate,
      collectionSlotsForDate,
      loadingCollectionSlots,
    } = rootStore.timeSlotStore;
    useEffect(() => {
      if(day){
            getCollectionSlotsForDate(day)
      }
    },[day])
    if (collectionSlotsForDate.length === 0) {
      if (loadingCollectionSlots)
        return <div>Loading slots...</div>;
      return <div>No time slots available</div>;
    }
    return (
      <div className="timeslot-time">
          {collectionSlotsForDate.map((ds) => (
              <CollectionTimeSlotRow
                key={ds.uniqueRecId}
                timeSlot={ds}
                localTimeSlot={timeSlot}
                setTimeSlot={setTimeSlot}
              />
            ))}
      </div>
    );
  }
);
