import React, { useContext } from "react";
import { Field } from "react-final-form";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";

export const PersonalDetails = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { signIn } = rootStore.userStore;
  return (
    <div className="basket-personal-details">
      <h2 className="basket-heading">Personal Details</h2>
      <span className="guest-link-block">
        Already have an account?{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            signIn();
          }}
        >
          click here
        </a>{" "}
        to sign in
      </span>
        <Field name="firstName">
          {({ input, meta }) => (
            <>
            <div className="basket-field-group">
              <label className="basket-field-group-label">First Name<span className="required">*</span></label>
              <input
                className="basket-field-group-input"
                type="text"
                placeholder="First Name"
                {...input}
              />
            </div>
                {meta.touched && (meta.error || meta.submitError) && (
                <span className="input-error basket-field-group-error">
                  {meta.error || meta.submitError}
                </span>
              )}

            </>
          )}
        </Field>
        
        <Field name="middleName">
          {({ input, meta }) => (
            <div className="basket-field-group">
            <label className="basket-field-group-label">Middle Name</label>
            <input
              className="basket-field-group-input"
              type="text"
              placeholder="Middle Name"
              {...input}
            />
            </div>
          )}
        </Field>
        <Field name="surname">
          {({ input, meta }) => (
            <>
            <div className="basket-field-group">
              <label className="basket-field-group-label">Surname<span className="required">*</span></label>
              <input
                className="basket-field-group-input"
                type="text"
                placeholder="Surname"
                {...input}
              />
             
            </div>
                          {meta.touched && (meta.error || meta.submitError) && (
                <span className="input-error basket-field-group-error">
                  {meta.error || meta.submitError}
                </span>
 )}
            </>
          )}
        </Field>
      <Field name="emailId">
        {({ input, meta }) => (
          <>
          <div className="basket-field-group">
            <label className="basket-field-group-label">Email Address<span className="required">*</span></label>
            <input
              className="basket-field-group-input"
              type="email"
              placeholder="example@domain.com"
              {...input}
            />
            
          </div>
                      {meta.touched && (meta.error || meta.submitError) && (
              <span className="input-error basket-field-group-error">
                {meta.error || meta.submitError}
              </span>
)}
          </>
        )}
      </Field>
      <Field name="mobileNo">
        {({ input, meta }) => (
          <>
          <div className="basket-field-group">
            <label className="basket-field-group-label">Mobile No<span className="required">*</span></label>
            <input
              className="basket-field-group-input"
              type="tel"
              placeholder="Mobile Number"
              {...input}
            />
          </div>
                      {meta.touched && (meta.error || meta.submitError) && (
              <span className="input-error basket-field-group-error">
                {meta.error || meta.submitError}
              </span>
            )}

          </>
        )}
      </Field>
          <Field name="password">
            {({ input, meta }) => (
              <>
              <div className="basket-field-group">
                <label className="basket-field-group-label">New Password<span className="required">*</span></label>
                <input
                  className="basket-field-group-input"
                  type="password"
                  placeholder="Create Password"
                  {...input}
                />
              </div>
                              {meta.touched && (meta.error || meta.submitError) && (
                  <span className="input-error basket-field-group-error">
                    {meta.error || meta.submitError}
                  </span>
                )}

              </>
            )}
          </Field>
          <Field name="confirmPassword">
            {({ input, meta }) => (
              <>
              <div className="basket-field-group">
                <label className="basket-field-group-label">Confirm Password<span className="required">*</span> </label>
                <input
                  className="basket-field-group-input"
                  type="password"
                  placeholder="Confirm Password"
                  {...input}
                />
              </div>
                              {meta.touched && (meta.error || meta.submitError) && (
                  <span className="input-error basket-field-group-error">
                    {meta.error || meta.submitError}
                  </span>
                )}

              </>
            )}
          </Field>
    </div>
  );
});
