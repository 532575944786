import React, { useState, useContext, useEffect } from "react";
import { ITimeSlot } from "../../models/order";
import { Dialog } from "../Common/Dialog";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { ChangeSlotGrid } from "./ChangeSlotGrid";
import { ChangeSlotHeader } from "./ChangeSlotHeader";
import { formatDate, getDateFromString } from "../../utils/Utils";

interface IProps {
  showDialog: boolean;
  hideDialog: () => void;
  selectedSlot: ITimeSlot;
  setTimeSlot: (value: ITimeSlot) => void;
}
export const ChangeSlotDialog: React.FC<IProps> = observer(
  ({ showDialog, hideDialog, setTimeSlot, selectedSlot }) => {
      const rootStore = useContext(RootStoreContext);
      const { user, customer, customerInfo } = rootStore.userStore;
      const { loadSlotsForDate } = rootStore.timeSlotStore;
      const [selectedDate, setSelectedDate] = useState<Date>(selectedSlot.slotDate);
    const [localTimeSlot, setLocalTimeSlot] = useState<ITimeSlot>(selectedSlot);
    useEffect(() => {
    if (user && user.isCustomer)
      if (!customer) 
        customerInfo();
  }, [user]);
    useEffect(() =>{
        if(selectedDate && customer)
            loadSlotsForDate(formatDate(selectedDate),customer.postCode)
    },[selectedDate,customer]);

    const saveTimeSlot = () => {
      setTimeSlot(localTimeSlot!);
      hideDialog();
    };
    const handleDateChange = (value:string) => {
        setSelectedDate(getDateFromString(value));
    }
    return (
      <Dialog
        title="Change Visit Slot"
        showDialog={showDialog}
        hideDialog={hideDialog}
        saveData={saveTimeSlot}
        saveDisabled={false}
      >
          <ChangeSlotHeader selectedDate={selectedSlot.slotDate} setSelectedDate={handleDateChange} />
        <ChangeSlotGrid 
          timeSlot={localTimeSlot!}
          setTimeSlot={setLocalTimeSlot}
        />
      </Dialog>
    );
  }
);
