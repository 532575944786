import React from "react";
import { IOrderDetail } from "../../models/order";
import { FaMinusSquare } from "react-icons/fa";
import { observer } from "mobx-react-lite";
interface IProps {
  orderDetail: IOrderDetail;
  removeOrderDetail: (orderDetail: IOrderDetail) => void;
}
export const AlterationGroupRow: React.FC<IProps> = observer(
  ({ orderDetail, removeOrderDetail }) => {
    return (
      <>
      <div className="alteration-group-row">
        <div>{orderDetail.subCategory}</div>
        <div className="alteration-price">
          &pound;&nbsp;{orderDetail.price.toFixed(2)}
        </div>
        <div>
          <FaMinusSquare className="delete-icon"
            onClick={(e) => {
              e.preventDefault();
              removeOrderDetail(orderDetail);
            }}
          />
        </div>
      </div>
      {orderDetail.notes && (
      <div className="alteration-group-row-notes">
        Notes: {orderDetail.notes}
      </div>
      )}
      </>
    );
  }
);
