import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { BackToOrdersFooter } from "../components/Common/BackToOrdersFooter";
import { Footer } from "../components/Layout/Footer";
import { Header } from "../components/Layout/Header";
import { RegularVisits } from "../components/Subscriptions/RegularVisits";
import { ScheduledVisits } from "../components/Subscriptions/ScheduledVisits";
import { RootStoreContext } from "../stores/rootStore";

export const MySubscriptionsPage = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const {setPrevRoute} = rootStore.commonStore;

  useEffect(() =>{
  setPrevRoute(window.location.pathname);
},[])
  return (
    <>
      <Header />
      <main>
      <div className="common-wrapper">
        <div className="offers-container">
            <div className="offers-wrapper">
                <div className="offers-section">
                    <RegularVisits />
                </div>
                <div className="offers-section">
                    <ScheduledVisits />
                </div>
            </div>
         </div>
      </div>
      </main>
    <Footer>
        <BackToOrdersFooter />
      </Footer>
    </>
  );
});
