import React from "react";
import { observer } from "mobx-react-lite";
import { FaEdit } from "react-icons/fa";

interface IProps {
  itemName: string;
  price: number;
  handleAlterTypeEdit: () => void;
}
export const AlterationGridHeader: React.FC<IProps> = observer(
  ({ itemName, price, handleAlterTypeEdit }) => {
    return (
      <div className="alteration-row">
        <div className="alteration-col">{itemName}</div>
        <div className="alteration-col">{price === 0 ? 'No Clean' : 'Clean Charges'}</div>
        <div className="alteration-col">&pound;&nbsp;{price.toFixed(2)}</div>
        <div className="alteration-col">&nbsp;</div>
        <div><FaEdit data-tip="Click here to view and make changes" onClick={() => handleAlterTypeEdit()} /></div>
      </div>
    );
  }
);
