import React, { useContext } from "react";
import { RootStoreContext } from "../../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { DeliveryTimeSlotHeaderButton } from "./DeliveryTimeSlotHeaderButton";

interface IProps {
  selectedDay: Date | undefined;
  setSelectedDay: (day: Date) => void;
}
export const DeliveryTimeSlotHeader: React.FC<IProps> = observer(
  ({  selectedDay, setSelectedDay }) => {
    const rootStore = useContext(RootStoreContext);
    const { weeklyDeliverySlots } = rootStore.timeSlotStore;
    return (
      <div className="timeslot-date-row">
        {weeklyDeliverySlots &&
          weeklyDeliverySlots.map((t, index) => (
            <DeliveryTimeSlotHeaderButton
              key={index}
              day={t.timeSlotDate}
              dayName={t.timeSlotDay}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
            />
          ))}
      </div>
    );
  }
);
