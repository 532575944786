import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { ProductItem } from "./ProductItem";
import { AlterationDialog } from "../Alterations/AlterationDialog";
import { AlterationEditDialog } from "../Alterations/AlterationEditDialog";
import { LoadingComponent } from "../Common/LoadingComponent";
import AlterationsTypeDialog from "../Alterations/AlterationsTypeDialog";

export const ProductList: React.FC = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const {
    selectedMainCategory,
    subCategories,
    loadSubCategories,
    loadingSubCategories,
    showAlterationDialog,
    showAlterationEditDialog,
    showAlterationsTypeDialog
  } = rootStore.categoryStore;

  useEffect(() => {
    if (selectedMainCategory !== null)
      loadSubCategories(selectedMainCategory?.groupName);
  }, [selectedMainCategory, loadSubCategories]);
  if (loadingSubCategories)
    return <div className="products-container">
      <LoadingComponent message="Loading items..." />
    </div>;

    return (
    <div className="products-wrapper">
      <div className="products-container">
        {subCategories.map((item) => (
          <ProductItem key={item.uniqueRecID} item={item} />
        ))}
      </div>
      {showAlterationDialog && <AlterationDialog />}
      {showAlterationEditDialog && <AlterationEditDialog />}
      {showAlterationsTypeDialog && <AlterationsTypeDialog />}
    </div>
  );
});
