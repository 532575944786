import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { FaEdit } from 'react-icons/fa';
import { IOrderDetail } from '../../models/order'
import { RootStoreContext } from '../../stores/rootStore';
interface IProps{
    order: IOrderDetail;
}
export const AlterationGroupHeader : React.FC<IProps> = observer( ({order}) => {
    const rootStore = useContext(RootStoreContext);
    const { setShowAlterationTypeDialog, setAlterationsType } = rootStore.categoryStore;
    const handleAlterTypeEdit = () => {
      setAlterationsType(order.alterationCode === 5 ? 2 : 1)
      setShowAlterationTypeDialog(order.mainLineNo)
    }
    return (
      <>
        <div className="alteration-group-header">
            <div>{order.subCategory}</div>
        </div>
        <div className="alteration-group-row">
        <div>{order.price === 0 ? "No Clean" : "Clean"}</div>
        <div className="alteration-price">
          &pound;&nbsp;{order.price.toFixed(2)}
        </div>
        <div>
          <FaEdit data-tip="Click here to view and make changes" onClick={() => handleAlterTypeEdit()} />
        </div>
      </div>
    </>
    );
});
