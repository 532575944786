import React, { useEffect, useContext, useState } from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { RootStoreContext } from "../stores/rootStore";
import { observer } from "mobx-react-lite";
import { ResetPasswordBox } from "../components/login/ResetPasswordBox";
import { decryptResetKey } from "../utils/Utils";
import { Loader } from "../components/Common/Loader";

export const ResetPasswordPage: React.FC<RouteComponentProps> = observer(({ location }) => {
  const rootStore = useContext(RootStoreContext);
  const { currentShop, shopUID, CheckShopCodeValid, shops, loadShops, setInvalidShopCode, setCurrentShop, validShopCode, invalidShopCode } = rootStore.commonStore;
  const [loading, setLoading] = useState(true);
  const [resetKey,setResetKey] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    var searchParams = new URLSearchParams(location.search);
    if(searchParams.has("key"))
      setResetKey(searchParams.get("key") || "");
    if(searchParams.has("message"))
      setMessage(searchParams.get("message") || "");
  }, [location.search]);
  useEffect(() =>{
    if (resetKey.length > 0) {
        var _shopuid = decryptResetKey(decodeURIComponent(resetKey));
        if(_shopuid){
          if (!shopUID || shopUID !== _shopuid)
            CheckShopCodeValid(_shopuid);
       } else {
            setInvalidShopCode();
            setLoading(false);    
       }
      if (shops.length === 0) loadShops();
    } else {
      setInvalidShopCode();
      setLoading(false);
    }
  },[resetKey])
    
  useEffect(() => {
    if (shops.length > 0 && !currentShop)
      setCurrentShop();
  }, [shops]);

    useEffect(() => {
    if(!validShopCode){
      setInvalidShopCode();
      setLoading(false);
    }
  },[validShopCode])

    useEffect(() => {
    if (currentShop){
      setLoading(false);
      let colourScheme = JSON.parse(currentShop.colorScheme);
        Object.keys(colourScheme).forEach((key) =>{
        if (key.endsWith('image'))
          document.documentElement.style.setProperty(`--${key}`, `url(${colourScheme[key]})`)  
        else
          document.documentElement.style.setProperty(`--${key}`, colourScheme[key])  
        }
        );
    }
  }, [currentShop]);
  
  if(loading)
    return <Loader message="Loading..." />
  if(invalidShopCode && !validShopCode){
    return <Redirect to="/" />;
  }

  return (
    <main className="login-wrapper">
      <div className="login-container">
        <div className="logo">
          <img
            src={ currentShop?.logoPath ? `${currentShop?.appDocUrl}/Logo/${currentShop?.logoPath}` : "/images/logo-v.png"}
            alt={currentShop?.shopName || "My Drycleaner"}
          />
          <h1 className="logo-title">
           {currentShop?.shopName || "My Drycleaner"}
          </h1>
        </div>
        <ResetPasswordBox resetKey={resetKey} message={message} />
      </div>
    </main>
  );
});
