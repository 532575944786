import React, { useState } from 'react'
import { FaPen } from 'react-icons/fa';
import { IOrderDetail } from '../../models/order';
import { Dialog } from '../Common/Dialog';
interface IProp {
    alteration: IOrderDetail;
    showDialog: boolean;
    hideDialog: () => void;
    updateAlterationNote: (alteration: IOrderDetail) => void;
}
export const AlterationNoteEditDialog : React.FC<IProp> = ({alteration, showDialog, hideDialog, updateAlterationNote}) => {
    const [note, setNote] = useState(alteration.notes)
    const saveData = () => {
        updateAlterationNote({...alteration, notes:note});
        hideDialog();
    }
      return (
    <Dialog
      title={`Edit note for ${alteration.subCategory}`}
      showDialog={showDialog}
      hideDialog={hideDialog}
      saveData={saveData}
      saveDisabled={false}
    >
        <div>
            <div className="input-group">
                  <div className="input-icon">
                    <FaPen />
                  </div>
                  <input
                    className="input-field"
                    value={note}
                    onChange={(e) =>
                      setNote(e.target.value)
                    }
                  />
                </div>
        </div>
    </Dialog>
  );
}
