import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { ICustomerCard } from "../../../models/cards";
import { RootStoreContext } from "../../../stores/rootStore";
interface IProps {
  customerCard: ICustomerCard;
}
export const CustomerCard: React.FC<IProps> = observer(
  ({ customerCard }) => {
      const rootStore = useContext(RootStoreContext);
      const { setDefaultCard } = rootStore.userStore;
    return (
      <div className="card-grid-row">
        <div>
            <label className="input-form-radio">
              <input name="cardDefault" type="radio" value={customerCard.uniqueRecId} onClick={()=>setDefaultCard(customerCard)} defaultChecked={customerCard.isDefault} />
              <span className="checkmark"></span>
            </label>
        </div>
        <div>
            <span>{customerCard.cardType}</span>
        </div>
        <div><span>{customerCard.cardNumber}</span></div>
        <div>
            <span>{customerCard.cardExpiry}</span>
        </div>
      </div>
    );
  }
);
