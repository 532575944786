import React from 'react'
import { IBundleOrderEntity } from '../../models/order'
import { displayDateTime } from '../../utils/Utils'
import { MyOrdersGrid } from './MyOrdersGrid'
import { MyOrdersFooter } from './MyOrdersFooter';
import { observer } from 'mobx-react-lite';
interface IProp{
    dateFieldText: string;
    orders: IBundleOrderEntity[];
    setOrdersType: () => void;
}
export const BundleOrdersList : React.FC<IProp> = observer(({ dateFieldText, orders, setOrdersType}) => {
    return (
        <div>
        {orders.map(order => (
            <div key={order.orderNo}>
                <div className="myorders-order-sub-header">
                    <div>Order No: {order.orderNo}</div>
                    <div>{dateFieldText}: {displayDateTime(order.deliveryDate)}</div>
                </div>
                <MyOrdersGrid orderDetails={order.orderDetails} />
                <MyOrdersFooter uid={order.uniqueRecId} orderNo={order.orderNo} collectionDate={order.deliveryDate} deliveryVisit={order.deliveryVisit} status={order.status} setOrdersType={setOrdersType} />
            </div>
        ))}
        </div>
    )
});
