import React, { useContext, useState, useEffect } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { Dialog } from "../Common/Dialog";
import { AlterationForm } from "./AlterationForm";
import { AlterationGrid } from "./AlterationGrid";
import { AlterationFooter } from "./AlterationFooter";
import { IOrderDetail } from "../../models/order";
import { observer } from "mobx-react-lite";
import { AlterationNoteEditDialog } from "./AlterationNoteEditDialog";
import { AlterationsTypeEditDialog } from "./AlterationsTypeEditDialog";
import { confirmAlert } from "react-confirm-alert";

export const AlterationDialog = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const {
    selectedItem,
    showAlterationDialog,
    showAlterationsEdit,
    hideAlterations,
    setAlterationUpdated,
    selectedMainLineNo,
    setAlterationsType,
    alterationsType,
    alterationDialogEditMode
  } = rootStore.categoryStore;
  const {
    addAlterations,
    updateAlterations,
    incrementMainLineNo,
    getAlterationDetails,
    removeAlteration, 
    removeAllAlterations
  } = rootStore.orderStore;
  const {user} = rootStore.userStore;
  const [alterations, setAlterations] = useState<IOrderDetail[]>([]);
  const [removedAlterations, setRemovedAlterations] = useState<IOrderDetail[]>([]);
  const [showEditAlterationNote, setShowEditAlterationNote ] = useState(false);
  const [showAlterationType, setShowAlterationType] = useState(false);
  const [selectedAlteration, setSelectedAlteration] = useState<IOrderDetail | undefined>();
  useEffect(() => {
    if (selectedItem)
      if(alterationDialogEditMode){
        setAlterations(getAlterationDetails(selectedMainLineNo));
      }
  }, [
    selectedItem,
    alterationDialogEditMode,
    setAlterations,
    getAlterationDetails
  ]);

  const saveAlterations = () => {
    if(alterationDialogEditMode && removedAlterations.length > 0){
      if(alterations.length === 0){
        removeAllAlterations(removedAlterations[0].mainLineNo);
      } else {
        removedAlterations.forEach((alteration) => {
            removeAlteration(alteration.mainLineNo,alteration.lineNo);
        })
      }
    } else {
      if (selectedMainLineNo === 0) {
        addAlterations(alterations);
        incrementMainLineNo();
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='dialog-box topmost'>
                <div className="dialog-title">
                    <h2>Important Note</h2>
                </div>
                <div className="dialog-body">
                  <p>{user?.alterationsMessage}</p>
                </div>
                <div className="dialog-footer">
                <button className="btn btn-default" onClick={onClose}>Ok</button>
                </div>
              </div>
            );
          }
        });
      } else {
        updateAlterations(alterations);
        showAlterationsEdit(selectedItem!);
      }
    }
    setAlterationUpdated(true);
    setAlterationsType(1);
    hideAlterations();
  };

  const cancelAlterations = () => {
    if (selectedMainLineNo > 0){
      showAlterationsEdit(selectedItem!);
    }
    setAlterationUpdated(false);
    setAlterationsType(1);
    hideAlterations();
  };
  const editAlterationNote = (alteration: IOrderDetail) => {
    setSelectedAlteration(alteration);
    setShowEditAlterationNote(true);
  }
  const hideEditNoteDialog = () => {
    setShowEditAlterationNote(false);
  }
  const updateAlterationNotes = (alteration: IOrderDetail) => {
      setAlterations([
          ...alterations.filter((a) => a.lineNo !== alteration.lineNo),alteration
        ]);
  };
  const handleRemoveAlteration = (detail: IOrderDetail) => {
      if (alterations.length > 2) {
        setAlterations([
          ...alterations.filter((a) => a.lineNo !== detail.lineNo),
        ]);
      } else {
        setAlterations([
          ...alterations.filter((a) => a.mainLineNo !== detail.mainLineNo),
        ]);
      }
      if(alterationDialogEditMode){
          setRemovedAlterations([...removedAlterations,detail])
        }

    };
  const handleSaveAlterationType = (alterType: number) => {
    if(alterations.length > 0){
      let mainDetails = alterations.find(a => a.alteration === 'A' && a.itemType === 'I');
      if(mainDetails){
        let alterationDetail = {
            mainLineNo: mainDetails.mainLineNo,
            lineNo: mainDetails.lineNo,
            mainCategory: mainDetails.mainCategory,
            subCategory: mainDetails.subCategory,
            scUnique: mainDetails.scUnique,
            stkCode: mainDetails.stkCode,
            discountQty: 0,
            quantity: 1,
            pieces: selectedItem!.pieces,
            price: alterType === 1 ? selectedItem!.scPrice : 0,
            lineTotal: alterType === 1 ? selectedItem!.scPrice : 0,
            alterationCode: alterType === 1 ? 0 : 5,
            alteration: "A",
            itemType: "I",
            collectionCharges: 0,
            deliveryCharges: 0,
            discountType: alterType === 1 ?
              (selectedItem!.discount > 0
                ? `PR::0:${selectedItem?.pmPrice}`
                : "") : "",
            discount: alterType === 1 ? selectedItem!.discount : 0,
            headerDiscount: 0,
            notes: "",
        };
        setAlterations([...alterations.filter((a) => a.mainLineNo !== alterationDetail.mainLineNo || (a.mainLineNo === alterationDetail.mainLineNo && a.lineNo !== alterationDetail.lineNo)),alterationDetail]);
      }
    }
    setShowAlterationType(false);
  }
  return (
    <Dialog
      title={`${alterationsType === 1 ? "Alter & Clean" : 'Alter Only'} - ${selectedItem?.itemName}`}
      showDialog={showAlterationDialog}
      hideDialog={cancelAlterations}
      saveData={saveAlterations}
      saveDisabled={alterations.length === 0 && removedAlterations.length === 0}
    >
        <AlterationForm
          alterations={alterations}
          setAlterations={setAlterations}
        />
      <AlterationGrid
        alterations={alterations}
        handleRemoveAlteration={handleRemoveAlteration}
        editAlterationNote={editAlterationNote}
        handleAlterTypeEdit={() => setShowAlterationType(true)}
      />
      <AlterationFooter alterations={alterations} />
      {showEditAlterationNote && <AlterationNoteEditDialog alteration={selectedAlteration!} showDialog={showEditAlterationNote} hideDialog={hideEditNoteDialog} updateAlterationNote={updateAlterationNotes}  />}
      {showAlterationType && <AlterationsTypeEditDialog defaultType={alterationsType} showDialog={showAlterationType} saveDialog={handleSaveAlterationType} hideDialog={()=>setShowAlterationType(false)} />}
    </Dialog>
  );
});
