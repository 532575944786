import React from "react";
import { observer } from "mobx-react-lite";
import { ITimeSlot } from "../../../models/order";
import { getTimeString } from "../../../utils/Utils";
interface IProps {
  timeSlot: ITimeSlot;
  localTimeSlot: ITimeSlot | undefined;
  setTimeSlot: (timeSlot: ITimeSlot) => void;
}
export const DeliveryTimeSlotRow: React.FC<IProps> = observer(
  ({ timeSlot, localTimeSlot, setTimeSlot }) => {
    return (
      <div
        onClick={() => setTimeSlot(timeSlot)}
        className={
          localTimeSlot?.uniqueRecId === timeSlot.uniqueRecId
            ? "timeslot-time-row timeslot-time-selected"
            : "timeslot-time-row"
        }
      >
        <div>{`${getTimeString(timeSlot.slotTimeFrom)}:00 to ${getTimeString(
          timeSlot.slotTimeTo
        )}:00`}</div>
        <div>FREE</div>
      </div>
    );
  }
);
