import React from "react";
import { IOrderDetail } from "../../models/order";
import { FaEdit, FaMinusSquare } from "react-icons/fa";
import { observer } from "mobx-react-lite";
interface IProps {
  orderDetail: IOrderDetail;
  removeOrderDetail: (orderDetail: IOrderDetail) => void;
  editOrderDetail: (orderDetail: IOrderDetail) => void;
}
export const AlterationRow: React.FC<IProps> = observer(
  ({ orderDetail, removeOrderDetail, editOrderDetail }) => {
    return (
      <>
      <div className="alteration-row">
        <div className="alteration-col">{orderDetail.mainCategory}</div>
        <div className="alteration-col">{orderDetail.subCategory}</div>
        <div className="alteration-col">
          &pound;&nbsp;{orderDetail.price.toFixed(2)}
        </div>
        <div className="alteration-col">
          <FaMinusSquare className="delete-icon"
            onClick={(e) => {
              e.preventDefault();
              removeOrderDetail(orderDetail);
            }}
          />
        </div>
        <div className="alteration-col">
          <FaEdit 
            onClick={(e) => {
              e.preventDefault();
              editOrderDetail(orderDetail);
            }}
            />
        </div>
      </div>
      {orderDetail.notes && (
      <div className="alteration-row-notes">
        Notes: {orderDetail.notes}
      </div>
      )}
      </>
    );
  }
);
