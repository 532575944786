import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { RootStoreContext } from '../../stores/rootStore'
import { getTimeString } from '../../utils/Utils';

export const RegularVisits = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const { regularVisits, loadingRegularVisits, loadRegularVisits } = rootStore.orderStore;

    useEffect(() =>{
        loadRegularVisits()
    },[])
    return (
        <>
        <div className="basket-personal-details">
          <h2 className="basket-heading">Regular Visits</h2>
        </div>
          { loadingRegularVisits ? (<span className="input-error">Loading Visits...</span>) : (
            <div className="offers-grid">
            <div className="regvisit-grid-heading">
                <div>Date</div>
                <div>Time</div>
            </div>
            { regularVisits?.map((visit,i) => (
                <div key={i} className="regvisit-grid-row">
                    <div>{visit.day}</div>
                    <div>{getTimeString(visit.time)}:00</div>
                </div>
            ))}
            { regularVisits.length === 0 && (
                <span className="input-error-big">Currently there are no regular visits</span>
            )}
        </div>
          )}
        </>
    )
});
