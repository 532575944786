import { useStripe } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { LoadingComponent } from '../components/Common/LoadingComponent';
import { RootStoreContext } from '../stores/rootStore';

export const CardStatusPage: React.FC<RouteComponentProps> = observer(({ location }) => {
  const [intentId, setIntentId] = useState<string|null>();
  const [clientSecret, setClientSecret] = useState<string|null>();
 const rootStore = useContext(RootStoreContext);
 const { user, isLoggedIn, saveNewStripeCard, setCardSaveError } = rootStore.userStore;
 const { prevRoute, currentShop } = rootStore.commonStore;
  const stripe_api = useStripe();

  useEffect(()=>{
    var searchParams = new URLSearchParams(location.search)
    if(searchParams.has('setup_intent'))
      setIntentId(searchParams.get('setup_intent'))
    if(searchParams.has('setup_intent_client_secret'))
      setClientSecret(searchParams.get('setup_intent_client_secret'))
  },[location.search])

    useEffect(()=>{
    if(clientSecret && stripe_api){
      stripe_api.retrieveSetupIntent(clientSecret)
        .then((response) => {
          if(response.error){
                setCardSaveError(response.error.message!);
                redirectToPrevious();
          } else {
            switch(response.setupIntent.status){
              case "succeeded":
                 if(response.setupIntent.payment_method){
                    saveNewStripeCard(response.setupIntent.payment_method)
                    .then(() => redirectToPrevious());
                 }
                break;
                case 'processing':
                    setCardSaveError("Processing..")
                    redirectToPrevious();
                    break;
                case "requires_payment_method":
                    setCardSaveError('Failed to process payment details. Please try another payment method');
                    redirectToPrevious();
                    break;
                default:
                    setCardSaveError("An error occured while saving your card!");
                    redirectToPrevious();
            }
          }
        })
    }

  },[clientSecret, stripe_api])

  
  const redirectToPrevious = () => {
    if (isLoggedIn) {
        if(prevRoute)
        return <Redirect to={prevRoute} />;
        else
        if (currentShop?.itemSelectionOptions === 0 || user?.noItemSelect ) 
            return <Redirect to="/basket" />;
        else
            return <Redirect to="/order" />;
    } else 
        return <Redirect to="/" />;  
  }
  return <LoadingComponent message='Processing card..' />
});

