import React, { useContext, useEffect, useState } from 'react';
import { IWorldpayToken } from '../../models/worldpay_models';
import { RootStoreContext } from '../../stores/rootStore';
import { customerAddressWithoutPostcode, toTitlecase } from '../../utils/Utils';

import { Dialog } from '../Common/Dialog';
export const WorldPayDialog = () => {
    const rootStore = useContext(RootStoreContext);
    const { customer, showCardDialog, hideNewCardDialog, saveNewCard, savingCard, cardSaveError, setCardSaveError } = rootStore.userStore;
    const { currentShop } = rootStore.commonStore;
    const [showError, setShowError] = useState(false);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    const handleSave = (response: IWorldpayToken) => {
        if(response && response.token){
            const {paymentMethod} = response;
            const expDate: string = (paymentMethod.expiryMonth && paymentMethod.expiryYear) ? (100 + paymentMethod.expiryMonth).toString().substring(1) + paymentMethod.expiryYear.toString().substring(2) : '';
            const stDate: string = (paymentMethod.startMonth && paymentMethod.startYear) ? (100 + paymentMethod.startMonth).toString().substring(1) + paymentMethod.startYear.toString().substring(2) : '';
            saveNewCard({
                accountCode: customer!.accountCode,
                address: customerAddressWithoutPostcode(customer!),
                postcode: customer!.postCode,
                cardNumber: paymentMethod.maskedCardNumber,
                cardType: toTitlecase(paymentMethod.cardType),
                expiryDate: expDate,
                issueNo: paymentMethod.issueNumber?.toString() || '',
                referenceCode: response.token,
                startDate: stDate,
                payProvider: currentShop!.cardProvider
            });
            if(cardSaveError.length === 0)
                hideNewCardDialog();
        }
        else{
            setCardSaveError("An error occured while saving card");
            setShowError(true);
        }
    }
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.worldpay.com/v1/worldpay.js';
        script.async = false;
        script.onload = () => {
            setScriptLoaded(true);
        // @ts-ignore
            Worldpay.useTemplateForm({
            'clientKey': (currentShop ? currentShop.cardKey : process.env.REACT_APP_WORLDPAY_KEY),
            'form':'paymentForm',
            'paymentSection':'paymentSection',
            'display':'inline',
            'saveButton':false,
            'templateOptions': {images:{enabled:false}},
            'reusable':true,
            // @ts-ignore
            'callback': handleSave,
            });
        };
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, [])
    useEffect(() => {
        if(!savingCard)
            if(cardSaveError.length > 0)
                setShowError(true);
            else
                setShowError(false);
    },[savingCard])
    if(!scriptLoaded)
        return <div>Connecting with payment gateway...</div>
    return (
        //@ts-ignore
        <Dialog title="Add new Credit/Debit card" showDialog={showCardDialog} hideDialog={hideNewCardDialog} saveData={Worldpay.submitTemplateForm}>
            <form id="paymentForm" method="post">
                <div id='paymentSection' style={{width:"auto"}} ></div>
            </form>
            {!savingCard && showError && (<span className="input-error-big">{cardSaveError}</span>)}
        </Dialog>
    );
    
}

