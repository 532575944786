import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { ToolbarButton } from "./ToolbarButton";

export const Toolbar: React.FC = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const {
    mainCategories,
    selectMainCategory,
    selectedMainCategory,
  } = rootStore.categoryStore;
  useEffect(() => {
    if (selectedMainCategory == null)
      if (mainCategories.length > 0)
        selectMainCategory(mainCategories[1]?.groupName);
  }, [mainCategories, selectedMainCategory, selectMainCategory]);

  return (
    <div className="toolbar">
      {mainCategories &&
        mainCategories.map((category) => (
          <ToolbarButton key={category.uniqueRecID} category={category} />
        ))}
    </div>
  );
});
