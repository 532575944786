import { observer } from 'mobx-react-lite'
import React from 'react'
import { IOrderDetailEntity } from '../../models/order';

interface IProps{
    orderDetails: IOrderDetailEntity[]
}
export const MyOrdersGrid : React.FC<IProps> = observer(({orderDetails}) => {
    return (
        <div className="myorders-order-grid">
            <div className="myorders-order-grid-header">
                <div>Qty</div>
                <div>Item</div>
                <div>Price</div>
            </div>
            {orderDetails.map((detail,i) => (
                <div key={i} className="myorders-order-grid-row">
                    <div className="numeric-field">{detail.quantity}</div>
                    <div>{detail.item}</div>
                    <div className="numeric-field">{detail.price.toFixed(2)}</div>
                </div>
            ))}
        </div>
    )
});
