import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { BackToOrdersFooter } from "../components/Common/BackToOrdersFooter";
import { Footer } from "../components/Layout/Footer";
import { Header } from "../components/Layout/Header";
import { CurrentOffers } from "../components/Offers/CurrentOffers";
import { SpecialPrices } from "../components/Offers/SpecialPrices";
import { RootStoreContext } from "../stores/rootStore";

export const MyOffersPage = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const {setPrevRoute} = rootStore.commonStore;
  const {customer, customerInfo} = rootStore.userStore;

  useEffect(() =>{
  setPrevRoute(window.location.pathname);
},[])
  useEffect(() =>{
    if(!customer)
      customerInfo();
  },[customer])

  return (
    <>
      <Header />
      <main>
      <div className="common-wrapper">
         <div className="offers-container">
           {(customer) && (customer?.membershipType.length > 0) && (
             <div className="offers-membership-heading">Membership Type : {customer!.membershipType}</div>
           )}
           <div className="offers-wrapper">
          <div className="offers-section">
            <CurrentOffers />
          </div>
          <div className="offers-section">
            <SpecialPrices />
          </div>
           </div>
         </div>
      </div>
      </main>
            <Footer>
        <BackToOrdersFooter />
      </Footer>
    </>
  );
});
