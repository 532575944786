import React, { Fragment, useContext, useEffect, useState } from "react";
import "./css/App.css";
import {
  RouteComponentProps,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { NotFoundPage } from "./pages/NotFoundPage";
import { HomePage } from "./pages/HomePage";
import { PrivateRoute } from "./components/Common/PrivateRoute";
import { OrderBookingPage } from "./pages/OrderBookingPage";
import { BasketPage } from "./pages/BasketPage";
import { MyOrdersPage } from "./pages/MyOrdersPage";
import { ProfilePage } from "./pages/ProfilePage";
import { MyOffersPage } from "./pages/MyOffersPage";
import { RootStoreContext } from "./stores/rootStore";
import { InfoPage } from "./pages/InfoPage";
import { NewCustomerPage } from "./pages/NewCustomerPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { observer } from "mobx-react-lite";
import { ContactUsPage } from "./pages/ContactUsPage";
import { MySubscriptionsPage } from "./pages/MySubscriptions";
import { DocumentPage } from "./pages/DocumentPage";
import { FAQsPage } from "./pages/FAQsPage";
import { useIsLoaded } from "./hooks/useIsLoaded";
import { MessageBox } from "./components/Common/MessageBox";
import {CardStatusPage} from "./pages/CardStatusPage";

const App: React.FC<RouteComponentProps> = ({ location, match }) => {
  const rootStore = useContext(RootStoreContext);
  const { token, shopUID, currentShop, loadShops, setTermsURL, setPrivacyURL, setFAQsURL, setPrefilledPostCode } = rootStore.commonStore;
  const { getUser, setShowLogin } = rootStore.userStore;
  const isLoaded = useIsLoaded();
  const [loginValue, setLoginValue] = useState<string | undefined>(undefined);
  const [postCode, setPostCode] = useState<string | undefined>(undefined);
  useEffect(() => {
    var searchParams = new URLSearchParams(location.search);
    if(searchParams.has("login"))
      setLoginValue(searchParams.get("login") ?? undefined);
    if(searchParams.has("pc"))
      setPostCode(searchParams.get("pc") ?? undefined);
  },[location.search])
  useEffect(() => {
    if(loginValue === "1")
      setShowLogin(true);
  })
  useEffect(() => {
    if(postCode)
      setPrefilledPostCode(postCode);             
  },[postCode])
  useEffect(() => {
    if (token) getUser();
  }, [token]);

  useEffect(() => {
    if (shopUID && !currentShop){ 
      loadShops();
    }
  }, [shopUID]);

  useEffect(() => {
    if (currentShop)
    {
      if(currentShop!.termsUrl.startsWith("http"))
        setTermsURL(currentShop!.termsUrl)
      else
        setTermsURL(`${currentShop!.appDocUrl}/Files/${currentShop?.termsUrl}`);
      if(currentShop!.privacyUrl.startsWith("http"))
        setPrivacyURL(currentShop!.privacyUrl);
      else
        setPrivacyURL(`${currentShop!.appDocUrl}/Files/${currentShop?.privacyUrl}`);
      if(currentShop!.faqUrl.startsWith("http"))
        setFAQsURL(currentShop!.faqUrl);
      else
        setFAQsURL(`${currentShop!.appDocUrl}/Files/${currentShop?.faqUrl}`);
      
        let colourScheme = JSON.parse(currentShop.colorScheme);
        Object.keys(colourScheme).forEach((key) =>{
        if (key.endsWith('image'))
          document.documentElement.style.setProperty(`--${key}`, `url(${currentShop!.appDocUrl}/Files/${colourScheme[key]})`)  
        else
          document.documentElement.style.setProperty(`--${key}`, colourScheme[key])  
        }
        );

    }
  }, [currentShop]);

  if(isLoaded){
    return (
      <MessageBox title="Multiple Instances" message="You have an existing web app already opened, please close the app and try again." />
    )
  }

  return (
    <Fragment>
      <ToastContainer position="top-right" />
      <Route exact path={"/"} component={NotFoundPage} />
      <Route
        path={"/(.+)"}
        render={() => (
          <Fragment>
            <Switch>
              <PrivateRoute exact path={"/info"} component={InfoPage} />
              <PrivateRoute exact path={"/order"} component={OrderBookingPage} />
              <PrivateRoute exact path={"/basket"} component={BasketPage} />
              <PrivateRoute exact path={"/newcustomer"} component={NewCustomerPage} />
              <PrivateRoute exact path={"/myorders"} component={MyOrdersPage} />
              <PrivateRoute exact path={"/profile"} component={ProfilePage} />
              <PrivateRoute exact path={"/mysubscriptions"} component={MySubscriptionsPage} />
              <PrivateRoute exact path={"/myoffers"} component={MyOffersPage} />
              <PrivateRoute exact path={"/cardstatus"} component={CardStatusPage} />
              <Route exact path={"/terms"} render={(props) => <DocumentPage docType={"terms"} {...props} />} />
              <Route exact path={"/privacy"} render={(props) => <DocumentPage docType={"privacy"} {...props} />} />
              <PrivateRoute exact path={"/faqs"} component={FAQsPage} />
              <Route exact path={"/forgotpassword"} component={ForgotPasswordPage} />
              <Route exact path={"/resetpassword"} component={ResetPasswordPage} />
              <Route exact path={"/contactus"} component={ContactUsPage} />
              <Route exact path={"/:id"} component={HomePage} />
            </Switch>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default withRouter(observer(App));
