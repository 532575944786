import React, { useState, useContext, useEffect } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { RootStoreContext } from "../../stores/rootStore";
import { IGuest } from "../../models/user";
import { FORM_ERROR, ValidationErrors } from "final-form";
import { FaMapMarkerAlt, FaStore } from "react-icons/fa";
import PostCodeLookUp from "../../api/agentPostCode";
import { observer } from "mobx-react-lite";
interface IProps {
  setShowLogin: (show: boolean) => void;
}
export const GuestBox: React.FC<IProps> = observer(({ setShowLogin }) => {
  const rootStore = useContext(RootStoreContext);
  const { guest } = rootStore.userStore;
  const { currentShop, shops, prefilledPostCode } = rootStore.commonStore;
  const [errorMsg, setErrorMsg] = useState("");
  const [guestValues, setGuestValues] = useState<IGuest>({
    appKey: currentShop?.appKey || "",
    postCode: "",
    shopCode: currentShop?.shopCode || "",
  });
  useEffect(() => {
    if (currentShop)
      setGuestValues({
        ...guestValues,
        appKey: currentShop?.appKey,
        shopCode: currentShop?.shopCode,
      });
  }, [currentShop]);
  useEffect(() => {
    if(prefilledPostCode && currentShop){

        PostCodeLookUp.lookUp(prefilledPostCode).then((res) => {
            if(res)
            {
                guest({
                  appKey: currentShop.appKey,
                  postCode: prefilledPostCode,
                  shopCode: currentShop.shopCode
                }).catch((error) => {
                setErrorMsg(error);
                })
            }
          })}
        },[prefilledPostCode])
  const postCodeValidate = (value: string) => {
    if(!value)
      return "Postcode is required";
    else{
    }
  }
  const validate = (values: IGuest) => {
    const errors: ValidationErrors = [];
    if (!values.postCode) 
      errors.postCode = "Postcode is required";
    else{
    }
    if (!values.shopCode) errors.shopCode = "Required";
    return errors;
  };
  return (
    <div className="dialog-box guest-box">
      <FinalForm
        initialValues={guestValues}
        onSubmit={(values: IGuest) => {
          setErrorMsg("");
        PostCodeLookUp.lookUp(values.postCode).then((res) => {
            if(!res)
              return {[FORM_ERROR]: "Invalid postcode"};
            else{
              guest(values).catch((error) => {
                setErrorMsg(error);
                return {
                [FORM_ERROR]: error //"Sorry, we don't cover your postcode yet!",
              }})
        }
      })
    }
        }
        render={({
          handleSubmit,
          submitError,
          submitting,
          invalid,
          pristine,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Field name="postCode" validate={postCodeValidate}>
              {({ input, meta }) => (
                <div className="input-group">
                  <div className="input-icon">
                    <FaMapMarkerAlt />
                  </div>
                  <input
                    className="input-field input-field-upper"
                    type="text"
                    {...input}
                    placeholder="Post Code"
                  />
                  {meta.touched && (meta.error || meta.submitError) && (
                    <span className="input-error">
                      {meta.error || meta.submitError}
                    </span>
                  )}
                </div>
              )}
            </Field>
            {shops.length > 1 && (
              <Field name="shopCode" type="select">
                {({ input }) => (
                  <div className="input-group">
                    <div className="input-icon">
                      <FaStore />
                    </div>
                    <select className="input-field" {...input}>
                      {shops.map((shop) => (
                        <option key={shop.uniqueRecId} value={shop.shopCode}>
                          {shop.shopName} ({shop.shopCode})
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </Field>
            )}
            {(submitError || errorMsg) && (
              <span className="input-error-big">{(submitError || errorMsg)}</span>
            )}
            <button type="submit" className="btn-fullwidth btn-default mb-20">
              Book Now
            </button>
          </form>
        )}
      />
      <span className="guest-link">
        Already have an account?{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowLogin(true);
          }}
        >
          click here
        </a>{" "}
        to sign in
      </span>
    </div>
  );
});
