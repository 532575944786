import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { RootStoreContext } from '../../stores/rootStore'

export const SpecialPrices = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const { myPriceGrid, loadMyPriceGrid, loadingMyPriceGrid} = rootStore.userStore;

    useEffect(() =>{
        loadMyPriceGrid()
    },[])

    return (
        <>
        <div className="basket-personal-details">
          <h2 className="basket-heading">Special Prices</h2>
        </div>
          { loadingMyPriceGrid ? (<span className="input-error">Loading special prices...</span>) : (
            <div className="offers-grid">
            <div className="offers-grid-heading">
                <div>Item Name</div>
                <div className="price-matrix-grid-price">Price</div>
            </div>
            { myPriceGrid?.map((price,i) => (
                <div key={i} className="offers-grid-row">
                    <div>{price.itemName}</div>
                    <div className="price-matrix-grid-price">{price.price.toFixed(2)}</div>
                </div>
            ))}
            { myPriceGrid.length === 0 && (
                <span className="input-error-big">Currently there are no special prices for you</span>
            )}
        </div>
          )}


        </>
    )
});
