import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../stores/rootStore";

export const Navbar = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { shopUID, currentShop, prevRoute } = rootStore.commonStore;
  const { user, isLoggedIn, isCustomer, logout, signIn } = rootStore.userStore;
  return (
    <nav>
      <ul className="navbar-nav">
        { isLoggedIn && isCustomer && (
        <>
          <li>
            <Link className={ prevRoute === '/order' ? "selected" : undefined}  to={ (currentShop?.itemSelectionOptions === 0 || user?.noItemSelect) ? "/basket" : "/order"}>Order Booking</Link>
          </li>
          <li>
            <Link className={ prevRoute === '/profile' ? "selected" : undefined}  to="/profile">My Details</Link>
          </li>
          {currentShop?.hasOrderTracking && (
          <li>
            <Link className={ prevRoute === '/myorders' ? "selected" : undefined} to="/myorders">My Orders</Link>
          </li>
          )}
          {(currentShop?.hasSubsciption && user?.hasRegularVisits) && (
          <li>
            <Link className={ prevRoute === '/mysubscriptions' ? "selected" : undefined} to="/mysubscriptions">My Collections</Link>
          </li>

          )}
          <li>
            <Link className={ prevRoute === '/myoffers' ? "selected" : undefined} to="/myoffers">My Offers</Link>
          </li>
        </>
        )}
        <li>
          <Link className={ prevRoute === '/contactus' ? "selected" : undefined} to="/contactus">Contact Us</Link>
        </li>
        <li className="sm-only">
          <Link className={ prevRoute === '/contactus' ? "selected" : undefined} to={"/terms"}>Terms &amp; Conditions</Link>
        </li>
        <li className="sm-only">
          <Link className={ prevRoute === '/contactus' ? "selected" : undefined} to={"/privacy"}>Privacy Policy</Link>
        </li>
        <li className="sm-only">
          <Link className={ prevRoute === '/contactus' ? "selected" : undefined} to={"/faqs"}>FAQs</Link>
        </li>
        <li className="sm-only">
          <Link className={ prevRoute === '/contactus' ? "selected" : undefined} to="/contactus">Contact Us</Link>
        </li>
      </ul>
      {isLoggedIn && isCustomer ? (
        <div className="navbar-logoff-wrapper">
        <Link to={`/${shopUID}`}>
          <button className="btn btn-navbar" onClick={logout}>
            Log Off
          </button>
        </Link>
        <span>{user?.displayName}</span>
        </div>
      ) : (
        <Link to={`/${shopUID}`}>
          <button className="btn btn-navbar" onClick={signIn} >Sign In</button>
        </Link>
      )}
    </nav>
  );
});
