import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ITimeSlot } from '../../models/order';
import { RootStoreContext } from '../../stores/rootStore'
import { getDateString, getTimeString } from '../../utils/Utils';
import { ChangeSlotDialog } from './ChangeSlotDialog';

export const ScheduledVisits = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const { scheduledVisits, loadingScheduledVisits, loadScheduledVisit, deleteVisit, changeVisitSlot, showChangeSlotDialog, setShowChangeSlotDialog } = rootStore.orderStore;
    const [timeSlotField, setTimeSlotField] = useState<ITimeSlot>()
    const [uniqueRecId, setUniqueRecId] = useState("");
    useEffect(() =>{
        loadScheduledVisit()
    },[])
    const handleDelete = (uid: string) =>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='dialog-box'>
                    <div className="dialog-title">
                    <h2>Confirm Delete</h2>
                    </div>
                    <div className="dialog-body">
                    <p>Are you sure you want to delete the selected visit?</p>
                    </div>
                    <div className="dialog-footer">
                    <button className="btn btn-default" onClick={() => deleteVisit(uid)}>Yes</button>
                    <button className="btn btn-cancel" onClick={onClose}>No</button>
                    </div>
                </div>
                );
            }
            });
    }
    const handleEdit = (uid: string, slot: ITimeSlot) =>{
        setUniqueRecId(uid);
        setTimeSlotField(slot);
        setShowChangeSlotDialog(true);
    }
    const handleSlotChange = (slot: ITimeSlot) =>{
        if(timeSlotField?.uniqueRecId !== slot.uniqueRecId){
            setTimeSlotField(slot);
            changeVisitSlot(uniqueRecId,slot.uniqueRecId,-1);
            setShowChangeSlotDialog(false);
            setUniqueRecId("");
        }
    }
    return (
        <>
        <div className="basket-personal-details">
          <h2 className="basket-heading">Scheduled Visits</h2>
        </div>
          { loadingScheduledVisits ? (<span className="input-error">Loading Visits...</span>) : (
            <div className="offers-grid">
            <div className="scvisit-grid-heading">
                <div>Purpose</div>
                <div>Date</div>
                <div>Time Slot</div>
                <div>Order No</div>
                <div>Change Slot</div>
                <div>Delete</div>
            </div>
            { scheduledVisits?.map((visit,i) => (
                <div key={i} className="scvisit-grid-row">
                    <div>{visit.purpose}</div>
                    <div>{getDateString(visit.timeSlot.slotDate)}</div>
                    <div>{`${getTimeString(visit.timeSlot.slotTimeFrom)}:00 to ${getTimeString(visit.timeSlot.slotTimeTo)}:00`}</div>
                    <div>{visit.orderNo}</div>
                    <div><FaEdit onClick={()=>handleEdit(visit.uniqueRecId, visit.timeSlot)} /></div>
                    {visit.orderNo === 0 && (<div><FaTrash onClick={()=> handleDelete(visit.uniqueRecId)} /></div>)}
                </div>
            ))}
            { scheduledVisits.length === 0 && (
                <span className="input-error-big">Currently there are no Scheduled visits</span>
            )}
            {showChangeSlotDialog && (<ChangeSlotDialog selectedSlot={timeSlotField!} hideDialog={()=> {setShowChangeSlotDialog(false);setUniqueRecId("");}} showDialog={showChangeSlotDialog} setTimeSlot={handleSlotChange} />)}
        </div>)}
        </>
    )
});
