import React, { useContext, useEffect } from "react";
import { Header } from "../components/Layout/Header";
import { Footer } from "../components/Layout/Footer";
import { DeliveryDetails } from "../components/Basket/DeliveryDetails";
import { OrderDetails } from "../components/Basket/OrderDetails";
import { BasketTotals } from "../components/Basket/BasketTotals";
import { BasketFooter } from "../components/Basket/BasketFooter";
import { RootStoreContext } from "../stores/rootStore";
import { Form as FinalForm } from "react-final-form";
import { IOrderBasket } from "../models/order";
import { FORM_ERROR, ValidationErrors } from "final-form";
import { observer } from "mobx-react-lite";
import { history } from "../";
import { NameAddress } from "../components/Basket/NameAddress";
import { PaymentDetails } from "../components/Basket/PaymentDetails/PaymentDetails";
import { WorldPayDialog } from "../components/WorldPay/WorldPayDialog";
import { confirmAlert } from "react-confirm-alert";
import { StripeDialogWrapper } from "../components/Stripe/StripeDialogWrapper";

export const BasketPage = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const {
    currentOrder,
    createOrder,
    saveOrder,
    setLoadingOrder,
    basketValues,
    setBasketValues,
    orderSaved
  } = rootStore.orderStore;
  const {
    defaultCollection,
    defaultDelivery,
    noDeliverySlot,
    noCollectionSlot
  } = rootStore.timeSlotStore;
  const { user, customer, customerInfo, showCardDialog, defaultCardUID, setCardSaveError, customerCards } = rootStore.userStore;
  const { currentShop,setPrevRoute } = rootStore.commonStore;
  useEffect(()=>{
    setPrevRoute(window.location.pathname);
  if(orderSaved === 1 && currentOrder?.orderNo === 0)
    history.push("/order");
  },[])
  useEffect(() => {
    if (!currentOrder && customer){
      createOrder(user!.accountCode);
      setLoadingOrder(false);
    }
    if(!customer)
      setLoadingOrder(true);
  },[currentOrder, customer])
  useEffect(() => {
    if (user)
      if (user!.isCustomer && !customer) {
        customerInfo();
        setLoadingOrder(true);
      } else
        setLoadingOrder(false);
  }, [user, customerInfo,setLoadingOrder]);

  useEffect(() => {
    if (customer && basketValues.firstName.length === 0) {
      setBasketValues({
        accountCode: customer.accountCode,
        title: customer.title,
        firstName: customer.firstName,
        middleName: customer.middleName,
        surname: customer.surname,
        address1: customer.address1,
        address2: customer.address2,
        postCode: customer.postCode,
        emailId: customer.emailId,
        mobileNo: customer.mobileNo,
        password: customer.password,
        confirmPassword: customer.password,
        deliveryNotes: customer.deliveryNotes,
        orderNotes: "",
        discountCode: "",
        headerDiscount: 0,
        grossAmount: 0,
        minCharge: 0,
        collectFromShop: false
      });
    }
  }, [customer, basketValues, setBasketValues]);
useEffect(()=>{
  if(currentOrder){
    if(currentOrder.orderNotes.length > 0)
      setBasketValues({...basketValues,orderNotes: currentOrder.orderNotes});
    if(currentOrder.discountCode.length > 0)
      setBasketValues({...basketValues,discountCode: currentOrder.discountCode});
    if(currentOrder.headerDiscount > 0)
      setBasketValues({...basketValues,headerDiscount: currentOrder.headerDiscount});
    if(currentOrder.grossAmount > 0)
      setBasketValues({...basketValues,grossAmount: currentOrder.grossAmount});
      if(currentOrder.minCharge > 0)
      setBasketValues({...basketValues,minCharge: currentOrder.minCharge});
    if(currentOrder.collectFromShop)
      setBasketValues({...basketValues,collectFromShop: currentOrder.collectFromShop});
  }
},[currentOrder])

  const validate = async () => {
    const errors: ValidationErrors = [];
    if (!defaultDelivery)
      if (noDeliverySlot) errors.deliveryNotes = "No slots available";
      else errors.deliveryNotes = "Kindly select a delivery slot";
    if (!defaultCollection)
      if (noCollectionSlot) errors.deliveryNotes = "No slots available";
      else errors.deliveryNotes = "Kindly select a collection slot";
    if(currentShop!.hasCardModule && defaultCardUID.length === 0){
      if(customerCards.length > 0)
        setCardSaveError("Please select a card for payment")
      else
        setCardSaveError("Please add a credit/debit card for payment")
    } else
      setCardSaveError("");
        
    return errors;
  };
   let cardDialog = null;
   switch(currentShop!.cardProvider)
   {
     case 'WORLDPAY': cardDialog = <WorldPayDialog />; break;
     case 'STRIPE' : cardDialog = <StripeDialogWrapper />; break;
     default: cardDialog = <StripeDialogWrapper />;
   }
  return (
    <>
      <Header />
      <FinalForm
        initialValues={basketValues}
        mutators={{
          clearVoucher: (args, state, utils) => {
                  utils.changeValue(state, 'discountCode', () => '')
                },
        }}
        validate={validate}
        onSubmit={(values: IOrderBasket) => {
          if(customerCards.length === 0 && !user?.hasCredit){
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
              <div className='dialog-box'>
                  <div className="dialog-title">
                    <h2>No Credit/Debit Card</h2>
                  </div>
                  <div className="dialog-body">
                     <p>You need to add atleast one credit/debit card</p>
                  </div>
                  <div className="dialog-footer">
                  <button className="btn btn-default"
                    onClick={onClose}
                  >
                    OK
                  </button>
                  </div>
                </div>
                );}})
          } else{
            setBasketValues(values);
            saveOrder()
            .then(() => history.push("/info"))
            .catch((error) => ({
              [FORM_ERROR]: error,
            }))
          }         
}
        }
        render={({
          handleSubmit, values,  form
        }) => (
          <form id="basket-form" onSubmit={handleSubmit}>
            <main>
              <div className="basket-wrapper">
                <div className="basket-container">
                  <div className="basket-details">
                    <NameAddress />
                    <DeliveryDetails />
                  </div>
                  <div className="basket-details">
                    <OrderDetails />
                    <BasketTotals formValues={values} clearVoucher={form.mutators.clearVoucher} />
                    {currentShop!.hasCardModule && !user?.hasCredit && (<PaymentDetails />)}
                  </div>
                </div>
              </div>
            </main>
            <Footer>
              <BasketFooter />
            </Footer>
          </form>
        )}
      />
      {currentShop!.hasCardModule && showCardDialog && cardDialog}
    </>
  );
});
