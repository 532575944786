import { useEffect, useState } from 'react';
import { IAppInstance } from '../models/appInstance';

export const useIsLoaded = () =>{
    const localStorageTimeout = 15 * 1000; // 15,000 milliseconds = 15 seconds.
    const localStorageResetInterval = 10 * 1000; // 10,000 milliseconds = 10 seconds.
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        var instanceId = getValues(0) || createInstanceId();
        setValues(0,instanceId);
        var val = getValues(1);
        var currentInstance: IAppInstance | null = null;
        if(val)
            currentInstance = JSON.parse(val) || null;
            
        if(currentInstance === null || currentInstance?.timeStamp < (new Date().getTime() - localStorageTimeout) || currentInstance.instanceId === instanceId){
            const setInstanceObject = () => {
                var newInstance: IAppInstance = {
                    instanceId: instanceId,
                    timeStamp: new Date().getTime()
                };
                setValues(1,JSON.stringify(newInstance));
            };
            setInstanceObject();
            setInterval(setInstanceObject,localStorageResetInterval);
            window.onunload = () => {
                setValues(0,"");
                window.localStorage.removeItem("mdc_instance");
            };
            setIsLoaded(false);
        } 
        else{
            setIsLoaded(true);
        }
           
    }, [])

    const getValues = (type: number) => {
        var value: string | null = "";
        switch(type){
            case 0 :
                value = window.name;
                break;
            case 1 :
                value = window.localStorage.getItem("mdc_instance");
                break;
        }
        return value === "" ? null : value;
    }
    const setValues = (type: number, value: string) => {
        switch(type){
            case 0:
                window.name = value;
                break;
            case 1:
                window.localStorage.setItem("mdc_instance",value);
                break;
        }
    }
    const createInstanceId = () => {
        const s4 = function() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
    }
    
    return isLoaded;
}
