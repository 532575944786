import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { OrderDetailRow } from "./OrderDetailRow";
interface IProps{
  handleDiscardChanges : () => void;
}
export const BasketOrderDetails : React.FC<IProps> = observer(({handleDiscardChanges}) => {
  const rootStore = useContext(RootStoreContext);
  const { orderDetails } = rootStore.orderStore;
  return (
    <div className="basket-orders">
      <div className="basket-heading">
      <h2>Details</h2>
      <button className="btn-small btn-cancel" onClick={handleDiscardChanges}>Discard Changes</button>
      </div>
      <div className="basket-grid">
        {orderDetails && orderDetails.length > 0 ? (
          orderDetails
            .sort((d1, d2) => d1.mainLineNo - d2.mainLineNo)
            .map((od) => <OrderDetailRow key={od.lineNo} orderDetail={od} />)
        ) : (
          <div className="basket-grid-row input-error-big">
            No items selected
          </div>
        )}
      </div>
    </div>
  );
});
