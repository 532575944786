import { RootStore } from "./rootStore";
import { action, observable, runInAction } from "mobx";
import { IMainCategory, ISubCategory } from "../models/category";
import agent from "../api/agent";

export default class CategoryStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loadingMainCategories = false;
  @observable loadingSubCategories = false;
  @observable loadingAlterCategories = false;
  @observable loadingAlterations = false;

  @observable mainCategories: IMainCategory[] = [];
  @observable subCategories: ISubCategory[] = [];
  @observable alterMainCategories: IMainCategory[] = [];
  @observable alterSubCategories: ISubCategory[] = [];

  @observable selectedItem: ISubCategory | undefined;
  @observable selectedMainCategory: IMainCategory | undefined;

  @observable showAlterationsTypeDialog = false;
  @observable alterationsTypeEditMode = false;
  @observable showAlterationDialog = false;
  @observable alterationDialogEditMode = false;
  @observable selectedMainLineNo = 0;
  @observable showAlterationEditDialog = false;
  @observable alterationsType = 0;
  @observable alterationUpdated: boolean = false;
  @observable alterationTypeUpdated: boolean = false;
  @observable selectedAlterCategory: IMainCategory | undefined;
  @observable selectedAlteration: ISubCategory | undefined;

  @action setAlterationsType = (type: number) => {
    this.alterationsType = type;
  }
  @action setAlterationUpdated = (updated: boolean) => {
    this.alterationUpdated = updated;
  };
  @action setAlterationTypeUpdated = (updated: boolean) => {
    this.alterationTypeUpdated = updated;
  }

  @action loadCategories = () => {
    this.loadingMainCategories = true;
    agent.Category.maincategory()
      .then((categories) => {
        runInAction(
          () =>
            (this.mainCategories = categories.sort(
              (a, b) => a.position - b.position
            ))
        );
      })
      .catch((error) => console.log(error))
      .finally(() => runInAction(() => (this.loadingMainCategories = false)));
  };

  @action selectMainCategory = (groupName: string) => {
    this.selectedMainCategory = this.mainCategories.find(
      (c) => c.groupName === groupName
    );
  };

  @action loadSubCategories = (id: string | undefined) => {
    if (id !== undefined) {
      this.loadingSubCategories = true;
      agent.Category.subcategory(id)
        .then((subcategories) => {
          runInAction(
            () =>
              (this.subCategories = subcategories.sort(
                (a, b) => a.position - b.position
              ))
          );
        })
        .catch((error) => console.log(error))
        .finally(() => runInAction(() => (this.loadingSubCategories = false)));
    }
  };

  @action loadAlterCategories = async (id: string | undefined) => {
    if (id) {
      this.loadingAlterCategories = true;
      runInAction(() => (this.alterSubCategories = []))
      agent.Category.altercategory(id)
        .then((alterCategories) => {
          runInAction(() => (this.alterMainCategories = alterCategories));
        })
        .catch((error) => console.log(error))
        .finally(() =>
          runInAction(() => (this.loadingAlterCategories = false))
        );
    } else {
      runInAction(() => {
        this.alterMainCategories = [];
        this.loadingAlterCategories = false;
      });
    }
  };

  @action loadAlterations = async (id: number | undefined) => {
    if(id){
      this.loadingAlterations = true;
      agent.Category.alterations(id)
        .then((alterations) => {
          runInAction(() => (this.alterSubCategories = alterations));
        })
        .catch((error) => console.log(error))
        .finally(() => runInAction(() => (this.loadingAlterations = false)));
    } else {
      runInAction(() => {
        this.alterSubCategories = [];
        this.loadingAlterations = false;
      });
    }
  };
  
  @action showAlterations = () => {
    this.alterationDialogEditMode = false;
    this.showAlterationDialog = true;
  };
  @action showAlterationsAddEdit = (item: ISubCategory, mLineNo: number) => {
    this.selectedItem = item;
    this.selectedMainLineNo = mLineNo;
    this.alterationDialogEditMode = true;
    this.showAlterationDialog = true;
  }
  @action setShowAlterationsTypeDialog = (item: ISubCategory) => {
    this.selectedItem = item;
    this.selectedMainLineNo = 0;
    this.showAlterationsTypeDialog = true; 
    this.alterationsTypeEditMode = false;
  }
  @action setShowAlterationTypeDialog = (mainLineNo: number) => {
    this.selectedMainLineNo = mainLineNo;
    this.showAlterationsTypeDialog = true;
    this.alterationsTypeEditMode = true; 
  }
  @action hideAlterationsTypeDialog = () => {
    this.showAlterationsTypeDialog = false;
  }

  @action showAlterationsEdit = (item: ISubCategory) => {
    this.showAlterationEditDialog = true;
    this.selectedItem = item;
  }
  @action hideAlterationsEdit = () => {
    this.showAlterationEditDialog = false;
  }

  @action hideAlterations = () => {
    this.alterationDialogEditMode = false;
    this.showAlterationDialog = false;
  };
  @action setSelectedAlterCategory = (value: IMainCategory | undefined) => {
    this.selectedAlterCategory = value;
  }
  @action setSelectedAlteration = (value: ISubCategory | undefined) => {
    this.selectedAlteration = value;
  }
  @action resetAlterations = () => {
    this.alterMainCategories = [];
    runInAction(() => {
      this.alterSubCategories = [];
      this.selectedAlterCategory = undefined;
      this.selectedAlteration = undefined;
    })

  }
}