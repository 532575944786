import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/rootStore";
import { ITimeSlot } from "../../../models/order";
import { DeliveryTimeSlotRow } from "./DeliveryTimeSlotRow";

interface IProps {
  timeSlot: ITimeSlot;
  day: Date | undefined;
  setTimeSlot: (timeSlot: ITimeSlot) => void;
}
export const DeliveryTimeSlotGrid: React.FC<IProps> = observer(
  ({ timeSlot, day, setTimeSlot }) => {
    const rootStore = useContext(RootStoreContext);
    const {
      getDeliverySlotsForDate,
      deliverySlotsForDate,
      loadingDeliverySlots,
    } = rootStore.timeSlotStore;
    useEffect(() => {
      if(day){
            getDeliverySlotsForDate(day)
      }
    },[day])
    if (deliverySlotsForDate.length === 0) {
      if (loadingDeliverySlots)
        return <div>Loading slots...</div>;
      return <div>No time slots available</div>;
    }
    return (
      <div className="timeslot-time">
          {deliverySlotsForDate.map((ds) => (
              <DeliveryTimeSlotRow
                key={ds.uniqueRecId}
                timeSlot={ds}
                localTimeSlot={timeSlot}
                setTimeSlot={setTimeSlot}
              />
            ))}
      </div>
    );
  }
);
