import React, { useContext, useState } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { IResetPassword } from "../../models/user";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { FaKey } from "react-icons/fa";
import { FORM_ERROR } from "final-form";
import { observer } from "mobx-react-lite";
import { confirmAlert } from "react-confirm-alert";
interface IProps{
    resetKey: string;
    message?: string;
}
export const ResetPasswordBox : React.FC<IProps> = observer(({resetKey, message}) => {
  const rootStore = useContext(RootStoreContext);
  const { resetPassword, signIn } = rootStore.userStore;
    const [resetPasswordValues, setResetPasswordValues] = useState<IResetPassword>({
  password: "",
  confirmPassword: "",
  resetKey: resetKey,
});
  const [result, setResult] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const validate = combineValidators({
    password: isRequired("Password"),
    confirmPassword: isRequired("Confirm Password"),
  });
  return (
    <div className="dialog-box login-box">
      <div className="dialog-heading">Reset Password</div>
        {message && result.length === 0 && (<div className="dialog-heading-message"><span>{message}</span></div>)}
      {(result.length === 0) ? (
      <FinalForm
        validate={validate}
        initialValues={resetPasswordValues}
        onSubmit={(values: IResetPassword) => {
          setSubmitting(true);
          resetPassword(values).then( function(response){
            setSubmitting(false);
            if(response.status === 1){
               confirmAlert({
                 customUI: ({ onClose }) => {
                return (
                  <div className='dialog-box'>
                  <div className="dialog-title">
                    <h2>Password Reset Successfully</h2>
                  </div>
                  <div className="dialog-body">
                     <p>Your password has been successfully reset, so please sign on by clicking on the sign in button</p>
                  </div>
                  <div className="dialog-footer">
                  <button className="btn btn-default"
                    onClick={() => {
                      signIn();
                      onClose();
                    }}
                  >
                    Sign In
                  </button>
                  </div>
                </div>
                )}
               });
            }
            else{
              setResult("");
              return {[FORM_ERROR]: response.error.description};
            }
        }).catch((error) => ({
            [FORM_ERROR]: error,
          }))
        }
        }
        render={({
          handleSubmit,
          submitError,
          submitting,
          invalid,
          pristine,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Field name="password">
              {({ input, meta }) => (
                <div className="input-group">
                  <div className="input-icon">
                    <FaKey />
                  </div>
                  <input
                    className="input-field"
                    type="password"
                    {...input}
                    onBlur={()=>setResult("")}
                    placeholder="New Password"
                  />
                  {meta.touched && (meta.error || meta.submitError) && (
                    <span className="input-error">
                      {meta.error || meta.submitError}
                    </span>
                  )}
                </div>
              )}
            </Field>
            <Field name="confirmPassword">
              {({ input, meta }) => (
                <div className="input-group">
                  <div className="input-icon">
                    <FaKey />
                  </div>
                  <input
                    className="input-field"
                    type="password"
                    {...input}
                    onBlur={()=>setResult("")}
                    placeholder="Confirm Password"
                  />
                  {meta.touched && (meta.error || meta.submitError) && (
                    <span className="input-error">
                      {meta.error || meta.submitError}
                    </span>
                  )}
                </div>
              )}
            </Field>
            {submitError && !dirtySinceLastSubmit && (
              <span className="input-error-big">{submitError}</span>
            )}
            { submitting && (<span className="input-error">Please wait...</span>)}
            {(!submitting && result.length === 0) && (
              <button
              type="submit"
              className="btn-fullwidth btn-default mb-20"
              disabled={
                (invalid && !dirtySinceLastSubmit) || pristine || submitting
              }
            >
              Reset Password
            </button>
            )}
            
          </form>
        )}
      />
      ) : (
      <span className="input-error-big">{result}</span>
      )}
    </div>
  );
});
