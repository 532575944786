import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { ICustomerCard } from "../../models/cards";
import { RootStoreContext } from "../../stores/rootStore";
import { FaMinusSquare } from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert";
interface IProps {
  customerCard: ICustomerCard;
}
export const CardDetailRow: React.FC<IProps> = observer(
  ({ customerCard }) => {
      const rootStore = useContext(RootStoreContext);
      const { setDefaultCard, deactivateCustomerCard } = rootStore.userStore;
      const handleDelete = () => {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className='dialog-box'>
                      <div className="dialog-title">
                        <h2>Delete Credit/Debit Card Confirmation</h2>
                      </div>
                      <div className="dialog-body">
                        <p>`Are you sure you want to delete {customerCard.cardType} {customerCard.cardNumber}?`</p>
                      </div>
                      <div className="dialog-footer">
                      <button className="btn btn-cancel" onClick={onClose}>No</button>
                      <button className="btn btn-default" onClick={() => {
                        deactivateCustomerCard(customerCard);
                        onClose();
                      }}>Yes</button>
                      </div>
                    </div>
                  );
                }
              });
      }
    return (
      <div className="card-detail-grid-row">
        <div>
            <label className="input-form-radio">
              <input name="cardDefault" type="radio" value={customerCard.uniqueRecId} onClick={()=>setDefaultCard(customerCard)} defaultChecked={customerCard.isDefault} />
              <span className="checkmark"></span>
            </label>
        </div>
        <div>
            <span>{customerCard.cardType}</span>
        </div>
        <div><span>{customerCard.cardNumber}</span></div>
        <div>
            <span>{customerCard.cardExpiry}</span>
        </div>
        <div>
            <FaMinusSquare className="delete-icon" onClick={handleDelete} />
        </div>
      </div>
    );
  }
);
