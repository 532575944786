import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { FaCaretRight, FaCaretUp } from 'react-icons/fa';
interface IProp{
    question: string;
    answer: string;
}
export const FAQQuestion : React.FC<IProp> = observer(({question,answer}) => {
    const [showAnswer, setShowAnswer] = useState(false);
    const toggleAnswer = () =>{
        setShowAnswer(!showAnswer);
    }

    return (
        <div>
            <h2 onClick={toggleAnswer} className="faq-question">{showAnswer ? (<FaCaretUp />) : (<FaCaretRight />)}{question}</h2>
            {showAnswer && (<p className="faq-answer">{answer}</p>)}
        </div>
    )
});
