import React, { useContext } from "react";
import {
  RouteProps,
  RouteComponentProps,
  Route,
  Redirect,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";

interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
}

export const PrivateRoute: React.FC<IProps> = observer(
  ({ component: Component, ...rest }) => {
    const rootStore = useContext(RootStoreContext);
    const { isLoggedIn } = rootStore.userStore;
    const { shopUID } = rootStore.commonStore;
    return (
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect to={`/${shopUID}`} />
          )
        }
      />
    );
  }
);
