import React, { useContext, useState } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { Field } from "react-final-form";
import { IOrderBasket } from "../../models/order";

interface IProps{
  formValues: IOrderBasket,
  clearVoucher: () => void;
};
export const BasketTotals:React.FC<IProps> = observer(({formValues, clearVoucher}) => {
  const rootStore = useContext(RootStoreContext);
  const { totalPieces, totalGrossAmount, totalDiscount, setHeaderDiscount, checkVoucher, setDiscountVoucher } = rootStore.orderStore;
  const { user } = rootStore.userStore;
  const{ currentShop } = rootStore.commonStore;
  const [voucherError, setVoucherError] = useState("")
  const handleApply =  async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>,voucherCode: string) => {
    e.preventDefault();
    if(voucherCode?.length === 0)
      setVoucherError("Voucher code blank!");
    else
    {
      var dv = await checkVoucher(voucherCode!);
      if(dv){
          let amount = totalGrossAmount;
          let discount = 0;
            if(dv.offerType === 0)
              discount = dv.flatDiscount;
            else
              discount = parseFloat((totalGrossAmount*(dv.percentDiscount/100)).toFixed(2));
        if(dv.minimumSpend > 0 && dv.maximumSpend > 0){
          if(dv.minMaxAfterDiscount){
              amount = totalGrossAmount - discount;
          }
          if(amount >= dv.minimumSpend && amount <= dv.maximumSpend){
            setDiscountVoucher(dv);
            setHeaderDiscount(discount);
            setVoucherError("");
          }
          else{
            setDiscountVoucher(null);
            setHeaderDiscount(0);
            setVoucherError(`Voucher code not applicable! Total amount must be between ${dv.minimumSpend} and ${dv.maximumSpend}`)
            clearVoucher();
          }
        }
        else{
          setDiscountVoucher(dv);
          setHeaderDiscount(discount);
          setVoucherError("");
        }
      }
      else{
        setDiscountVoucher(null);
        setHeaderDiscount(0);
        setVoucherError("Invalid voucher code!");
        clearVoucher();
      }

    }
  }
    return (
    <div className="basket-totals">
      <h2 className="basket-heading">Totals</h2>
      {currentShop?.hasVouchers && (
      <div className="discount-input-group">
        <Field name="discountCode">
        {({input,meta}) => (
        <input
          className="discount-input"
          type="text"
          placeholder="Voucher Code"
          {...input}
        />
        )}
        </Field>
        <Field name="applyVoucher" validateFields={['discountCode']}>
          {() => (
              <button className="discount-btn" onClick={(event) => handleApply(event,formValues.discountCode)} >Apply</button>
          )}
        </Field>
      </div>
      )}
      <span className="basket-field-group-error input-error">{voucherError}</span>
      <div className="basket-grid">
        <div className="basket-discount-row">
          <div>
            Sub Total
            <span className="basket-footer-note">
              {totalGrossAmount < user!.minOrderValue
                ? `( Min order value £20 )`
                : ""}
            </span>
          </div>
          <div className="basket-grid-number">
            &pound;
            {totalGrossAmount < user!.minOrderValue
              ? user!.minOrderValue.toFixed(2)
              : totalGrossAmount.toFixed(2)}
          </div>
        </div>
        <div className="basket-discount-row">
          <div>Discount</div>
            <div className="basket-grid-number">-&pound;{totalDiscount.toFixed(2)}</div>
        </div>
        <div className="basket-discount-row">
          <div>Total Pieces</div>
          <div className="basket-grid-number">{totalPieces}</div>
        </div>
        <div className="basket-discount-row">
          <div>Total Amount</div>
          <div className="basket-grid-number">
            &pound;
            {totalGrossAmount < user!.minOrderValue
              ? (user!.minOrderValue - totalDiscount).toFixed(2)
              : (totalGrossAmount - totalDiscount).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
});
