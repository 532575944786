import { observer } from 'mobx-react-lite'
import React from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
interface IProps{
    status: number;
}
export const MyOrderStatus : React.FC<IProps> = observer(({status}) => {
    return (
        <div className="myorders-order-status-container">
            <div className="myorders-order-status-wrapper">
                <div className={status >= 1 ? "myorders-order-status-complete" : "myorders-order-status-icon"}><FaArrowAltCircleRight /></div>
                <div className="myorders-order-status-text">Awaiting Pickup</div>
            </div>
            <div className="myorders-order-status-wrapper">
                <div className={status >= 2 ? "myorders-order-status-complete" : "myorders-order-status-icon"}><FaArrowAltCircleRight /></div>
                <div className="myorders-order-status-text">Driver Picked Up</div>
            </div>
            <div className="myorders-order-status-wrapper">
                <div className={status >= 3 ? "myorders-order-status-complete" : "myorders-order-status-icon"}><FaArrowAltCircleRight /></div>
                <div className="myorders-order-status-text">In Processing</div>
            </div>
            <div className="myorders-order-status-wrapper">
                <div className={status >= 4 ? "myorders-order-status-complete" : "myorders-order-status-icon"}><FaArrowAltCircleRight /></div>
                <div className="myorders-order-status-text">Ready</div>
            </div>
            <div className="myorders-order-status-wrapper">
                <div className={status >= 5 ? "myorders-order-status-complete" : "myorders-order-status-icon"}><FaArrowAltCircleRight /></div>
                <div className="myorders-order-status-text">On Delivery</div>
            </div>
            <div className="myorders-order-status-wrapper">
                <div className={status >= 6 ? "myorders-order-status-complete" : "myorders-order-status-icon"}><FaArrowAltCircleRight /></div>
                <div className="myorders-order-status-text">Delivered</div>
            </div>
        </div>            
    )
});
