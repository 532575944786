import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Header } from "../components/Layout/Header";
import { RootStoreContext } from "../stores/rootStore";
import { AppOrdersList } from "../components/Orders/AppOrdersList";
import { MyOrderStatus } from "../components/Orders/MyOrderStatus";
import { BundleOrderGroup } from "../components/Orders/BundleOrderGroup";
import { BackToOrdersFooter } from "../components/Common/BackToOrdersFooter";
import { Footer } from "../components/Layout/Footer";
import { ChangeSlotDialog } from "../components/Subscriptions/ChangeSlotDialog";
import { ITimeSlot } from "../models/order";

export const MyOrdersPage = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const {setPrevRoute} = rootStore.commonStore;
  const { 
    getUnPickedOrders, unPickedOrders, loadingUnPickedOrders,
    getPickedOrders, pickedOrders, loadingPickedOrders,
    getOrdersInProcess, ordersInProcess, loadingOrdersInProcess,
    getOrdersReady, ordersReady, loadingOrdersReady,
    getOrdersOnDelivery, ordersOnDelivery, loadingOrdersOnDelivery,
    getOrdersDelivered, ordersDelivered, loadingOrdersDelivered,
    showChangeSlotDialog, setShowChangeSlotDialog, changeVisitSlot, selectedVisitUID, setSelectedVisitUID, visitSlot, setVisitSlot
  } = rootStore.orderStore;
  const [showDelivered, setShowDelivered] = useState(false);
  const [ordersType, setOrdersType] = useState<number>(-1);
  useEffect(() =>{
    setPrevRoute(window.location.pathname);
    getUnPickedOrders();
    getPickedOrders();
    getOrdersInProcess();
    getOrdersReady();
    getOrdersOnDelivery();
    getOrdersDelivered();
},[])

const handleSlotChange = (slot: ITimeSlot) =>{
        if(visitSlot !== slot){
            changeVisitSlot(selectedVisitUID,slot.uniqueRecId,ordersType);
            setVisitSlot(slot);
            setShowChangeSlotDialog(false);
            setOrdersType(-1);
        }
    }
  const handleHideDialog = () => {
    setSelectedVisitUID("");
    setVisitSlot(undefined);
    setOrdersType(-1);
    setShowChangeSlotDialog(false);
  }
  return (
    <>
    <Header />
    <main>
      <div className="common-wrapper">
        <div className="offers-container">
          { unPickedOrders.length > 0 && (
            <>
              <div className="myorders-heading-wrapper">
                <h2 className="myorders-heading-title">Order(s) Awaiting Pickup</h2>
                <MyOrderStatus status={1} />         
              </div>
              <AppOrdersList orders={unPickedOrders} orderType={1} setOrdersType={setOrdersType} />
            </>
          )}
        
        { pickedOrders.length > 0 && (
          <>
            <div className="myorders-heading-wrapper">
              <h2 className="myorders-heading-title">Order(s) Picked Up</h2>
              <MyOrderStatus status={2} />
            </div>
            <AppOrdersList orders={pickedOrders} orderType={2} setOrdersType={setOrdersType} />
          </>
        )}
          {  ordersInProcess.length > 0 && (
            <>
              <div className="myorders-heading-wrapper">
                <h2 className="myorders-heading-title">Order(s) In Processing</h2>
                <MyOrderStatus status={3} />  
              </div>
              <BundleOrderGroup orders={ordersInProcess} orderType={3} setOrdersType={setOrdersType} />
            </>
          )}
          {  ordersReady.length > 0 && (
            <>
              <div className="myorders-heading-wrapper">
                <h2 className="myorders-heading-title">Order(s) Ready</h2>
                <MyOrderStatus status={4} />         
              </div>
              <BundleOrderGroup orders={ordersReady} orderType={4} setOrdersType={setOrdersType} />
            </>
          )}
          {  ordersOnDelivery.length > 0 && (
            <>
              <div className="myorders-heading-wrapper">
                <h2 className="myorders-heading-title">Order(s) on Delivery</h2>
                <MyOrderStatus status={5} />         
              </div>
              <BundleOrderGroup orders={ordersOnDelivery} orderType={5} setOrdersType={setOrdersType} />
            </>
          )}
          {  ordersDelivered.length > 0 && (
            <>
              <div className="myorders-heading-wrapper">
                <h2 className="myorders-heading-title">Order(s) Delivered</h2>
                <button className="btn btn-default" onClick={() => setShowDelivered(!showDelivered)}>{showDelivered ? "Hide" : "Show"}</button>         
              </div>
              {showDelivered && (<BundleOrderGroup orders={ordersDelivered} orderType={6} setOrdersType={setOrdersType} />)}
            </>
          )}
          { 
          (!loadingUnPickedOrders && unPickedOrders.length === 0 &&
          !loadingPickedOrders && pickedOrders.length === 0 &&
          !loadingOrdersInProcess && ordersInProcess.length === 0 &&
          !loadingOrdersReady && ordersReady.length === 0 &&
          !loadingOrdersOnDelivery && ordersOnDelivery.length === 0 &&
          !loadingOrdersDelivered && ordersDelivered.length === 0) &&
          (
            <div>You have no orders</div>
          )
          }
      </div>
      </div>
    </main>
    <Footer>
        <BackToOrdersFooter />
      </Footer>
      {showChangeSlotDialog && (<ChangeSlotDialog selectedSlot={visitSlot!} hideDialog={handleHideDialog} showDialog={showChangeSlotDialog} setTimeSlot={handleSlotChange} />)}
    </>
  );
});
