import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";

export const Footer: React.FC = observer(({ children }) => {
  return (
    <footer>
      {children && children}
      <div className="page-footer">
        <div>Copyright &copy; 2020 My Drycleaner. All rights reserved.</div>
        <div className="footer-menu">
          <ul>
            <li>
              <Link to={"/terms"}>Terms &amp; Conditions</Link>
            </li>
            <li>
              <Link to={"/privacy"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/faqs"}>FAQs</Link>
            </li>
            <li>
              <Link to="/contactus">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
});
