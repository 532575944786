import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { CardDetailRow } from "./CardDetailRow";

export const CardDetails = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const { customer, getCustomerCards, customerCards, showNewCardDialog, cardSaveError, defaultCardUID } = rootStore.userStore;
    useEffect(() => {
        if(customer){
            getCustomerCards();
        }
    }, [customer])
    return (
    <>
      <div className="card-detail-row-header">
            <div><span>Default</span></div>
            <div><span>Card Type</span></div>
            <div><span>Card Number</span></div>
            <div><span>Expiry Date</span></div>
            <div><span>Delete</span></div>
        </div>
        {customerCards.sort((a) => a.isDefault ? -1 : 1).map((card) => {
            return <CardDetailRow key={card.uniqueRecId} customerCard={card} />
        })}
        {(defaultCardUID.length === 0 && cardSaveError.length > 0) && (
            <span className="input-error">{cardSaveError}</span>
        )}
        <div className="card-grid-footer">
            <button className="btn btn-default" onClick={ (event) => { event.preventDefault(); showNewCardDialog();}}>Add a Credit/Debit card</button>
        </div>
    </>
    );
  }
);
