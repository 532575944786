import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { StripeDialog } from "./StripeDialog";


export const StripeDialogWrapper = observer(() => {
    
    const rootStore = useContext(RootStoreContext);
    const { currentShop } = rootStore.commonStore;
    const { createStripeIntent, stripeIntent } = rootStore.userStore;
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null); 
    const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions | undefined>(undefined);

    useEffect(() => {
    if(!stripeIntent){
        createStripeIntent();
    } else if(stripeIntent && stripeIntent!.secret.length > 0){
        setStripeOptions({clientSecret: stripeIntent!.secret, appearance: {}}); 
    }
    },[stripeIntent])

    useEffect(() => {
        if(currentShop){
            if(!stripePromise)
                setStripePromise(loadStripe(currentShop!.cardKey));
        }
    },[currentShop, stripePromise])

    if(!stripeOptions)
        return <p>Loading</p>;
     return (
    <Elements stripe={stripePromise} options={stripeOptions}>
        <StripeDialog />
    </Elements>

  )
});