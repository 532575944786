import React from "react";
import { observer } from "mobx-react-lite";
import { history } from "../../";

export const ProfileFooter = observer(() => {

  return (
    <div className="common-footer">
        <div className="common-footer-buttons">
        <button onClick={() => history.push("/order")} className="btn-medium btn-cancel mr1r">Cancel</button>
        <button type="submit" className="btn-medium btn-bottom-toolbar">
          Save
        </button>
      </div>
    </div>
  );
});
