import React, { useContext } from "react";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { history } from "../../";

export const NewCustomerFooter = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { totalPieces, totalGrossAmount } = rootStore.orderStore;
  const { user } = rootStore.userStore;
  return (
    <div className="products-footer">
      <div className="products-totals">
        <div className="products-total-qty">
          <label>Total Pieces: </label>
          <label>{totalPieces}</label>
        </div>
        <div className="products-total-amt">
          <label>Total Amount: </label>
          <label>
            &pound;
            {totalGrossAmount < user!.minOrderValue
              ? user!.minOrderValue.toFixed(2)
              : totalGrossAmount.toFixed(2)}
            &nbsp;
          </label>
        </div>
      </div>
      <div className="basket-footer-buttons">
        <button
          onClick={() => history.push("/order")}
          className="btn-medium btn-cancel"
        >
          Back to Item Selection
        </button>
        <button type="submit" className="btn-medium btn-bottom-toolbar">
          Next
        </button>
      </div>
      <div style={{ clear: "both" }}></div>
    </div>
  );
});
