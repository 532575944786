import React, { useContext } from "react";
import { Navbar } from "./Navbar";
import { RootStoreContext } from "../../stores/rootStore";
import { Link } from "react-router-dom";
export const Header = () => {
  const rootStore = useContext(RootStoreContext);
  const { currentShop } = rootStore.commonStore;
  return (
    <header>
      <div className="navbar-brand">
        <Link className="navbar-logo" to="/order">
          <img
            src={ currentShop?.logoPath ? `${currentShop?.appDocUrl}/Logo/${currentShop?.logoPath}` : "/images/logo-v.png"}
            alt={currentShop?.shopName || "My Drycleaner"}
          />
        </Link>
        <h1 className="logo-title">
          {currentShop?.showCompanyName && (
          currentShop?.shopName || "My Drycleaner")}
        </h1>
      </div>
      <input type="checkbox" id="navbar-toggler" className="navbar-toggler" />
      <Navbar />
      <label htmlFor="navbar-toggler" className="navbar-hamburger">
        <span></span>
      </label>
    </header>
  );
};
