import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/rootStore";
interface IProps {
  day: Date;
  dayName: string;
  selectedDay: Date | undefined;
  setSelectedDay: (day: Date) => void;
}
export const CollectionTimeSlotHeaderButton: React.FC<IProps> = observer(
  ({ day, dayName, selectedDay, setSelectedDay }) => {
    const rootStore = useContext(RootStoreContext);
    const {
      collectionSlotsAvailable,
    } = rootStore.timeSlotStore;
    const [dayNo, setDayNo] = useState(0);
    const [enabled, setEnabled] = useState(true);
    useEffect(() => {
      if (day) {
        setEnabled(collectionSlotsAvailable(day));
        setDayNo(new Date(day!).getDate());
      }
    }, [day, collectionSlotsAvailable]);
    return (
      <div
        className={
          selectedDay === day
            ? "timeslot-date timeslot-date-selected"
            : enabled
            ? "timeslot-date"
            : "timeslot-date timeslot-disabled"
        }
        onClick={() => {
          if (selectedDay !== day && enabled) setSelectedDay(day);
        }}
      >
        <span>{dayName}</span>
        <span>{dayNo}</span>
      </div>
    );
  }
);
