import { RootStore } from "./rootStore";
import { observable, computed, action, runInAction } from "mobx";
import { IUser, ILogin, IGuest, ICustomer, ICustomerForm, IResetPassword, IForgotPassword, ICustomerProfile } from "../models/user";
import agent from "../api/agent";
import { history } from "../";
import { MD5 } from "crypto-js";
import { ICustomerCard, INewCard, INewStripeCard } from "../models/cards";
import { IApiResponse, IApiResponseT } from "../models/apiresponse";
import { IVoucherListItem } from "../models/voucher";
import { IPriceMatrixItem } from "../models/category";
import { IStripeIntent } from "../models/stripe_models";
export default class UserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable user: IUser | null = null;
  @observable customer: ICustomer | null = null;
  @observable customerCards: ICustomerCard[] = [];
  @observable showLogin: boolean = false;
  @observable showForgotPassword: boolean = false;
  @observable defaultCardUID: string = "";
  @observable showCardDialog: boolean = false;
  @observable savingCard: boolean = false;
  @observable cardSaveError: string = "";
  @observable passwordVerified: boolean = false;
  @observable loadingMyOffers: boolean = false;
  @observable myOffers: IVoucherListItem[] = [];
  @observable loadingMyPriceGrid: boolean = false;
  @observable myPriceGrid: IPriceMatrixItem[] = [];
  @observable stripeIntent: IStripeIntent | undefined;

  @action setPasswordVerified = (value: boolean) => {
    this.passwordVerified = value;
  }
  @action setCardSaveError = (error: string) => {
    this.cardSaveError = error;
  }
  @action setDefaultCard = (card: ICustomerCard) => {
    this.customerCards.forEach((c) =>{
      if(c.uniqueRecId === card.uniqueRecId){
        runInAction(() =>{
          this.defaultCardUID = card.uniqueRecId;
          card.isDefault = true;
        })
      }
      else
      runInAction(() => card.isDefault = false );
    })
  }
  @computed get isLoggedIn() {
    return !!this.user;
  }
  @computed get isCustomer() {
    return this.user ? this.user?.isCustomer : false;
  }
  @action setShowLogin = (value: boolean) => {
    this.showLogin = value;
  }
  @action showForgotPasswordDialog = () =>{
    this.showForgotPassword = true;
  }
  @action hideForgotPasswordDialog = () =>{
    this.showForgotPassword = false;
  }
  @action showNewCardDialog = () => {
    this.showCardDialog = true;
  } 
  @action hideNewCardDialog = () =>{
    this.showCardDialog = false;
  }
  @action login = async (values: ILogin) => {
    try {
      const user = await agent.User.login(values);
      if(user.isAllowed){
        if(!user.resetKey){
          runInAction(() => {
            this.user = user;
          });
          this.setShowLogin(false);
          this.rootStore.commonStore.setToken(user.token);
          if (!this.rootStore.commonStore.prevRoute){
            if(this.rootStore.orderStore.orderDetails.length > 0)
              this.rootStore.orderStore.reCalculatePrices();
            else
              if (this.rootStore.commonStore.currentShop?.itemSelectionOptions === 0)
                history.push("/basket");
              else
                history.push("/order");
          }
          else {
            switch(this.rootStore.commonStore.prevRoute){
              case '/order': 
                history.push("/order");
                break;
              case '/newcustomer':
                history.push("/basket");
                break;
              case '/basket':
                history.push("/basket");
                break;
            }
          }
        } else {
          /// Reset Key
          history.push(`/resetpassword?key=${user.resetKey}&message=${user.errorMessage}`)
        }
      } else {
        // Not Allowed
        throw({notAllowed: true, error: user.errorMessage})
      }
    } catch (error) {
      throw error;
    }
  };

  @action logout = () => {
    this.rootStore.commonStore.setToken(null);
    this.user = null;
    this.customer = null;
    this.rootStore.orderStore.clearOrderDetails();
    this.rootStore.orderStore.clearOrder();
    this.rootStore.orderStore.clearBasketValues();
    this.rootStore.timeSlotStore.clearTimeslots();
    this.rootStore.commonStore.setPrevRoute("");
    history.push(`/${this.rootStore.commonStore.shopUID}`);
  };

  @action signIn = () => {
    if(window.location.pathname.toString() !== "/resetpassword" && window.location.pathname.toString() !== "/newcustomer")
      this.rootStore.commonStore.setPrevRoute(window.location.pathname.toString());
    this.rootStore.commonStore.setToken(null);
    this.user = null;
    this.customer = null;
    this.showLogin = true;
    history.push(`/${this.rootStore.commonStore.shopUID}`);
  }

  @action guest = async (values: IGuest) => {
    try {
      const user = await agent.User.guest(values);
      runInAction(() => {
        this.user = user;
      });
      if(user){
      this.rootStore.commonStore.setToken(user.token);
      this.rootStore.orderStore.clearOrderDetails();
      this.rootStore.orderStore.clearOrder();
      this.rootStore.orderStore.clearBasketValues();
      this.rootStore.timeSlotStore.clearTimeslots();
      if (this.rootStore.commonStore.currentShop?.itemSelectionOptions === 0) {
        history.push("/basket");
        this.rootStore.commonStore.setPrevRoute("/basket");
      } else {
        history.push("/order");
        this.rootStore.commonStore.setPrevRoute("/order");
      }
      }
      else
        throw "Sorry, we don't cover your postcode yet!"
        
    } catch (error) {
      throw error;
    }
  };

  @action getUser = async () => {
    try {
      const user = await agent.User.current();
      runInAction(() => {
        this.user = user;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action customerInfo = async () => {
    try {
      const customer = await agent.Customer.customer();
      runInAction(() => {
        this.customer = customer;
      });
    } catch (error) {
      runInAction(() => (this.customer = null));
    }
  };
  @action saveCustomer = async (values: ICustomerForm) => {
    try {
          agent.Customer.savecustomer({
          accountCode: "",
          title: "",
          firstName: values.firstName,
          middleName: values.middleName,
          surname: values.surname,
          address1: values.address1,
          address2: values.address2,
          address3: values.address3,
          address4: values.address4,
          postCode: values.postCode,
          mobileNo: `0${values.mobileNo.substr(-10,10)}`,
          emailId: values.emailId,
          password: MD5(values.password).toString().toUpperCase(),
          deliveryNotes: values.deliveryNotes,
          membershipType: '',
          cardCustomerId: ''
        }).then((user) => {
          runInAction(() => {
            this.user = user;
          });
          this.rootStore.commonStore.setToken(null);
          this.rootStore.commonStore.setToken(user.token);
        });
    } catch (error) {
      throw error;
    }
  };

  @action updateCustomer =  async (customer: ICustomerProfile) => {
    agent.Customer.updatecustomer(customer).then((res) => {
      if(res){
        this.updateDefaultCustomerCard()
        this.customerInfo();
      }
    });
  }

  @action verifyCustomer = async (mobileNo: string, emailId:string) : Promise<boolean> =>{
    return await agent.Customer.verifycustomer(mobileNo,emailId)
  }

  @action verifyPassword = async(password: string) : Promise<boolean> => {
    return await agent.User.verifypassword(password); 
  }

  @action doForgotPassword = async (values: IForgotPassword) : Promise<IApiResponse> => {
    try{
      return await agent.User.forgotpassword(values);
    } catch(error){
        if(error) 
          return {
            status: 0,
            error: {
              code: 101,
              description: "The email id is not registered. Please enter your registered email id."
            }
          }; 
        throw error;}
  }

  @action resetPassword = async (values: IResetPassword) : Promise<IApiResponse> => {
    try{
      return await agent.User.resetpassword(values);
    } catch(error) { return {
            status: 0,
            error: {
              code: 500,
              description: "An error occured while resetting your password"
            }
           }
    }
  }
  
  @action createStripeIntent = async () => {
    this.savingCard = true;
    agent.CustomerCards.createstripeintent()
              .then((response) => {
                if(response.status === 1 ){
                  runInAction(() => {
                    this.stripeIntent = response.result;
                    this.savingCard = false;
                  })
                if(this.customer!.cardCustomerId === ''){
                  this.customerInfo();
                }
                }
                else {
                  runInAction(() => {
                            this.savingCard = false;
                            this.cardSaveError = response.error.description;
                      })
                } 
              })
  }

  @action cancelStripeIntent = async () => {
    if(this.stripeIntent)
      await agent.CustomerCards.cancelstripeintent(this.stripeIntent.id);
  }

  @action saveNewStripeCard = async (id: string) => {
    this.savingCard = true;
    var card: INewStripeCard = { id };
    await agent.CustomerCards.addnewstripecard(card).then((response:IApiResponseT<ICustomerCard>) =>{
    if(response.status === 1){
        runInAction(() => {
          this.customerCards = [...this.customerCards,response.result];
          this.cardSaveError = "";
          this.savingCard = false;
          this.hideNewCardDialog();
        })
        this.setDefaultCard(response.result);
      }
      else{
        runInAction(() => {
          this.stripeIntent = undefined;
          this.savingCard = false;
          if(response.error)
          this.cardSaveError = response.error.description;
        })
      }
    })  
  }

  @action getCustomerCards = async () => {
    
    agent.CustomerCards.customercards().then((cards:ICustomerCard[]) => {
      runInAction(() =>{
        this.customerCards = cards;
        if(cards.length > 0){
          var card = cards.find(card => card.isDefault === true);
          if(card)
            this.defaultCardUID = card.uniqueRecId;
        }
      })
    } );
  }

  @action saveNewCard = async (card: INewCard) => {
    this.savingCard = true;
    agent.CustomerCards.addnewcard(card).then((response:IApiResponseT<ICustomerCard>) =>{
      if(response.status === 1){
        runInAction(() => {
          this.customerCards = [...this.customerCards,response.result];
          this.cardSaveError = "";
          this.savingCard = false;
          this.hideNewCardDialog();
        })
        this.setDefaultCard(response.result);
      }
      else{
        runInAction(() => {
          this.savingCard = false;
          this.cardSaveError = response.error.description;
        })
      }
    })
  }

  @action updateDefaultCustomerCard = async () => {
    var card = this.customerCards.filter(c => c.uniqueRecId === this.defaultCardUID)[0];
    if(card)
      agent.CustomerCards.setdefaultcard(card);
  }

  @action deactivateCustomerCard = async (card: ICustomerCard) => {
    const cardUID = card.uniqueRecId;
    await agent.CustomerCards.deactivatecard(card).then(()=>{
      runInAction(() => {
          if(cardUID === this.defaultCardUID)    
            this.defaultCardUID = "";
          this.customerCards = [...this.customerCards.filter(c => c.uniqueRecId !== card.uniqueRecId)];
          this.cardSaveError = "";
          this.savingCard = false;
        }
    )
  })
}
@action loadMyOffers = async () => {
  this.loadingMyOffers = true;
  await agent.Customer.myoffers().then((offers) => {
    runInAction(() =>{
      this.myOffers = offers;
      this.loadingMyOffers = false;
    })
  })
}

@action loadMyPriceGrid = async () => {
  this.loadingMyPriceGrid = true;
  await agent.Customer.myspecialprice().then((pricematrix) => {
    runInAction(()=>{
      this.myPriceGrid = pricematrix;
      this.loadingMyPriceGrid = false;
    })
  })
}

}
