import React, { useContext, useEffect } from "react";
import { Header } from "../components/Layout/Header";
import { Footer } from "../components/Layout/Footer";
import { RootStoreContext } from "../stores/rootStore";
import { observer } from "mobx-react-lite";
import { LoadingComponent } from "../components/Common/LoadingComponent";
import { FAQSection } from "../components/Document/FAQSection";

export const FAQsPage = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { setPrevRoute, faqs, loadingDocument, loadFAQs } = rootStore.commonStore;

useEffect(() =>{
  setPrevRoute(window.location.pathname);
  loadFAQs();
},[])

if (loadingDocument || !faqs)
   return <LoadingComponent message="Loading page..." />;
  return (
    <>
      <Header />
      <main>
        <div className="faq-wrapper">
          <div className="document-container">
            {faqs.sections.map(section => (
                <FAQSection section={section} />
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
});