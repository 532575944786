import React, { useContext, useEffect } from "react";
import { Header } from "../components/Layout/Header";
import { Toolbar } from "../components/Toolbar/Toolbar";
import { ProductList } from "../components/Products/ProductList";
import { Footer } from "../components/Layout/Footer";
import { ProductsFooter } from "../components/Products/ProductFooter";
import { RootStoreContext } from "../stores/rootStore";
import { confirmAlert } from "react-confirm-alert";
import { history } from "../";
import BasketDialog from "../components/Basket/BasketDialog";
import { observer } from "mobx-react-lite";

export const OrderBookingPage = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { loadCategories } = rootStore.categoryStore;
  const { setPrevRoute } = rootStore.commonStore;
  const {orderDetails, resetOrder, currentOrder, showBasket, setShowBasket } = rootStore.orderStore;
  const { user } = rootStore.userStore;
  useEffect(()=>{
    setPrevRoute(window.location.pathname);
  },[])
  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

    const handleCheckout = () => {
    if(orderDetails.length === 0){
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className='dialog-box topmost'>
                      <div className="dialog-title">
                        <h2>Confirm No Item Selection</h2>
                      </div>
                      <div className="dialog-body">
                        <p>There are no items in your basket, Please select the items or select Skip Item Selection</p>
                      </div>
                      <div className="dialog-footer">
                      <button className="btn btn-default" onClick={onClose}>Ok</button>
                      </div>
                    </div>
                  );
                }
              });
          }
          else{
            setShowBasket(false);
            if(user?.isCustomer)
              history.push("/basket");
            else
              history.push("/newcustomer");
          }
  }
  const handleDiscardChanges = () => {
    if((currentOrder && currentOrder?.orderNo > 0) || orderDetails.length > 0){
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className='dialog-box'>
                      <div className="dialog-title">
                        <h2>Confirm Cancel Order</h2>
                      </div>
                      <div className="dialog-body">
                        {currentOrder && currentOrder?.orderNo > 0 ? (
                          <p>Do you want to cancel the changes you have made to this order?</p>
                        ) : (
                          <p>Do you want to cancel the order?</p>
                        )}
                      </div>
                      <div className="dialog-footer">
                      <button className="btn btn-cancel" onClick={onClose}>No</button>
                      <button className="btn btn-default" onClick={() => {resetOrder();setShowBasket(false);onClose();}}>Yes</button>
                      </div>
                    </div>
                  );
                }
              });
          }
          else
            setShowBasket(false);
  }
  return (
    <>
      <Header />
      <main>
        <Toolbar />
        <ProductList />
        { showBasket && <BasketDialog showDialog={showBasket} hideDialog={() => (setShowBasket(false))} handleCheckOut={handleCheckout} handleDiscardChanges={handleDiscardChanges} />}
      </main>
      <Footer>
        <ProductsFooter />
      </Footer>
    </>
  );
});
