import React, { useState, useContext, useEffect } from "react";
import { ITimeSlot } from "../../../models/order";
import { Dialog } from "../../Common/Dialog";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/rootStore";
import { DeliveryTimeSlotHeader } from "./DeliveryTimeSlotHeader";
import { DeliveryTimeSlotGrid } from "./DeliveryTimeSlotGrid";

interface IProps {
  showDialog: boolean;
  hideDialog: () => void;
  setTimeSlot: (value: ITimeSlot) => void;
}
export const DeliveryTimeSlotDialog: React.FC<IProps> = observer(
  ({ showDialog, hideDialog, setTimeSlot }) => {
    const [localTimeSlot, setLocalTimeSlot] = useState<ITimeSlot|undefined>();
    const [localSelectedDay, setLocalSelectedDay] = useState<Date|undefined>();
    const rootStore = useContext(RootStoreContext);
    const {
      defaultDelivery,
      loadingDeliverySlots,
    } = rootStore.timeSlotStore;

    useEffect(() => {
      setLocalTimeSlot(defaultDelivery);
    }, []);

    useEffect(()=>{
      if(localTimeSlot){
        setLocalSelectedDay(localTimeSlot.slotDate);
      }
    },[localTimeSlot])
    const saveTimeSlot = () => {
      setTimeSlot(localTimeSlot!);
      hideDialog();
    };

    const handleSelectDay = (day: Date) => {
      setLocalSelectedDay(day);
      setLocalTimeSlot(undefined);
    }
    return (
      <Dialog
        title="Delivery Slots"
        showDialog={showDialog}
        hideDialog={hideDialog}
        saveData={saveTimeSlot}
        saveDisabled={!localSelectedDay || !localTimeSlot}
        hasScroll={true}
      >
        {(loadingDeliverySlots) ? (
          <div>
              <span className="input-error-big">Loading slot...</span>
            </div>
        ) : (
          <>
            <DeliveryTimeSlotHeader selectedDay={localSelectedDay} setSelectedDay={handleSelectDay} />
            <DeliveryTimeSlotGrid
              timeSlot={localTimeSlot!}
              day={localSelectedDay}
              setTimeSlot={setLocalTimeSlot}
            />
          </>
        ) }
      </Dialog>
    );
  }
);
