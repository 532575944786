import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { RootStoreContext } from '../../stores/rootStore'
import { supportFullAddress } from '../../utils/Utils';

export const SupportDetails = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const { supportDetails} = rootStore.commonStore;
    return (
    <div className="basket-personal-details">
      <h2 className="basket-heading">Contact Details</h2>
      <div className="contact-address-container">
        <div className="contact-company-name">
            <label>{supportDetails?.companyName}</label>
        </div>
        <div>
            <label>{supportFullAddress(supportDetails)}</label>
        </div>
      </div>
        <div className="contact-address-container">
            <div className="contact-address-field-wrapper">
                <div className="field-name">Email Id</div>
                <div>{supportDetails?.emailId}</div>
            </div>
            <div className="contact-address-field-wrapper">
                <div className="field-name">Phone No</div>
                <div>{supportDetails?.phoneNo}</div>
            </div>
        </div>
        <h3>Working Hours</h3>
        {supportDetails?.workingHours.map((wh) =>(
        <div key={wh.dayNo} >
            {wh.isClosed ? (
            <div className="working-hours-row">
                <div>{wh.dayName}</div>
                <div><strong>CLOSED</strong></div>
            </div>
            ) : (
            <div className="working-hours-row">
                        <div>{wh.dayName}</div>                        
                        <div>{`${wh.timeFrom} To ${wh.timeTo}`}</div>
            </div>
            )}
        </div>

        ))}
    </div>
    )
});
