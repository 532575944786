import React, { useState, useContext, useEffect } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { FaPen } from "react-icons/fa";
import { RootStoreContext } from "../../stores/rootStore";
import { IOrderDetail } from "../../models/order";
import { observer } from "mobx-react-lite";
import { IAlterationValues } from "../../models/category";
import { FORM_ERROR } from "final-form";
import { AlterationsDuplicateAlertDialog } from "./AlterationsDuplicateAlertDialog";

interface IProps {
  alterations: IOrderDetail[];
  setAlterations: (alterations: IOrderDetail[]) => void;
}
export const AlterationForm: React.FC<IProps> = observer(
  ({ alterations, setAlterations }) => {
    const rootStore = useContext(RootStoreContext);
    const {
      selectedItem,
      alterMainCategories,
      alterSubCategories,
      loadingAlterCategories,
      loadingAlterations,
      loadAlterCategories,
      loadAlterations,
      selectedMainLineNo,
      selectedAlterCategory,
      selectedAlteration,
      setSelectedAlterCategory,
      setSelectedAlteration,
      resetAlterations,
      alterationsType
    } = rootStore.categoryStore;
    const { mainLineNo, setTurnAroundTime } = rootStore.orderStore;
    const [alterValues, setAlterValues] = useState({
      alterType: 1,
      mainCategory: "",
      subCategory: "",
      price: 0,
      notes: "",
    });
    const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);
    const [duplicateRepair,setDuplicateRepair] = useState("");
    const [lineNo, setLineNo] = useState(selectedMainLineNo > 0 ? selectedMainLineNo * 100 : mainLineNo * 100);
    const [mainOrderDetail, setMainOrderDetail] = useState<IOrderDetail | undefined>();
    useEffect(() => {
      if (selectedItem) {
        loadAlterCategories(selectedItem!.mcUniques);
      }
      return () => {
        resetAlterations();
      }
    }, [selectedItem]);
    useEffect(() => {
      if (alterMainCategories.length === 1) 
        setSelectedAlterCategory(alterMainCategories[0]);
      else {
        setSelectedAlterCategory(undefined);
        setSelectedAlteration(undefined);
        loadAlterations(undefined);
      }
    }, [alterMainCategories, setSelectedAlterCategory]);

    useEffect(() => {
      if (selectedAlterCategory) 
        loadAlterations(selectedAlterCategory.mcUnique);
    },[selectedAlterCategory, loadAlterations]);

    useEffect(() => {
      if (alterSubCategories.length === 1) {
        setSelectedAlteration(alterSubCategories[0]);
      }
    }, [alterSubCategories, setSelectedAlteration]);
    useEffect(() => {
      if (selectedAlteration) {
        const { pmPrice, itemName } = selectedAlteration;
        setAlterValues({
          ...alterValues,
          price: pmPrice,
          subCategory: itemName,
          mainCategory: selectedAlterCategory?.groupName || "",
        });
        }
    }, [selectedAlteration]);
    useEffect(() => {
      if(alterations.length > 0 && selectedMainLineNo > 0){
        var mainOD = alterations.find(a => a.mainLineNo === selectedMainLineNo && a.alteration === "A");
        setMainOrderDetail(mainOD);
        if(mainOD){
          setAlterValues({
            ...alterValues,
            alterType: mainOD.alterationCode === 5 ? 2 : 1
          })
        }
      }
    },[alterations,selectedMainLineNo, setMainOrderDetail])
    const hideAltert = () => {
      setShowDuplicateAlert(false);
      setAlterValues({
        alterType: 1,
        mainCategory: alterMainCategories.length === 1 ? alterMainCategories[0].groupName : "Select an Item",
        subCategory: alterSubCategories.length === 1 ? alterSubCategories[0].groupName : "Select A Repair",
        price: 0,
        notes: "",
      });
      setSelectedAlteration(undefined);
      setDuplicateRepair("");
    }

    const handleAlterCategoryChange = (name: string) => {
      let cat = alterMainCategories.find(c => c.groupName === name);
      setSelectedAlterCategory(cat);
      if(cat){
        setAlterValues({...alterValues, mainCategory: cat.groupName})
      } else {
        setAlterValues({...alterValues, mainCategory: '', subCategory: '', price: 0})
      }
    }
    const handleAlterationChange = (name: string) => {
      let alter = alterSubCategories.find(a => a.itemName === name);
      setSelectedAlteration(alter);
      if(alter){
        setAlterValues({...alterValues, subCategory: alter.itemName, price: alter.scPrice})
      } else {
        setAlterValues({...alterValues, subCategory: '', price: 0})
      }
    }

    const isDisabled = () : boolean => {
      return (!selectedAlterCategory || !selectedAlteration);  
    }
    const handleFormSubmit = ({
      mainCategory,
      subCategory,
      price,
      notes,
    }: IAlterationValues) => {
      if (
        alterations.some((a) =>
            a.mainCategory === mainCategory && a.subCategory === subCategory
        )) {
          setDuplicateRepair(subCategory);
          setShowDuplicateAlert(true);
      } else {
        saveAlterations({
      mainCategory,
      subCategory,
      price,
      notes,
    })
      }

    }
    const saveAlterations = ({
      mainCategory,
      subCategory,
      price,
      notes,
    }: IAlterationValues) => {
      
      if(mainCategory === "" || mainCategory === "Select an Item" || price === 0 || subCategory === "" || subCategory === "Select a Repair")
      return {
          [FORM_ERROR]: `Cannot insert blank values!`,
        };
      let alter: IOrderDetail[] = [];
      let ln = lineNo;
        if(selectedMainLineNo === 0){
          ln = mainLineNo*100;
        } else {
          ln = selectedMainLineNo * 100;
        }
        alterations.forEach(a => {
            if(a.lineNo > ln) ln = a.lineNo
          })
        setLineNo(ln);
        if (alterations.length === 0) {
          alter.push({
            mainLineNo: mainLineNo,
            lineNo: ln,
            mainCategory: selectedItem!.groupName,
            subCategory: selectedItem!.itemName,
            scUnique: selectedItem!.scUnique,
            stkCode: selectedItem!.stkCode,
            discountQty: 0,
            quantity: 1,
            pieces: selectedItem!.pieces,
            price: alterationsType === 1 ? selectedItem!.scPrice : 0,
            lineTotal: alterationsType === 1 ? selectedItem!.scPrice : 0,
            alterationCode: alterationsType === 1 ? 0 : 5,
            alteration: "A",
            itemType: "I",
            collectionCharges: 0,
            deliveryCharges: 0,
            discountType: alterationsType === 1 ?
              (selectedItem!.discount > 0
                ? `PR::0:${selectedItem?.pmPrice}`
                : "") : "",
            discount: alterationsType === 1 ? selectedItem!.discount : 0,
            headerDiscount: 0,
            notes: "",
          });
        }
        alter.push({
          mainLineNo: selectedMainLineNo > 0 ? selectedMainLineNo : mainLineNo,
          lineNo: ++ln,
          mainCategory: mainCategory,
          subCategory: subCategory,
          scUnique: selectedAlteration!.scUnique,
          stkCode: selectedAlteration!.stkCode,
          discountQty: 0,
          quantity: 1,
          pieces: 0, //selectedItem!.pieces,
          price: price,
          lineTotal: selectedAlteration!.scPrice,
          alterationCode: 0,
          alteration: "",
          itemType: "S",
          collectionCharges: 0,
          deliveryCharges: 0,
          discountType: "",
          discount: 0,
          headerDiscount: 0,
          notes: notes,
        });
        setAlterations([...alterations, ...alter]);
        setLineNo(ln);
        loadAlterations(selectedAlterCategory?.mcUnique)
        if (alterMainCategories.length === 1) {
          setSelectedAlterCategory(alterMainCategories[0]);
        }
        setAlterValues({
          alterType: 1,
      mainCategory: alterMainCategories.length === 1 ? alterMainCategories[0].groupName : "Select an Item",
      subCategory: alterSubCategories.length === 1 ? alterSubCategories[0].groupName : "Select A Repair",
      price: 0,
      notes: "",
    });
      setSelectedAlteration(undefined);
      setTurnAroundTime(selectedItem!.turnAroundTime);
    };
    return (
      <>
      <FinalForm
        initialValues={alterValues}
        mutators={
          {
            setValue: ([field,value], state, utils) => {
              utils.changeValue(state,field, () => value);
            }
          }
        }
        onSubmit={handleFormSubmit}
        render={({ form, handleSubmit, submitError, dirtySinceLastSubmit}) => {
          // @ts-ignore
          window.globalThis.form = window.globalThis.form ?? form;
        return (
          <form onSubmit={handleSubmit}>
            <Field name="mainCategory" type="select">
              {(input) => (
                <div className="input-form-group">
                  <label className="input-label" htmlFor="mainCategory">
                    Item
                  </label>
                  <select
                    className="input-form-field"
                    {...input}
                     value={alterValues.mainCategory}
                    onChange={(e) => {
                      handleAlterCategoryChange(e.target.value);
                    }}
                  >
                    {!loadingAlterCategories ? (
                      <>
                      { alterMainCategories.length > 1 && (
                        <option key={0}>Select an Item</option>
                      )}
                      {alterMainCategories.map((cat) => (
                          <option key={cat.uniqueRecID}>{cat.groupName}</option>                      
                      ))}
                      </>
                    ) : (
                      <option key={0}>Loading...</option>
                    )}
                  </select>
                </div>
              )}
            </Field>
            <Field name="subCategory" type="select">
              {(input) => (
                <div className="input-form-group">
                  <label className="input-label" htmlFor="subCategory">
                    Repair
                  </label>
                  <select
                    className="input-form-field"
                    {...input}
                     value={alterValues.subCategory}
                    onChange={(e) => {
                      handleAlterationChange(e.target.value);
                    }}
                  >{!loadingAlterations ? (
                    <>
                      { (alterSubCategories.length > 1) && (
                      <option key={0}>Select a Repair</option>
                      ) }
                      {alterSubCategories.map((alt) => (
                        <option key={alt.uniqueRecID}>{alt.itemName}</option>
                      ))}
                    </>
                  ) : (
                      <option key={0}>Loading...</option>
                  )}
                  </select>
                </div>
              )}
            </Field>
            <Field name="price" value={alterValues.price}>
              {(input) => (
                <div className="input-form-group">
                  <label className="input-label" htmlFor="price">
                    Price
                  </label>
                  <span className="input-form-field">
                    &pound;&nbsp;
                    {selectedAlteration?.scPrice?.toFixed(2) ?? 0.0}
                  </span>
                  <input
                    type="hidden"
                    {...input}
                    value={selectedAlteration ?  selectedAlteration.scPrice?.toFixed(2) : 0.0 }
                  />
                </div>
              )}
            </Field>

            <Field name="notes" placeholder="Any comments or notes" value={alterValues.notes}>
              {(input) => (
                <div className="input-group">
                  <div className="input-icon">
                    <FaPen />
                  </div>
                  <input
                    className="input-field"
                    value={alterValues.notes}
                    {...input}
                    onChange={(e) =>
                      setAlterValues({ ...alterValues, notes: e.target.value })
                    }
                  />
                </div>
              )}
            </Field>
            {submitError && !dirtySinceLastSubmit && (
              <span className="input-error">{submitError}</span>
            )}
            <div className="input-button">
              <button disabled={isDisabled()} className='btn btn-default' type="submit">
                Add Alteration
              </button>
            </div>
          </form>
        )}}
      />
    {(showDuplicateAlert && duplicateRepair) && (<AlterationsDuplicateAlertDialog saveData={() => {setShowDuplicateAlert(false); saveAlterations(alterValues);}} showDialog={showDuplicateAlert} hideDialog={hideAltert} repair={duplicateRepair!} />)}
    </>
    );
  }
);
