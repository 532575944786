import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { history } from "../../";
import { RootStoreContext } from "../../stores/rootStore";

export const BasketFooter = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { currentShop } = rootStore.commonStore;
  const { user } = rootStore.userStore;
  const { currentOrder } = rootStore.orderStore;
  return (
    <div className="products-footer">
      <div style={{clear: "both"}}>
      </div>
        <div className="basket-footer-buttons">
            {(currentShop?.itemSelectionOptions === 0 || user?.noItemSelect) ? (
              <></>
            ) : (
              <>
              <button onClick={() => history.push("/order")} className="btn-medium btn-cancel mt1r">Back</button>
              {currentOrder && currentOrder.orderNo > 0 && (
                <span>{`Changing Order No ${currentOrder.orderNo}`}</span>
              )}
              </>
            )}
            <button type="submit" className="btn-medium btn-bottom-toolbar mt1r">Next</button>
        </div>
    </div>

  );
});
