import React, { useContext } from "react";
import { RootStoreContext } from "../stores/rootStore";
import { observer } from "mobx-react-lite";
import { ForgotPasswordBox } from "../components/login/ForgotPasswordBox";

export const ForgotPasswordPage = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const {
    currentShop
  } = rootStore.commonStore;
     
  return (
    <main className="login-wrapper">
      <div className="login-container">
        <div className="logo">
          <img
            src={ currentShop?.logoPath ? `${currentShop?.appDocUrl}/Logo/${currentShop?.logoPath}` : "/images/logo-v.png"}
            alt={currentShop?.shopName || "My Drycleaner"}
          />
          <h1 className="logo-title">
           {currentShop?.shopName || "My Drycleaner"}
          </h1>
        </div>
        <ForgotPasswordBox />
      </div>
    </main>
  );
});
