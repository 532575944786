import { observer } from 'mobx-react-lite'
import React from 'react'
import { Field } from 'react-final-form';
interface IProps{
    setSubmitted: (value: boolean) => void;
}
export const ContactUsForm : React.FC<IProps> = observer(({setSubmitted}) => {
    return (
    <div className="basket-personal-details">
      <h2 className="basket-heading">Contact Us</h2>
      <Field name="firstName">
          {({ input, meta }) => (
            <>
            <div className="basket-field-group">
              <label className="basket-field-group-label">First Name</label>
              <input
                className="basket-field-group-input"
                type="text"
                placeholder="First Name"
                {...input}
              />
            </div>
                {meta.touched && (meta.error || meta.submitError) && (
                <span className="input-error contact-form-group-error">
                  {meta.error || meta.submitError}
                </span>
              )}

            </>
          )}
        </Field>
        <Field name="surname">
          {({ input, meta }) => (
            <>
            <div className="basket-field-group">
              <label className="basket-field-group-label">Surname</label>
              <input
                className="basket-field-group-input"
                type="text"
                placeholder="Surname"
                {...input}
              />
             
            </div>
                          {meta.touched && (meta.error || meta.submitError) && (
                <span className="input-error contact-form-group-error">
                  {meta.error || meta.submitError}
                </span>
 )}
            </>
          )}
        </Field>
        <Field name="emailId">
        {({ input, meta }) => (
          <>
          <div className="basket-field-group">
            <label className="basket-field-group-label">Email Address</label>
            <input
              className="basket-field-group-input"
              type="email"
              placeholder="example@domain.com"
              {...input}
            />
            
          </div>
                      {meta.touched && (meta.error || meta.submitError) && (
              <span className="input-error contact-form-group-error">
                {meta.error || meta.submitError}
              </span>
)}
          </>
        )}
      </Field>
      <Field name="phoneNo">
        {({ input, meta }) => (
          <>
          <div className="basket-field-group">
            <label className="basket-field-group-label">Mobile No</label>
            <input
              className="basket-field-group-input"
              type="text"
              placeholder="Mobile Number"
              {...input}
            />
          </div>
                      {meta.touched && (meta.error || meta.submitError) && (
              <span className="input-error contact-form-group-error">
                {meta.error || meta.submitError}
              </span>
            )}

          </>
        )}
      </Field>
      <Field name="message">
          {({ input, meta }) => (
            <div className="basket-input-group">
              <label className="basket-input-label">Message</label>
              <textarea
                className="basket-input-field multiline-field"
                {...input}
                rows={5}
              ></textarea>
                            {meta.touched && (meta.error || meta.submitError) && (
                <span className="input-error">
                  {meta.error || meta.submitError}
                </span>
              )}

            </div>
          )}
        </Field>
         <button type="submit" className="btn-medium btn-default">
          Submit
        </button>
    </div>
    )
});
