import axios, { AxiosResponse } from "axios";
import { IUser, IGuest, ILogin, ICustomer, IResetPassword, IForgotPassword, ICustomerProfile } from "../models/user";
import { IShop } from "../models/shop";
import { IMainCategory, IPriceMatrixItem, ISubCategory } from "../models/category";
import { toast } from "react-toastify";
import { ITimeSlot, ITimeSlotsDTO, IOrder, IAppOrderEntity, IBundleOrderGroupEntity } from "../models/order";
import { IVoucher, IVoucherListItem } from "../models/voucher";
import { ICustomerCard, INewCard, INewStripeCard } from "../models/cards";
import { IApiResponse, IApiResponseT } from "../models/apiresponse";
import { IContactForm, ISupportDetails } from "../models/support";
import { IRegularVisit, IScheduledVisit } from "../models/visit";
import { history } from "../index";
import { useContext } from "react";
import { RootStoreContext } from "../stores/rootStore";
import { IDocument, IFAQs } from "../models/document";
import { IStripeIntent } from "../models/stripe_models";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(undefined, (error) => {
  if (error.message === "Network Error" && !error.response) {
    toast.error(
      "A network error occured. The server is either not responding or is down."
    );
  }
  const { status, headers } = error.response;
  if (status === 404 && error.response) {
    history.push("/");
    // toast.error("The resource you are trying to access cannot be found! (404)");
  }
  if(status === 401 && headers.has('Token-Expired')){
    toast.error("Your session has expired! Please login again to continue.");
    const rootStore = useContext(RootStoreContext);
    const { logout } = rootStore.userStore;
    logout();
  }

  /*if (
    status === 401 &&
    headers["www-authenticate"].includes(
      'Bearer error="invalid_token", error_description="The token expired at'
    )
  ) {
    window.localStorage.removeItem("jwt");
    history.push("/");
    console.log("Your session has expired, please login again.");
  }
  if (
    status === 400 &&
    config.method === "get" &&
    Object.prototype.hasOwnProperty.call(data.errors, "id")
  ) {
    // data.errors.hasOwnproperty("id") throws error so changed it
    history.push("/notfound");
  }
  if (status === 400 && config.method === "put") {
    console.log(error.response);
  }
*/

  if (status === 500) {
    toast.error("A server error occured. Please contact the administrator");
  }
  throw error.response;
});

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("mdc_jwt");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const responseBody = (response: AxiosResponse) => response.data;

const request = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) =>  axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody)
};

const Shop = {
  list: (uid: string): Promise<IShop[]> => request.get(`/shop/${uid}`),
  checkshopuid: (uid: string) : Promise<boolean> => request.get(`/shop/check/${uid}`)
};

const User = {
  current: (): Promise<IUser> => request.get("/user"),
  login: (user: ILogin): Promise<IUser> => request.post("/user/login", user),
  guest: (user: IGuest): Promise<IUser> => request.post("/user/guest", user),
  forgotpassword: (forgotPwd: IForgotPassword): Promise<IApiResponse> => request.post("/user/password/forgot", forgotPwd),
  resetpassword: (reset: IResetPassword): Promise<IApiResponse> => request.post("/user/password/reset", reset),
  verifypassword: (password: string): Promise<boolean> => request.get(`/user/verify/${encodeURIComponent(password)}`),
};

const Customer = {
  customer: (): Promise<ICustomer> => request.get("/customer"),
  savecustomer: (customer: ICustomer): Promise<IUser> => request.post("/customer/update", customer),
  updatecustomer: (customer: ICustomerProfile) : Promise<boolean> => request.post("/customer/profile", customer),
  verifycustomer: (mobileno: string, emailid: string): Promise<boolean> => request.get(`/customer/verify/${encodeURIComponent(mobileno)}/${encodeURIComponent(emailid)}`),
  myoffers: () : Promise<IVoucherListItem[]> => request.get("/customer/offers"),
  myspecialprice: () : Promise<IPriceMatrixItem[]> => request.get("/customer/pricematrix")
};

const CustomerCards = {
  customercards: (): Promise<ICustomerCard[]> => request.get("/customercards"),
  addnewcard: (card: INewCard): Promise<IApiResponseT<ICustomerCard>> => request.post("/customercards/new",card),
  setdefaultcard: (card: ICustomerCard) => request.post("/customercards/default",card),
  deactivatecard: (card: ICustomerCard) => request.post("/customercards/inactive",card),
  createstripeintent: (): Promise<IApiResponseT<IStripeIntent>> => request.get("/customercards/stripe/intent"),
  cancelstripeintent: (id: string): Promise<IApiResponse> => request.del(`/customercards/stripe/intent/cancel/${id}`),
  addnewstripecard: (card: INewStripeCard): Promise<IApiResponseT<ICustomerCard>> => request.post("/customercards/new/stripe",card)
}

const Category = {
  maincategory: (): Promise<IMainCategory[]> => request.get("/category"),
  subcategory: (id: string): Promise<ISubCategory[]> =>
    request.get(`/category/${id}`),
  altercategory: (id: string): Promise<IMainCategory[]> =>
    request.get(`/category/alter/${id}`),
  alterations: (id: number): Promise<ISubCategory[]> =>
    request.get(`/category/alter/sub/${id}`),
};

const TimeSlot = {
  defaultCollection: (
    orderDate: string,
    postCode: string
  ): Promise<ITimeSlot> =>
    request.get(
      `/timeslot/collection/${encodeURIComponent(
        postCode
      )}?OrderDate=${encodeURIComponent(orderDate)}`
    ),
  defaultDelivery: (
    collectionDate: string,
    postCode: string
  ): Promise<ITimeSlot> =>
    request.get(
      `/timeslot/delivery/${encodeURIComponent(
        postCode
      )}?Collectiondate=${encodeURIComponent(collectionDate)}`
    ),
  weeklyCollection: (
    fromDate: string,
    postCode: string
  ): Promise<ITimeSlotsDTO[]> =>
    request.get(
      `/timeslot/collection/week/${encodeURIComponent(
        postCode
      )}?OrderDate=${encodeURIComponent(fromDate)}`
    ),
  weeklyDelivery: (fromDate: string, postCode: string): Promise<ITimeSlotsDTO[]> =>
    request.get(
      `/timeslot/delivery/week/${encodeURIComponent(
        postCode
      )}?CollectionDate=${encodeURIComponent(fromDate)}`
    ),
  slotfordate: (forDate: string, postCode: string): Promise<ITimeSlot[]> => request.get(`/timeslot/${encodeURIComponent(
        postCode
      )}?ForDate=${encodeURIComponent(forDate)}`)
};

const Order = {
  saveOrder: (order: IOrder): Promise<number> => request.post("/order/new", order),
  updateOrder: (order: IOrder) : Promise<boolean> => request.post("/order/update", order),
  checkVoucher: (voucherCode: string, orderDate: string): Promise<IVoucher> => request.get(`/order/voucher/${encodeURIComponent(voucherCode)}?OrderDate=${encodeURIComponent(orderDate)}`),
  getOrder: (uid: string) : Promise<IOrder> => request.get(`/order/${uid}`),
  getUnPickedOrders: () : Promise<IAppOrderEntity[]> => request.get("/order/awaiting"),
  getPickedOrders: () : Promise<IAppOrderEntity[]> => request.get("/order/pickedup"),
  getOrdersInProcessing: () : Promise<IBundleOrderGroupEntity[]> => request.get("/order/processing"),
  getOrdersReady: () : Promise<IBundleOrderGroupEntity[]> => request.get("/order/ready"),
  getOrdersOnDelivery: () : Promise<IBundleOrderGroupEntity[]> => request.get("/order/ondelivery"),
  getOrdersDelivered: () : Promise<IBundleOrderGroupEntity[]> => request.get("/order/delivered"),
};

const Visit = {
  regular: () : Promise<IRegularVisit[]> => request.get("/visit/regular"),
  scheduled: () : Promise<IScheduledVisit[]> => request.get("/visit/scheduled"),
  updateSlot: (visitUniqueRecId: string, slotUniqueRecId: string): Promise<boolean> => request.put(`/visit/update/${visitUniqueRecId}/${slotUniqueRecId}`,{}),
  delete: (uniqueRecId: string) : Promise<boolean> => request.del(`/visit/${uniqueRecId}`),
  visitById: (uid: string) : Promise<ITimeSlot> => request.get(`/visit/${uid}`),
}

const Support = {
  supportdetails: (appKey: string, shopCode: string): Promise<ISupportDetails> => request.get(`/support/${shopCode}?appKey=${encodeURIComponent(appKey)}`),
  sendcontactform: (contactForm: IContactForm) : Promise<boolean> => request.post("/support/contact",contactForm)
};

const Document = {
  terms: () : Promise<IDocument> => request.get("/document/terms"),
  privacy: () : Promise<IDocument> => request.get("/document/privacy"),
  faqs: () : Promise<IFAQs> => request.get("/document/faqs")
}

export default { Shop, User, Customer, CustomerCards, Category, TimeSlot, Order, Visit, Support, Document };
