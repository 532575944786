import React, { useContext, useEffect } from "react";
import { Header } from "../components/Layout/Header";
import { Footer } from "../components/Layout/Footer";
import { RootStoreContext } from "../stores/rootStore";
import { observer } from "mobx-react-lite";
import { LoadingComponent } from "../components/Common/LoadingComponent";
export const InfoPage = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { message, setPrevRoute } = rootStore.commonStore;
  const { savingOrder } = rootStore.orderStore;
useEffect(() =>{
  setPrevRoute(window.location.pathname);
},[])
if (savingOrder)
   return <LoadingComponent message="Saving order" />;
  return (
    <>
      <Header />
      <main>
        <div className="basket-wrapper">
          <div className="message-container">
            { message && (<div dangerouslySetInnerHTML={{__html: message}}></div>)}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
});
