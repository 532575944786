import React from "react";

export const Loader: React.FC<{ message?: string }> = ({ message }) => {
  return (
    <div className="loader-wrapper">
      <p className="loader-message">{message}</p>
      <div className="loader"></div>
    </div>
  );
};
