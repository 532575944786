import React, { useContext } from "react";
import { RootStoreContext } from "../../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { getDateStringEx, getTimeString } from "../../../utils/Utils";
interface IProps {
  collectFromShop: boolean;
  handleClick: () => void;
}
export const DeliveryTimeSlotBox: React.FC<IProps> = observer(
  ({ handleClick, collectFromShop }) => {
    const rootStore = useContext(RootStoreContext);
    const {
      noDeliverySlot,
      defaultDelivery,
      loadingDeliverySlots
    } = rootStore.timeSlotStore;

    if (!defaultDelivery && !noDeliverySlot)
      return (
        <div className="delivery-slot-box">
        <h3 className="delivery-slot-heading">Delivery</h3>
            <div>
              <span className="input-error-big">Loading slots...</span>
            </div>
            <div>
              <span>&nbsp;</span>
            </div>
        </div>
      );
    return (
      <div
        className="delivery-slot-box"
        onClick={() => handleClick()}
      >
        <h3 className="delivery-slot-heading">{collectFromShop ? 'Ready By' : 'Delivery'}</h3>
        {noDeliverySlot ? (
          <>
            <div>
              <span className="input-error-big">No slots available</span>
            </div>
            <div>
              <span>&nbsp;</span>
            </div>
          </>
        ) : (
          <>
            <div>
            <div>
              <span>
                {getDateStringEx(defaultDelivery!.slotDate)}
              </span>
            </div>
            <div>
              <span>{`${getTimeString(defaultDelivery!.slotTimeFrom)}:00 - ${getTimeString(defaultDelivery!.slotTimeTo)}:00`}</span>
            </div>
            </div>
            <div className="delivery-slot-note">{loadingDeliverySlots ? "Loading slots..." : "Click to change date/time"}</div>
          </>
        )}
      </div>
    );
  }
);
