import { createContext } from "react";
import { configure } from "mobx";
import UserStore from "./userStore";
import CommonStore from "./commonStore";
import CategoryStore from "./categoryStore";
import OrderStore from "./orderStore";
import TimeSlotStore from "./timeSlotStore";

configure({ enforceActions: "always" });

export class RootStore {
  userStore: UserStore;
  commonStore: CommonStore;
  categoryStore: CategoryStore;
  orderStore: OrderStore;
  timeSlotStore: TimeSlotStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.commonStore = new CommonStore(this);
    this.categoryStore = new CategoryStore(this);
    this.orderStore = new OrderStore(this);
    this.timeSlotStore = new TimeSlotStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());
