import React, { useContext, useEffect } from "react";
import { Field } from "react-final-form";
import { ITimeSlot } from "../../models/order";
import { RootStoreContext } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";
import { addDays, addHours, formatDate } from "../../utils/Utils";
import { CollectionTimeSlotDialog } from "./CollectionTimeSlot/CollectionTimeSlotDialog";
import { DeliveryTimeSlotDialog } from "./DeliveryTimeSlot/DeliveryTimeSlotDialog";
import { CollectionTimeSlotBox } from "./CollectionTimeSlot/CollectionTimeSlotBox";
import { DeliveryTimeSlotBox } from "./DeliveryTimeSlot/DeliveryTimeSlotBox";

export const DeliveryDetails = observer(
  () => {
    const rootStore = useContext(RootStoreContext);
    const {
      defaultCollection,
      defaultDelivery,
      showCollectionTimeSlots,
      showDeliveryTimeSlots,
      setShowCollectionTimeSlots,
      setShowDeliveryTimeSlots,
      setDefaultCollection,
      setDefaultDelivery,
      getDefaultCollection,
      getDefaultDelivery,
      loadWeeklyCollectionSlots,
      loadingCollectionSlots,
      loadWeeklyDeliverySlots,
      loadingDeliverySlots,
    } = rootStore.timeSlotStore;
    const {
    basketValues, setBasketValues, currentOrder, turnAroundTime
  } = rootStore.orderStore;
  const { user } = rootStore.userStore;
  
  useEffect(() => {
    if (basketValues.postCode.length) 
      if(!defaultCollection){
          getDefaultCollection(new Date(), basketValues.postCode);
      }
  }, [defaultCollection, basketValues, currentOrder, getDefaultCollection]);

  useEffect(() => {
    if (defaultCollection && basketValues.postCode.length > 0) {
      if(!defaultDelivery || new Date(defaultDelivery.slotDate).getTime() < addDays(defaultCollection.slotDate,(user!.minTurnAround > turnAroundTime ? user!.minTurnAround : turnAroundTime)).getTime())
        getDefaultDelivery(defaultCollection!.slotDate, basketValues.postCode);
        loadWeeklyCollectionSlots(formatDate(addHours(new Date(),user!.minInterval)),basketValues.postCode);
    }
  }, [defaultCollection, basketValues, currentOrder, defaultDelivery, getDefaultDelivery, loadWeeklyCollectionSlots]);

  useEffect(() => {
    if (defaultCollection && defaultDelivery && basketValues.postCode.length > 0)
      loadWeeklyDeliverySlots(formatDate(addDays(defaultCollection.slotDate,(user!.minTurnAround > turnAroundTime ? user!.minTurnAround : turnAroundTime))),basketValues.postCode);
  }, [defaultDelivery, basketValues, loadWeeklyDeliverySlots]);


  const handleSetCollectionTimeSlot = (timeSlot: ITimeSlot) => {
    if(defaultCollection !== timeSlot){
      setDefaultCollection(timeSlot);
      if(!defaultDelivery || new Date(defaultDelivery.slotDate).getDate() < addDays(timeSlot.slotDate,user!.minTurnAround).getDate())
        getDefaultDelivery(timeSlot.slotDate, basketValues.postCode);
      loadWeeklyCollectionSlots(formatDate(addDays(timeSlot.slotDate,user!.minTurnAround)), basketValues.postCode)
      loadWeeklyDeliverySlots(formatDate(addDays(timeSlot.slotDate,(user!.minTurnAround > turnAroundTime ? user!.minTurnAround : turnAroundTime))),basketValues.postCode);
    }
  }
  
  const handleSetDeliveryTimeSlot = (timeSlot: ITimeSlot) => {
    setDefaultDelivery(timeSlot);
   };
    return (
      <div className="basket-delivery-details">
        <h2 className="basket-heading">Collection &amp; Delivery</h2>
        <div className="delivery-slots">
          <CollectionTimeSlotBox handleClick={()=> {if(!loadingCollectionSlots) setShowCollectionTimeSlots(true)}} />
          <DeliveryTimeSlotBox collectFromShop={basketValues.collectFromShop} handleClick={()=> {if(!loadingDeliverySlots) setShowDeliveryTimeSlots(true)}} />
        </div>
        <div className="basket-checkbox-wrapper">
          <Field name="collectFromShop" type="checkbox">
              {({input, meta}) => (
                <div className="basket-checkbox">
                  <label className="basket-checkbox-label">I will collect from shop
                    <input type="checkbox" {...input} onChange={(event)=> setBasketValues({...basketValues, collectFromShop: event.target.checked})} />
                    <span className="basket-checkbox-checkmark" />
                  </label>
                </div>
              )
              }
            </Field>
        </div>
        <Field name="deliveryNotes">
          {({ input, meta }) => (
            <div className="basket-input-group">
              {meta.touched && (meta.error || meta.submitError) && (
                <span className="input-error">
                  {meta.error || meta.submitError}
                </span>
              )}
              <label className="basket-input-label">Delivery Notes</label>
              <textarea
                className="basket-input-field multiline-field"
                {...input}
                rows={5}
                onChange={(event)=> setBasketValues({...basketValues, deliveryNotes: event.target.value})}
              ></textarea>
            </div>
          )}
        </Field>
        {showCollectionTimeSlots && (
          <CollectionTimeSlotDialog 
            hideDialog={() => setShowCollectionTimeSlots(false)}
            showDialog={showCollectionTimeSlots}
            setTimeSlot={handleSetCollectionTimeSlot}          
          />
        )}
        {showDeliveryTimeSlots && (
          <DeliveryTimeSlotDialog 
            hideDialog={() => setShowDeliveryTimeSlots(false)}
            showDialog={showDeliveryTimeSlots}
            setTimeSlot={handleSetDeliveryTimeSlot}          
          />
        )}

      </div>
    );
  }
);
