import React, { useState, useEffect } from "react";
import { IOrderDetail } from "../../models/order";
import { observer } from "mobx-react-lite";

interface IProps {
  alterations: IOrderDetail[];
}
export const AlterationFooter: React.FC<IProps> = observer(
  ({ alterations }) => {
    const [totalPrice, setTotalPrice] = useState(0.0);
    const [totalQty, setTotalQty] = useState(0);

    useEffect(() => {
      let qty = 0;
      let price = 0.0;
      alterations.forEach((a: IOrderDetail) => {
        if (a.itemType === "S") qty += a.quantity;
        price += a.lineTotal;
      });
      setTotalPrice(price);
      setTotalQty(qty);
    }, [alterations, setTotalPrice, setTotalQty]);

    return (
      <div className="alteration-footer-row">
        <div className="alteration-col">
          <span>Alterations: </span>
          <span>{totalQty}</span>
        </div>
        <div className="alteration-col">
          <span>Total: </span>
          <span>&pound;&nbsp;{totalPrice.toFixed(2)}</span>
        </div>
      </div>
    );
  }
);
