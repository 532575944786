import React, { useContext, useEffect } from "react";
import { Header } from "../components/Layout/Header";
import { Footer } from "../components/Layout/Footer";
import { RootStoreContext } from "../stores/rootStore";
import { observer } from "mobx-react-lite";
import { LoadingComponent } from "../components/Common/LoadingComponent";
import { DocumentSection } from "../components/Document/DocumentSection";
interface IProps{
    docType: string;
}
export const DocumentPage : React.FC<IProps> = observer(({docType}) => {
  const rootStore = useContext(RootStoreContext);
  const { setPrevRoute, document, loadingDocument, loadTerms, loadPrivacy } = rootStore.commonStore;

useEffect(() =>{
  setPrevRoute(window.location.pathname);
},[])
useEffect(() =>{
  switch(docType){
    case "terms" :
        loadTerms();
        break;
    case "privacy":
        loadPrivacy();
        break;
  }
},[docType])

if (loadingDocument || !document)
   return <LoadingComponent message="Loading page..." />;
  return (
    <>
      <Header />
      <main>
        <div className="document-wrapper">
          <div className="document-container">
            {document!.sections.map( (section) => (
                <DocumentSection section={section} />
            )
            )}            
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
});