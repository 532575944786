import React from "react";
import { Loader } from "./Loader";

export const LoadingComponent: React.FC<{ message?: string }> = ({
  message,
}) => {
  return (
    <div className="dimmer">
      <Loader message={message} />
    </div>
  );
};
