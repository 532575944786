import { observer } from 'mobx-react-lite';
import React from 'react'
import { IAppOrderEntity } from '../../models/order'
import { displayDate, displayDateTime } from '../../utils/Utils';
import { MyOrdersFooter } from './MyOrdersFooter';
import { MyOrdersGrid } from './MyOrdersGrid';
interface IProp{
    orders: IAppOrderEntity[];
    orderType: number;
    setOrdersType: (type: number) => void;
}
export const AppOrdersList : React.FC<IProp> = observer(({ orders, orderType, setOrdersType}) => {
    const handleSetOrderType = () => setOrdersType(orderType);
    return (
        <div className="myorders-list-container">
            {orders?.map(order => (
                <div key={order.uniqueRecId}>
                    <div className="myorders-order-header">
                        <div className="myorders-order-header-item">
                            <div>Order No: {order.orderNo}</div>
                            <div>Total: &pound;&nbsp;{order.total.toFixed(2)}</div>
                        </div>
                        <div className="myorders-order-header-item">
                            <div>Order Date: {displayDate(order.orderDate)}</div>
                        </div>
                        <div className="myorders-order-header-item">
                            <div>Collection Date: {displayDateTime(order.collectionDate)}</div>
                            <div>Delivery Date: {displayDateTime(order.deliveryDate)}</div>
                        </div>
                    </div>
                    <MyOrdersGrid orderDetails={order.orderDetails} />
                    <MyOrdersFooter uid={order.uniqueRecId} orderNo={order.orderNo} collectionDate={order.collectionDate} deliveryVisit={order.deliveryVisit} status={order.status} setOrdersType={handleSetOrderType} />
                </div>
            ))}
        </div>
    )
});
