import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CustomerCard } from "./CustomerCard";
import { RootStoreContext } from "../../../stores/rootStore";
import { ICustomerCard } from "../../../models/cards";

export const PaymentDetails = observer(() => {
    const rootStore = useContext(RootStoreContext);
    const { customer, getCustomerCards, customerCards, showNewCardDialog, cardSaveError, defaultCardUID } = rootStore.userStore;
    const [currentCard, setCurrentCard] = useState<ICustomerCard | undefined>();
    useEffect(() => {
        if(customer){
            getCustomerCards();
        }
    }, [customer])
    useEffect(() => {
        if(defaultCardUID){
            var card = customerCards.filter(c => c.uniqueRecId === defaultCardUID)[0];
            if(card)
                setCurrentCard(card);
        }
    },[defaultCardUID,customerCards])
    return (
    <div className="basket-personal-details">
      <h2 className="basket-heading">Payment Details</h2>
      <div className="card-grid-header">
            <div>
                <span>Card Type</span>
            </div>
            <div><span>Card Number</span></div>
            <div>
                <span>Expiry Date</span>
            </div>
        </div>
        {customerCards.sort((a) => a.isDefault ? -1 : 1).map((card) => {
            return <CustomerCard key={card.uniqueRecId} customerCard={card} />
        })}
        {(defaultCardUID.length === 0 && cardSaveError.length > 0) && (
            <span className="input-error">{cardSaveError}</span>
        )}
        <div className="card-grid-footer">
            {currentCard && (
            <span>
            {`We will charge for this order using your ${currentCard.cardType} ${currentCard.cardNumber}`}
            
            </span>
        )}
          <button className="btn btn-default" onClick={ (event) => { event.preventDefault(); showNewCardDialog();}}>Add a Credit/Debit card</button>
        </div>
    </div>
    );
  }
);
