import { ITimeSlot } from "../models/order";
import { ISupportDetails } from "../models/support";
import { ICustomer } from "../models/user";

export const addHours = (date: Date, hours: number) => {
  var actualDate = new Date(date);
  actualDate.setTime(actualDate.getTime() + (hours*60*60*1000));
  return actualDate
}

export const addDays = (date: Date, days: number) => {
  var actualDate = new Date(date);
  actualDate.setDate(actualDate.getDate() + days);
  return actualDate;
}

export const getDatePart = (date: Date) => {
  var actualDate = new Date(date);
  return new Date(
     actualDate.getFullYear(),
     actualDate.getMonth(),
     actualDate.getDate(),
     0,
     0
  )
}
export const formatDate = (date: Date) => {
  var actualDate = new Date(date);
  const timeString =
    actualDate.getHours() + ":" + actualDate.getMinutes() + ":00";
  const year = actualDate.getFullYear();
  const month = actualDate.getMonth() + 1;
  const day = actualDate.getDate();
  const dateString = `${year}-${month}-${day}`;
  return dateString + " " + timeString;
};

export const displayDateTime = (date: Date) => {
  var actualDate = new Date(date);
  const timeString = `${(100 + actualDate.getHours()).toString().substr(1)}:${(100 + actualDate.getMinutes()).toString().substr(1)}`;
  const year = actualDate.getFullYear();
  const month = 100 + actualDate.getMonth() + 1;
  const day = 100 + actualDate.getDate();
  const dateString = `${day.toString().substr(1)}/${month.toString().substr(1)}/${year}`;
  return dateString + " " + timeString;
};

export const displayDate = (date: Date) => {
  var actualDate = new Date(date);
  const year = actualDate.getFullYear();
  const month = 100 + actualDate.getMonth() + 1;
  const day = 100 + actualDate.getDate();
  return `${day.toString().substr(1)}/${month.toString().substr(1)}/${year}`;
};

export const getDateString = (date:Date) => {
  var actualDate = new Date(date);
  const year = actualDate.getFullYear();
  const month = actualDate.getMonth() + 1;
  const day = actualDate.getDate();
  return `${year}-${(100+month).toString().substr(1)}-${(100+day).toString().substr(1)}`;
}
export const getDate = (date: Date, time: number) => {
  var actualDate = new Date(date);
  return new Date(
    actualDate.getFullYear(),
    actualDate.getMonth(),
    actualDate.getDate(),
    time,
    0,
    0
  );
};

export const getDateFromString = (value: string) => {
  var dateValues = value.split("-");
  return new Date(
    parseInt(dateValues[0]),
    parseInt(dateValues[1])-1,
    parseInt(dateValues[2])
  );
}
export const getDateStringEx = (date: Date) => {
  if (date) {
    var actualDate = new Date(date);
    var dayNumber = actualDate.getDate();
    var suffix = "";
    if(dayNumber > 3 && dayNumber < 21){
      suffix = "th";
    } else {
    switch(dayNumber % 10){
      case 1 : suffix='st'; break;
      case 2 : suffix='nd'; break;
      case 3 : suffix='rd'; break;
      default: suffix='th'; break;
    }
    }

    return `${getDayName(actualDate)}, ${getMonthName(
      actualDate
    )} ${actualDate.getDate()}${suffix}`;
  }
};

export const getDateFullNameString = (date: Date) => {
  if (date) {
    var actualDate = new Date(date);
    var dayNumber = actualDate.getDate();
    var suffix = "";
    if(dayNumber > 3 && dayNumber < 21){
      suffix = "th";
    } else {
    switch(dayNumber % 10){
      case 1 : suffix='st'; break;
      case 2 : suffix='nd'; break;
      case 3 : suffix='rd'; break;
      default: suffix='th'; break
    }
    }

    return `${getDayFullName(actualDate)}, ${actualDate.getDate()}${suffix} ${getMonthFullName(
      actualDate
    )}`;
  }
};



export const getTimeString = (time: number) => {
  return (100 + time).toString().substring(1);
};

export const getDayName = (date: Date) => {
  if (date) {
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var actualDate = new Date(date);
    return days[actualDate.getDay()];
  }
};

export const getDayFullName = (date: Date) => {
  if (date) {
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var actualDate = new Date(date);
    return days[actualDate.getDay()];
  }
};

export const getMonthName = (date: Date) => {
  if (date) {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var actualDate = new Date(date);
    return months[actualDate.getMonth()];
  }
};

export const getMonthFullName = (date: Date) => {
  if (date) {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var actualDate = new Date(date);
    return months[actualDate.getMonth()];
  }
};


export const customerFullName = (customer: ICustomer) => {
  let fullName:string = ""; 
  if(customer.title.length > 0)
    fullName = `${customer.title} ${customer.firstName}`;
  else
    fullName = customer.firstName;
  if(customer.middleName.length > 0)
    fullName = `${fullName} ${customer.middleName}`;
  if(customer.surname.length > 0)
    fullName = `${fullName} ${customer.surname}`;
  fullName = fullName.toLowerCase();
  return fullName.replace(/([a-z])([A-Z])/g, function(allMatches, firstMatch, secondMatch) {
    return firstMatch + " " + secondMatch;
  })
  .toLowerCase()
  .replace(/([ -_]|^)(.)/g, function(allMatches, firstMatch, secondMatch) {
    return (firstMatch ? " " : "") + secondMatch.toUpperCase();
  });
}

export const customerFullAddress = (customer: ICustomer) => {
  let fullAddress: string = "";
  if(customer.address1.length > 0)
    fullAddress = customer.address1;
  if(customer.address2.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${customer.address2}`
    else
      fullAddress = customer.address2;
  }
  if(customer.address3.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${customer.address3}`
    else
      fullAddress = customer.address3;
  }
  if(customer.address4.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${customer.address4}`
    else
      fullAddress = customer.address4;
  }
  if(customer.postCode.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${customer.postCode}`
    else
      fullAddress = customer.postCode;
  }
  return fullAddress;
}

export const customerAddressWithoutPostcode = (customer: ICustomer) => {
  let fullAddress: string = "";
  if(customer.address1.length > 0)
    fullAddress = customer.address1;
  if(customer.address2.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${customer.address2}`
    else
      fullAddress = customer.address2;
  }
  if(customer.address3.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${customer.address3}`
    else
      fullAddress = customer.address3;
  }
  if(customer.address4.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${customer.address4}`
    else
      fullAddress = customer.address4;
  }
  return fullAddress;
}

export const toTitlecase = (value:string) =>{
  if(value)
  return value.toLowerCase().replace(/([a-z])([A-Z])/g, function(allMatches, firstMatch, secondMatch) {
    return firstMatch + " " + secondMatch;
  })
  .toLowerCase()
  .replace(/([ -_]|^)(.)/g, function(allMatches, firstMatch, secondMatch) {
    return (firstMatch ? " " : "") + secondMatch.toUpperCase();
  });
  return '';
}

export const supportFullAddress = (support: ISupportDetails | undefined) => {
  let fullAddress: string = "";
  if(support){
  if(support.address1.length > 0)
    fullAddress = support.address1;
  if(support.address2.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${support.address2}`
    else
      fullAddress = support.address2;
  }
  if(support.address3.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${support.address3}`
    else
      fullAddress = support.address3;
  }
  if(support.address4.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${support.address4}`
    else
      fullAddress = support.address4;
  }
  if(support.postCode.length > 0)
  {
    if(fullAddress.length > 0)
        fullAddress = `${fullAddress}, ${support.postCode}`
    else
      fullAddress = support.postCode;
  }
  }

  return fullAddress;
}

export const uniqueSlotDates = (slots:ITimeSlot[]) => {
 var uniqueSlots:Date[] = [];
  for(var i=0; i < slots.length; i++){
    if(!uniqueSlots.some(s => new Date(s).getTime() === new Date(slots[i].slotDate).getTime()))
      uniqueSlots.push(slots[i].slotDate);
  }
  return uniqueSlots;
}

export const decryptResetKey = (resetKey: string) => {
  if(resetKey.length <= 29)
    return "";
  var prefix = resetKey.substring(0,15);
  var suffix = resetKey.substring(resetKey.length-14); 
  return prefix + suffix;
}
