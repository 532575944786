import { RootStore } from "./rootStore";
import { reaction, observable, action, runInAction } from "mobx";
import { IShop } from "../models/shop";
import agent from "../api/agent";
import { IContactForm, ISupportDetails } from "../models/support";
import { IDocument, IFAQs } from "../models/document";

export default class CommonStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem("mdc_jwt", token);
        } else {
          window.localStorage.removeItem("mdc_jwt");
        }
      }
    );
    reaction(
      () => this.shopUID,
      (shop) => {
        if (shop) {
          window.localStorage.setItem("mdc_shop", shop);
        } else {
          window.localStorage.removeItem("mdc_shop");
        }
      }
    );
    reaction(
      () => this.prevRoute,
      (pr) => {
        if (pr) {
          window.sessionStorage.setItem("mdc_pr", pr);
        } else {
          window.sessionStorage.removeItem("mdc_pr");
        }
      }
    );
  }

  @observable token: string | null = window.localStorage.getItem("mdc_jwt");
  @observable appLoaded = false;
  @observable invalidShopCode = false;
  @observable validShopCode = true;
  @observable shopUID: string | null = window.localStorage.getItem("mdc_shop");
  @observable shops: IShop[] = [];
  @observable currentShop: IShop | undefined;
  @observable message: string = "";
  @observable prevRoute: string | null = window.sessionStorage.getItem("mdc_pr");
  @observable supportDetails: ISupportDetails | undefined;
  @observable termsURL: string = "";
  @observable privacyURL: string = "";
  @observable faqsURL: string = "";
  @observable loadingDocument: boolean = false;
  @observable document: IDocument | undefined;
  @observable faqs: IFAQs | undefined;
  @observable prefilledPostCode: string | undefined;

  @action setPrefilledPostCode = (postCode: string) => {
    this.prefilledPostCode = postCode;
  }
  
  @action setFAQsURL = (url: string) =>{
    this.faqsURL = url;
  }
  @action setTermsURL = (url: string) =>{
    this.termsURL = url;
  }
  
  @action setPrivacyURL = (url: string) =>{
    this.privacyURL = url;
  }

  @action loadTerms = () =>{
    this.loadingDocument = true;
    agent.Document.terms().then(doc =>{
      runInAction(() => {
        this.document = doc;
        this.loadingDocument = false;
      })
    })
  }

  @action loadPrivacy = () =>{
    this.loadingDocument = true;
    agent.Document.privacy().then(doc =>{
      runInAction(() => {
        this.document = doc;
        this.loadingDocument = false;
      })
    })
  }

  @action loadFAQs = () => {
    this.loadingDocument = true;
    agent.Document.faqs().then(faqs => {
      runInAction(() => {
        this.faqs = faqs;
        this.loadingDocument = false;
      })
    })
  }

  @action setPrevRoute = (pRoute: string) =>{
    if(this.prevRoute !== pRoute)
      this.prevRoute = pRoute;
  }
  @action setMessage = (message: string) => {
    this.message = message;
  };
  @action setToken = (token: string | null) => {
    this.token = token;
  };

  @action setAppLoaded = () => {
    this.appLoaded = true;
  };

  @action setInvalidShopCode = () => {
    this.invalidShopCode = true;
  };

  @action setShopUID = (uid: string) => {
    this.shopUID = uid;
  };

  @action CheckShopCodeValid = (uid: string) => {
    agent.Shop.checkshopuid(uid).then((valid)=>{
      runInAction(() => {
      this.validShopCode = valid;
      this.invalidShopCode = !valid;
      })
      if(this.validShopCode)
      if(this.shopUID !== uid){
        this.setShopUID(uid);
        this.loadShops();
      }
    });
  }

  @action loadShops = async () => {
    if (this.shopUID) {
      agent.Shop.list(this.shopUID)
        .then((shops) => {
          runInAction(() => {
            this.shops = shops;
            if (shops.length <= 0) {
              this.setInvalidShopCode();
              this.setAppLoaded();
            }
          });
        })
        .catch((error) => {
          this.setInvalidShopCode();
          this.setAppLoaded();
        });
    }
  };

  @action setCurrentShop = () => {
    if (this.shopUID) {
      this.currentShop = this.shops.find(
        (s) => s.uniqueRecId.toLowerCase() === this.shopUID?.toLowerCase()
      );
    }
  };

  @action getSupportDetails = () => {
    if(this.currentShop){
      agent.Support.supportdetails(this.currentShop.appKey, this.currentShop.shopCode)
      .then((response:ISupportDetails) => {
        runInAction(() => {
          this.supportDetails = response;
        })
      });
    }
  }

  @action sendContactForm = async (contactForm: IContactForm) : Promise<boolean> => {
    return await agent.Support.sendcontactform(contactForm);
  }
}
        