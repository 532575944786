import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { IMainCategory } from "../../models/category";
import { RootStoreContext } from "../../stores/rootStore";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { history } from "../../";

interface IProps {
  category: IMainCategory;
}
export const ToolbarButton: React.FC<IProps> = observer(({ category }) => {
  const rootStore = useContext(RootStoreContext);
  const { selectMainCategory, selectedMainCategory } = rootStore.categoryStore;
  const { currentShop } = rootStore.commonStore;
  const { totalQuantity, setOrderSaved } = rootStore.orderStore;
  const { user } = rootStore.userStore;
 
  return category.position === 0 ? (
    currentShop?.itemSelectionOptions === 1 && totalQuantity === 0 ? (
      <div
        className={
          selectedMainCategory === category
            ? "toolbar-button toolbar-selected"
            : "toolbar-button"
        }
        onClick={() => {
          // history.push("/basket");
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='dialog-box'>
                  <div className="dialog-title">
                    <h2>Confirm No Item Selection</h2>
                  </div>
                  <div className="dialog-body">
                   {currentShop.noItemPrompt.split("//").map( (np,index) =>
                     <p key={index} >{np}</p>
                   )}
                  </div>
                  <div className="dialog-footer">
                  <button className="btn btn-cancel" onClick={onClose}>Cancel</button>
                  <button className="btn btn-default"
                    onClick={() => {
                      setOrderSaved(0);
                      user!.isCustomer ? (history.push("/basket")) : (history.push("/newcustomer")) 
                      onClose();
                    }}
                  >
                    Ok
                  </button>
                  </div>
                </div>
              );
            }
    });
        }}
      >
        <img
          className="img-default"
          src={`${currentShop!.appDocUrl}/Toolbar/Normal/${category!.groupImage}?${new Date().getTime()}`}
          alt=""
        />
        <img
          className="img-hover"
          src={`${currentShop!.appDocUrl}/Toolbar/MouseOver/${category!.groupMOImage}?${new Date().getTime()}`}
          alt=""
        />
        <p>{category.groupName}</p>
      </div>
    ) : (
      <div></div>
    )
  ) : (
    <div
      className={
        selectedMainCategory === category
          ? "toolbar-button toolbar-selected"
          : "toolbar-button"
      }
      onClick={() => {
        if (selectedMainCategory !== category) {
          selectMainCategory(category.groupName);
        }
      }}
    >
      <img
        className="img-default"
        src={`${currentShop!.appDocUrl}/Toolbar/Normal/${category!.groupImage}?${new Date().getTime()}`}
        alt=""
      />
      <img
        className="img-hover"
        src={`${currentShop!.appDocUrl}/Toolbar/MouseOver/${category!.groupMOImage}?${new Date().getTime()}`}
        alt=""
      />
      <p>{category.groupName}</p>
    </div>
  );
});
