import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { OrderDetailRow } from "./OrderDetailRow";
import { Field } from "react-final-form";

export const OrderDetails = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { orderDetails, basketValues, setBasketValues } = rootStore.orderStore;
  return (
    <div className="basket-orders">
      <h2 className="basket-heading">Order Details</h2>
      <div className="basket-grid">
        {orderDetails && orderDetails.length > 0 ? (
          orderDetails
            .sort((d1, d2) => d1.mainLineNo - d2.mainLineNo)
            .map((od) => <OrderDetailRow key={od.lineNo} orderDetail={od} />)
        ) : (
          <div className="basket-grid-row input-error-big">
            No items selected
          </div>
        )}
      </div>
      <Field name="orderNotes">
        {({ input }) => (
          <div className="basket-input-group">
            <label className="basket-input-label">Order Notes</label>
            <textarea
              className="basket-input-field multiline-field"
              {...input}
              rows={5}
              onChange={(event)=> setBasketValues({...basketValues, orderNotes: event.target.value})}
            ></textarea>
          </div>
        )}
      </Field>
    </div>
  );
});
